<div class="pt-0">
  <div class="card rounded-4 shadow-sm">
    <div class="card-header">
      <h5 class="mb-0 py-2 fw-bold">My cases</h5>
      <p class="text-secondary">Find patient's who are assigned to you.</p>
    </div>
    <div class="card-header border-0">
      <ul class="nav nav-tabs">
        <!-- <li class="nav-item" (click)="changeView(0)">
              <a [class]="view == 0 ? 'nav-link active' : 'nav-link'" aria-current="page">
                New cases
                <span *ngIf="listOfNewCases.length > 0" class="ms-2  top-0 start-100 badge rounded-pill bg-danger">
                  {{listOfNewCases.length}}
                  <span class="visually-hidden"></span>
                </span>
              </a>
              
            </li> -->
        <li class="nav-item" (click)="changeView(1)">
          <a [class]="view == 1 ? 'nav-link active' : 'nav-link'"
            >Active ({{ listOfCases.length }})</a
          >
        </li>
        <li class="nav-item" (click)="changeView(2)">
          <a [class]="view == 2 ? 'nav-link active' : 'nav-link'"
            >Closed ({{ listOfClosedCases.length }})</a
          >
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div class="row g-3 mb-3">
        <div class="col-12">
          <div class="form-group">
            <div class="input-group">
              <input
                (keyup)="filter($event)"
                type="text"
                class="form-control"
                placeholder="Filter by Name, National ID or Assignee"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="text-secondary" *ngIf="!loading && view == 0">
            Showing
            <strong
              >{{ listOfNewCases.length }}
              {{ listOfNewCases.length == 1 ? "result" : "results" }}</strong
            >
          </p>
          <p class="text-secondary" *ngIf="!loading && view == 1">
            Showing
            <strong
              >{{ listOfCases.length }}
              {{ listOfCases.length == 1 ? "result" : "results" }}</strong
            >
          </p>
          <p class="text-secondary" *ngIf="!loading && view == 2">
            Showing
            <strong
              >{{ listOfClosedCases.length }}
              {{ listOfClosedCases.length == 1 ? "result" : "results" }}</strong
            >
          </p>
          <span class="placeholder-glow" *ngIf="loading"
            ><span class="placeholder col-3"></span
          ></span>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead *ngIf="!loading">
                <tr>
                  <th>National ID</th>
                  <th>Patient's Full Name</th>
                  <th>Registration Date</th>
                  <th>Assigned to</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <!-- new cases -->
              <tbody *ngIf="!loading && view == 0">
                <tr
                  *ngFor="
                    let c of listOfNewCases
                      | paginate : { itemsPerPage: 6, currentPage: p }
                  "
                >
                  <td>{{ c.IdCardNumber }}</td>
                  <td class="text-capitalize">{{ c.PatientName }}</td>
                  <td>{{ c.DateCreated | date }}</td>
                  <td>{{ c.AssignedUserName }}</td>
                  <td>
                    <div>{{ c.IdRecordStatus }}</div>
                  </td>

                  <td class="d-flex">
                    <a class="btn btn-sm btn-secondary" (click)="viewCase(c.Id)"
                      >View <i class="ms-1 bi bi-eye-fill"></i
                    ></a>
                    <button
                      (click)="whenOpenTransferModal(c.Id)"
                      class="btn btn-sm btn-primary ms-2 d-flex align-items-center"
                      data-bs-toggle="modal"
                      data-bs-target="#transferModal"
                    >
                      <span class="me-1">Transfer</span>
                      <svg
                        width="20"
                        height="20"
                        fill="currentColor"
                        style="enable-background: new 0 0 24 24"
                        version="1.1"
                        viewBox="0 0 24 24"
                        xml:space="preserve"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <g id="info" />
                        <g id="icons">
                          <path
                            d="M21.7,10.2l-6.6-6C14.6,3.7,14,4.2,14,5v3c-4.7,0-8.7,2.9-10.6,6.8c-0.7,1.3-1.1,2.7-1.4,4.1   c-0.2,1,1.3,1.5,1.9,0.6C6.1,16,9.8,13.7,14,13.7V17c0,0.8,0.6,1.3,1.1,0.8l6.6-6C22.1,11.4,22.1,10.6,21.7,10.2z"
                            id="share"
                          />
                        </g>
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
              <!-- opened cases -->
              <tbody *ngIf="!loading && view == 1">
                <tr
                  *ngFor="
                    let c of listOfCases
                      | paginate : { itemsPerPage: 6, currentPage: p }
                  "
                >
                  <td>{{ c.IdCardNumber }}</td>
                  <td class="text-capitalize">{{ c.PatientName }}</td>
                  <td>{{ c.DateCreated | date }}</td>
                  <td>{{ c.AssignedUserName }}</td>
                  <td>
                    <app-pill [state]="'open'" [text]="'Open'"></app-pill>
                  </td>
                  <td class="d-flex">
                    <a
                      class="btn btn-sm btn-primary"
                      href="/#/search/patient?id={{ c.Id }}"
                      >View <i class="ri-arrow-right-s-line"></i
                    ></a>
                    <button
                      (click)="whenOpenTransferModal(c.Id)"
                      class="btn btn-sm btn-primary ms-2 d-flex align-items-center"
                      data-bs-toggle="modal"
                      data-bs-target="#transferModal"
                    >
                      <span class="me-1">Transfer</span>
                      <i class="ri-send-plane-fill"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              <!-- closed cases-->
              <tbody *ngIf="!loading && view == 2">
                <tr
                  *ngFor="
                    let c of listOfClosedCases.reverse()
                      | paginate : { itemsPerPage: 6, currentPage: p }
                  "
                >
                  <td>{{ c.IdCardNumber }}</td>
                  <td class="text-capitalize">{{ c.PatientName }}</td>
                  <td>{{ c.DateCreated | date }}</td>
                  <td>{{ c.AssignedUserName }}</td>
                  <td>
                    <app-pill [state]="'closed'" [text]="'Closed'"></app-pill>
                  </td>
                  <td class="d-flex">
                    <a
                      class="btn btn-sm btn-primary"
                      href="/#/search/patient?id={{ c.Id }}"
                      >View <i class="ri-arrow-right-s-line"></i
                    ></a>
                    <!-- <button (click)="whenOpenTransferModal(c.Id)" class="btn btn-sm btn-primary ms-2 d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#transferModal"><span class="me-1">Transfer</span> <svg width="20" height="20" fill="currentColor" style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="info"/><g id="icons"><path d="M21.7,10.2l-6.6-6C14.6,3.7,14,4.2,14,5v3c-4.7,0-8.7,2.9-10.6,6.8c-0.7,1.3-1.1,2.7-1.4,4.1   c-0.2,1,1.3,1.5,1.9,0.6C6.1,16,9.8,13.7,14,13.7V17c0,0.8,0.6,1.3,1.1,0.8l6.6-6C22.1,11.4,22.1,10.6,21.7,10.2z" id="share"/></g></svg></button> -->
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="loading">
                <tr class="placeholder-glow">
                  <td><span class="placeholder col-8"></span></td>
                  <td><span class="placeholder col-8"></span></td>
                  <td><span class="placeholder col-6"></span></td>
                  <td><span class="placeholder col-3"></span></td>
                  <td><span class="placeholder col-6"></span></td>
                  <td><span class="placeholder col-6"></span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer" *ngIf="!loading">
      <div class="d-flex justify-content-center custom-pagination">
        <pagination-controls
          class="custom-pagination"
          (pageChange)="p = $event"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>

<!-- Transfer Modal -->
<div
  class="modal fade"
  id="transferModal"
  tabindex="-1"
  aria-labelledby="transferModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="transferModalLabel">
          Transfer Patient Record
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row g-2">
          <div class="col">
            <h5 class="">ESS Users</h5>
          </div>
        </div>
        <div class="row g-2">
          <div class="col">
            <input
              (keyup)="filterByUser($event)"
              type="text"
              class="form-control"
              placeholder="Filter by name, surname or ID card number"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <ul class="list-group">
              <li
                (click)="selectTransferUser(u.Id, i)"
                [class]="
                  u.Names + ' ' + u.Surnames == username
                    ? 'd-none'
                    : 'list-group-item'
                "
                [id]="'list-item-' + i"
                aria-current="true"
                *ngFor="let u of listOfUsers; index as i"
              >
                <div class="d-flex">
                  <span class="checked me-2"
                    ><i class="bi bi-check-square-fill"></i
                  ></span>
                  <span class="unchecked me-2"
                    ><i class="bi bi-square"></i
                  ></span>
                  <span>{{ u.Names }} {{ u.Surnames }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-transparent"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <button
          [disabled]="!userTransferSelected"
          (click)="transferCase()"
          type="button"
          [class]="
            userTransferSelected
              ? 'btn btn-success d-flex align-items-center'
              : 'btn disabled btn-success d-flex align-items-center'
          "
        >
          <span class="me-1">Transfer Patient</span>
          <svg
            width="20"
            height="20"
            fill="white"
            style="enable-background: new 0 0 24 24"
            version="1.1"
            viewBox="0 0 24 24"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="info" />
            <g id="icons">
              <path
                d="M21.7,10.2l-6.6-6C14.6,3.7,14,4.2,14,5v3c-4.7,0-8.7,2.9-10.6,6.8c-0.7,1.3-1.1,2.7-1.4,4.1   c-0.2,1,1.3,1.5,1.9,0.6C6.1,16,9.8,13.7,14,13.7V17c0,0.8,0.6,1.3,1.1,0.8l6.6-6C22.1,11.4,22.1,10.6,21.7,10.2z"
                id="share"
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
