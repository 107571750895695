<div>
    <div class="col-12">
        <h4>Edit Form</h4>
        <div>
            <h5 class="mb-2 mt-3">
                <strong>
                    <span *ngFor="let d of diseasesList">
                        <span *ngIf="d.Id == formForEdit.IdDisease">{{d.Disease}} Form</span>
                    </span>
                </strong>
            </h5>
            <div class="row g-3 mb-3">
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label class="fw-bold" for="">Form Name</label>
                        <input [(ngModel)]="formObject.FormName" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label class="fw-bold" for="">Form Id</label>
                        <input [(ngModel)]="formObject.FormCode" type="text" class="form-control">
                    </div>
                </div>
            </div>
            <p class="text-secondary">Current version: {{FormVersion}}</p>
            <div class="row g-2 mb-3" *ngFor="let level of formJson; index as i">
                <div class="col-12">
                    <div class="card bg-light border">
                        <div class="card-header">
                            <h6 class="mb-0 py-2 fw-bold">{{i+1}}. Level Name:</h6>
                            <!-- <input [(ngModel)]="level.LevelName" type="text" class="form-control" value="{{level.LevelName}}"> -->
                            <textarea [(ngModel)]="level.LevelName" name="" id="" cols="30" rows="3" class="form-control" value="{{level.LevelName}}"></textarea>
                        </div>
                        <div class="card-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Question</th>
                                        <th>Type</th>
                                        <th>Options</th>
                                        <th>Mandatory</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let question of level.Form; index as questionIndex;">
                                        <td>
                                            <p *ngIf="question.Type == '1006'">This displays a list of diseases registered in the system.</p>
                                            <textarea
                                            *ngIf="question.Type != '1006'"
                                            [(ngModel)]="question.Question"
                                            type="text" class="form-control" [value]="question?.Question"></textarea>
                                        </td>
                                        <td>
                                            <select [(ngModel)]="question.Type" name="" id="" class="form-select">
                                                <option [selected]="question.Id" [value]="type.Id" *ngFor="let type of dataTypes">{{type.DataTypeName}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <!-- if it's radio (4) or multiple choice (5) -->
                                            <ul class="list-group list-group-flush" *ngIf="question.Type == '5' || question.Type == '4'">
                                                <li class="list-group-item text-capitalize options-list" *ngFor="let option of question.Options; index as optionIndex">
                                                    {{option}}
                                                    <span class="float-end">
                                                        <button class="btn btn-sm text-danger" (click)="deleteOption(i,questionIndex, optionIndex)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                                              </svg>
                                                        </button>
                                                    </span>
                                                </li>
                                                <li class="list-group-item">
                                                    <div class="row g-2">
                                                        <div class="col">
                                                            <input [id]="'optionsInput-' + i + '-' + questionIndex" type="text" class="form-control">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button class="btn btn-success" (click)="addOption(i,questionIndex, i + '-' + questionIndex)">Add </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </td>
                                        <td>
                                            <select [(ngModel)]="question.Mandatory" name="" id="" class="form-select text-capitalize">
                                                <option [selected]="question.Mandatory" [value]="true">True</option>
                                                <option [selected]="question.Mandatory" [value]="false">False</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="btn btn-danger ms-2" (click)="removeQuestion(i, questionIndex)">
                                                <i class="bi bi-trash-fill"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <!-- table row contains form for adding new question -->
                                    <tr>
                                        <td colspan="5">
                                            <button class="btn btn-secondary" (click)="addQuestion(i)">
                                                Add new question
                                                <svg fill="currentColor" width="20" height="20" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" id="memory-plus"><path d="M12 17H10V12H5V10H10V5H12V10H17V12H12Z" /></svg>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="card bg-light border">
                        <div class="card-body">
                            <button class="btn btn-secondary" (click)="addLevel()">Add new level</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer mb-4">
            <button class="btn btn-success" (click)="updateForm()">Update Form</button>
        </div>
    </div>
</div>