<div class="pt-0">
  <div class="card rounded-4 shadow-sm animate__animated animate__fadeIn">
    <div class="card-header">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb pt-2">
          <li class="breadcrumb-item text-primary pointer">
            <a (click)="switchView(0)"
              ><h5 class="mb-0 fw-bold">Record New Case</h5></a
            >
          </li>
          <li
            *ngIf="viewOption == 1"
            class="breadcrumb-item active fw-bold"
            aria-current="page"
          >
            <h5 class="mb-0 fw-bold">
              {{ formSelectedForRegistration?.FormName }}
            </h5>
          </li>
        </ol>
      </nav>
    </div>
    <div class="card-body" *ngIf="viewOption == 0">
      <h6 class="mb-3">Case Notification Forms:</h6>
      <div class="row">
        <div class="col-lg-7 col-12">
          <div class="row g-3">
            <div class="col-12" *ngFor="let form of listOfForms">
              <div class="card form-icon-card" (click)="selectForm(form)">
                <div class="card-body">
                  <img
                    height="64"
                    width="64"
                    src="/assets/icons/file.png"
                    alt=""
                  />
                  <div>
                    <p class="mt-3 mb-0">
                      {{ form?.FormName }}
                    </p>
                    <p for="" class="text-muted">
                      Version {{ form?.FormVersion }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" *ngIf="viewOption == 1">
      <div class="row" *ngIf="noFormAlert">
        <div class="col-12">
          <div class="alert alert-warning" role="alert">
            There aren't any registration forms for this disease yet. Please
            <a class="custom-link" routerLink="/forms">go to "Forms"</a> to
            create a new one.
          </div>
        </div>
      </div>
      <div class="row g-3" *ngIf="showFormCreator">
        <div [class]="loading ? 'col-12' : 'd-none'">
          <div class="card-text placeholder-glow">
            <span class="placeholder col-7"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-4"></span>
            <span class="placeholder col-6"></span>
            <span class="placeholder col-8"></span>
          </div>
        </div>
        <div [class]="loading ? 'd-none' : 'col-12'">
          <form [formGroup]="patientForm" action="" class="h-100">
            <div class="border rounded-1 p-3 shadow-sm">
              <h6 class="fw-bold">Patient Info</h6>
              <div class="row row-cols-4 g-2 mt-3">
                <div class="form-floating mb-2 idCardNumber-wrap">
                  <input
                    (keyup)="searchExistingPatient($event)"
                    [class.is-invalid]="noIdCardNumber"
                    formControlName="IdCardNumber"
                    type="text"
                    class="form-control"
                  />
                  <label>National ID</label>
                  <div
                    *ngIf="loadingPatient"
                    class="spinner-border text-success spinner-border-sm"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <div class="form-floating mb-2">
                  <input
                    [class.is-invalid]="noNames"
                    formControlName="Names"
                    type="text"
                    class="form-control"
                  />
                  <label>Names</label>
                </div>
                <div class="form-floating mb-2">
                  <input
                    [class.is-invalid]="noSurnames"
                    formControlName="Surnames"
                    type="text"
                    class="form-control"
                  />
                  <label>Surnames</label>
                </div>
                <div class="form-floating mb-2">
                  <select
                    [class.is-invalid]="noGender"
                    formControlName="IdGender"
                    name=""
                    class="form-select"
                  >
                    <option value="null" selected disabled>
                      Select gender
                    </option>
                    <option [value]="g.Id" *ngFor="let g of gendersList">
                      {{ g.Gerder }}
                    </option>
                  </select>
                  <label>Gender</label>
                </div>
              </div>
              <div class="row g-2 mt-2">
                <div class="col">
                  <div class="form-floating mb-2">
                    <input
                      [class.is-invalid]="noHomeAddress"
                      formControlName="HomeAddress"
                      type="text"
                      class="form-control"
                    />
                    <label>Home Address</label>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-floating mb-2">
                    <select
                      [class.is-invalid]="noHomeLocation"
                      formControlName="IdHomeAddress"
                      name=""
                      class="form-select"
                    >
                      <option [value]="l.Id" *ngFor="let l of locationsList">
                        {{ l.GeographicName }}
                      </option>
                    </select>
                    <label>Location</label>
                  </div>
                </div>
              </div>
              <div class="row g-2">
                <div class="col">
                  <div class="form-floating mb-2">
                    <input
                      [class.is-invalid]="noWorkAddress"
                      formControlName="WorkAddress"
                      type="text"
                      class="form-control"
                    />
                    <label>Work Address</label>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="form-floating mb-2">
                    <select
                      [class.is-invalid]="noWorkLocation"
                      formControlName="IdWorkAddress"
                      name=""
                      class="form-select"
                    >
                      <option [value]="l.Id" *ngFor="let l of locationsList">
                        {{ l.GeographicName }}
                      </option>
                    </select>
                    <label>Location</label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12">
          <div class="">
            <div class="mt-3">
              <div class="row g-3">
                <div [class]="loading ? 'col-12' : 'd-none'">
                  <div class="card-text placeholder-glow">
                    <span class="placeholder col-7"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-4"></span>
                    <span class="placeholder col-6"></span>
                    <span class="placeholder col-8"></span>
                  </div>
                </div>
                <div
                  [class]="loading ? 'd-none' : 'col-12'"
                  *ngFor="let level of formJson; index as levelIndex"
                >
                  <div
                    [class]="
                      !containsConditionalDiseases(level) &&
                      level.LevelIsConditional
                        ? 'd-none'
                        : 'border rounded-1 shadow-sm p-3'
                    "
                  >
                    <!-- *ngIf="levelIndex == 0" -->
                    <h6 class="text-capitalize fw-bold">
                      {{ level.LevelName }}
                    </h6>
                    <div
                      class="alert alert-warning"
                      role="alert"
                      *ngIf="level.LevelIsConditional"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-info-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                        />
                      </svg>
                      {{ level.LevelConditionalTitle }}
                    </div>
                    <div
                      class="form-group mb-3"
                      *ngFor="let q of level.Form; index as qi"
                    >
                      <label class="text-capitalize" *ngIf="q.Type != '1008'"
                        >{{ q.Question }}
                        <span *ngIf="q.Mandatory"
                          ><i class="text-danger ri-asterisk"></i></span
                      ></label>
                      <p class="mb-0 fw-bold" *ngIf="q.Type == '1008'">
                        {{ q.Question }}
                        <span *ngIf="q.Mandatory"
                          ><i class="text-danger ri-asterisk"></i
                        ></span>
                      </p>
                      <!-- SHORT ANSWER -->
                      <input
                        [class.is-invalid]="
                          q.Mandatory &&
                          q.Answer.trim() == '' &&
                          missingMandatoryFields
                        "
                        [(ngModel)]="q.Answer"
                        type="text"
                        class="form-control"
                        *ngIf="q.Type == '1'"
                      />
                      <!-- LONG ANSWER -->
                      <textarea
                        [class.is-invalid]="
                          q.Mandatory &&
                          q.Answer.trim() == '' &&
                          missingMandatoryFields
                        "
                        [(ngModel)]="q.Answer"
                        class="form-control"
                        cols="15"
                        rows="4"
                        *ngIf="q.Type == '2'"
                      ></textarea>
                      <!-- YES/NO -->
                      <select
                        [class.is-invalid]="
                          q.Mandatory &&
                          q.Answer.trim() == '' &&
                          missingMandatoryFields
                        "
                        [(ngModel)]="q.Answer"
                        class="form-select"
                        *ngIf="q.Type == '3'"
                      >
                        <option value="" selected disabled>
                          Select an option
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                      <!-- SINGLE CHOICE / RADIO -->
                      <div *ngIf="q.Type == '4'" class="text-capitalize">
                        <select
                          [class.is-invalid]="
                            q.Mandatory &&
                            q.Answer.trim() == '' &&
                            missingMandatoryFields
                          "
                          [(ngModel)]="q.Answer"
                          name=""
                          class="form-select"
                        >
                          <option
                            [value]="o"
                            *ngFor="let o of q.Options; index as optionIndex"
                          >
                            {{ o }}
                          </option>
                        </select>
                      </div>
                      <!-- MULTIPLE CHOICE -->
                      <div *ngIf="q.Type == '5'" class="text-capitalize">
                        <div
                          (click)="
                            pushCheckVal(
                              m,
                              qi,
                              'multiple',
                              levelIndex,
                              'flexCheckChoice' + m
                            )
                          "
                          class="form-check"
                          *ngFor="let ch of q.Options; index as m"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [value]="ch"
                            id="flexCheckChoice{{ m }}"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckChoice{{ m }}"
                          >
                            {{ ch }}
                          </label>
                        </div>
                      </div>
                      <!-- DIAGNOSIS / DISEASE LIST -->
                      <div
                        *ngIf="q.Type == '1006'"
                        class="diseases-wrap text-capitalize"
                      >
                        <div
                          (click)="
                            pushCheckVal(
                              n,
                              qi,
                              'diagnosis',
                              levelIndex,
                              'flexCheckDiagnosis' + n
                            )
                          "
                          class="form-check me-3"
                          *ngFor="let d of diseasesList; index as n"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            [value]="d.Disease"
                            id="flexCheckDiagnosis{{ n }}"
                          />
                          <label
                            style="pointer-events: none !important"
                            class="form-check-label"
                            for="flexCheckDiagnosis{{ n }}"
                            >{{ d.Disease }}</label
                          >
                        </div>
                      </div>
                      <!-- CONTACT -->
                      <div *ngIf="q.Type == '1007'">
                        <input
                          [class.is-invalid]="
                            q.Mandatory &&
                            q.Answer.trim() == '' &&
                            missingMandatoryFields
                          "
                          [(ngModel)]="q.Answer"
                          type="text"
                          class="form-control"
                        />
                      </div>
                      <!-- DATE -->
                      <input
                        [class.is-invalid]="
                          q.Mandatory &&
                          q.Answer.trim() == '' &&
                          missingMandatoryFields
                        "
                        [(ngModel)]="q.Answer"
                        type="date"
                        class="form-control"
                        *ngIf="q.Type == '6'"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer" *ngIf="viewOption == 1">
      <div class="d-flex justify-content-end">
        <button
          [disabled]="loading"
          class="btn px-3 py-2.5 py-2 btn-success float-right"
          (click)="saveRecord()"
        >
          <span *ngIf="!loading">Save case</span>
          <span *ngIf="loading" class="d-inline">
            <div
              class="spinner-border spinner-border-sm text-light"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <span class="ms-2">Saving case</span>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
