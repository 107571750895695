import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  constructor(private http: HttpClient) {}

  addNew(body: any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/Patients`, body);
  }

  getAllPatients(): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/Patients`);
  }

  getSinglePatient(id: any): Observable<any> {
    return this.http.get<any>(
      `${environment.apiURL}/Patients/Id?IdCardNumber=${id}`
    );
  }

  updateSinglePatient(id: any, body: any): Observable<any> {
    return this.http.put<any>(`${environment.apiURL}/Patients/${id}`, body);
  }
}
