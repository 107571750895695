<div class="pt-0">
  <div>
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <p class="mb-0 me-2">Visualize as</p>
          <div
            class="btn-group btn-group-sm"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            <input
              [disabled]="loading"
              (click)="toggleView()"
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio1"
              autocomplete="off"
            />
            <label class="btn btn-outline-secondary" for="btnradio1"
              >Tree Chart</label
            >

            <input
              [disabled]="loading"
              type="radio"
              class="btn-check"
              name="btnradio"
              id="btnradio2"
              autocomplete="off"
              checked
            />
            <label
              (click)="toggleView()"
              (click)="generateTable(chartData)"
              class="btn btn-outline-secondary"
              for="btnradio2"
              >List
            </label>
          </div>
        </div>

        <div class="col-auto ms-auto">
          <div class="form-group">
            <button
              [disabled]="loading"
              class="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#addOrganizationModal"
            >
              Add New
            </button>
          </div>
        </div>
      </div>
      <!-- <h6 class="mb-0 py-2">Search</h6> -->
    </div>
    <div>
      <div *ngIf="viewAsTree">
        <div echarts [options]="option" style="height: 500px"></div>
      </div>
      <div class="d-none" *ngIf="!viewAsTree" id="table-wrap">
        <table class="table" *ngIf="loading">
          <tbody>
            <tr class="placeholder-glow">
              <td><span class="placeholder col-10"></span></td>
              <td><span class="placeholder col-4"></span></td>
              <td><span class="placeholder col-2"></span></td>
              <td><span class="placeholder col-6"></span></td>
              <td><span class="placeholder col-8"></span></td>
            </tr>
          </tbody>
        </table>
        <table class="table" *ngIf="!loading">
          <thead>
            <tr>
              <th>Name</th>
              <th>ID</th>
              <th>Reports To</th>
              <th>Admin User</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let i of organizations">
              <td>{{ i.AreaName }}</td>
              <td>{{ i.Id }}</td>
              <td>{{ i.SuperiorsCode }}</td>
              <td>N/A</td>
              <td>
                <div class="dropdown ms-2">
                  <button
                    class="btn btn-secondary"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        (click)="editModal(i)"
                        data-bs-toggle="modal"
                        data-bs-target="#editmodal"
                        ><i class="ri-pencil-fill"></i> Edit</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item text-danger"
                        (click)="deleteModal(i.Id)"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                        ><i class="ri-delete-bin-2-fill"></i> Delete</a
                      >
                    </li>
                  </ul>
                </div>
                <!-- <button class="btn btn-warning" (click)="editModal(i)" data-bs-toggle="modal" data-bs-target="#editmodal">
                                    <i class="bi bi-pencil-fill"></i>
                                </button>
                                <button class="btn btn-danger ms-2" (click)="deleteModal(i.Id)" data-bs-toggle="modal" data-bs-target="#deleteModal">
                                    <i class="bi bi-trash3-fill"></i>
                                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ul class="list-group list-group-flush" *ngIf="!viewAsTree">
        <li class="list-group-item" *ngFor="let structure of hierarchy">
          <div class="d-flex align-items-center">
            <span>{{ structure.AreaName }}</span>
            <div class="dropdown ms-2">
              <button
                class="btn btn-secondary"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="ri-more-2-fill"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a
                    class="dropdown-item edit-org-btn"
                    id="{{ structure.Id }}"
                    (click)="editModal(structure.Id)"
                    data-bs-toggle="modal"
                    data-bs-target="#editmodal"
                    ><i class="ri-pencil-fill"></i> Edit</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item text-danger delete-org-btn"
                    id="del-{{ structure.Id }}"
                    (click)="deleteModal(structure.Id)"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                    ><i class="ri-delete-bin-2-fill"></i> Delete</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div *ngIf="structure.children">
            <ul class="structure-list">
              <li *ngFor="let child of structure.children" class="py-2">
                <!-- <div>{{ child.AreaName }}</div> -->
                <div class="d-flex align-items-center">
                  <span>{{ child.AreaName }}</span>
                  <div class="dropdown ms-2">
                    <button
                      class="btn btn-secondary"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="ri-more-2-fill"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item edit-org-btn"
                          [id]="child.Id"
                          (click)="editModal(child.Id)"
                          data-bs-toggle="modal"
                          data-bs-target="#editmodal"
                          ><i class="ri-pencil-fill"></i> Edit</a
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item text-danger delete-org-btn"
                          [id]="'del-' + child.Id"
                          (click)="deleteModal(child.Id)"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteModal"
                          ><i class="ri-delete-bin-2-fill"></i> Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <div *ngIf="child.children">
                  <ul class="structure-list">
                    <li *ngFor="let child of child.children" class="py-2">
                      <!-- <div>{{ child.AreaName }}</div> -->
                      <div class="d-flex align-items-center">
                        <span>{{ child.AreaName }}</span>
                        <div class="dropdown ms-2">
                          <button
                            class="btn btn-secondary"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="ri-more-2-fill"></i>
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                class="dropdown-item edit-org-btn"
                                [id]="child.Id"
                                (click)="editModal(child.Id)"
                                data-bs-toggle="modal"
                                data-bs-target="#editmodal"
                                ><i class="ri-pencil-fill"></i> Edit</a
                              >
                            </li>
                            <li>
                              <a
                                class="dropdown-item text-danger delete-org-btn"
                                [id]="'del-' + child.Id"
                                (click)="deleteModal(child.Id)"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                                ><i class="ri-delete-bin-2-fill"></i> Delete</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div *ngIf="child.children">
                        <div *ngIf="child.children">
                          <ul class="structure-list">
                            <li
                              *ngFor="let child of child.children"
                              class="py-2"
                            >
                              <!-- <div>{{ child.AreaName }}</div> -->
                              <div class="d-flex align-items-center">
                                <span>{{ child.AreaName }}</span>
                                <div class="dropdown ms-2">
                                  <button
                                    class="btn btn-secondary"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i class="ri-more-2-fill"></i>
                                  </button>
                                  <ul class="dropdown-menu">
                                    <li>
                                      <a
                                        class="dropdown-item edit-org-btn"
                                        [id]="child.Id"
                                        (click)="editModal(child.Id)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editmodal"
                                        ><i class="ri-pencil-fill"></i> Edit</a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        class="dropdown-item text-danger delete-org-btn"
                                        [id]="'del-' + child.Id"
                                        (click)="deleteModal(child.Id)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteModal"
                                        ><i class="ri-delete-bin-2-fill"></i>
                                        Delete</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div *ngIf="child.children">
                                <div *ngIf="child.children">
                                  <ul class="structure-list">
                                    <li
                                      *ngFor="let child of child.children"
                                      class="py-2"
                                    >
                                      <!-- <div>{{ child.AreaName }}</div> -->
                                      <div class="d-flex align-items-center">
                                        <span>{{ child.AreaName }}</span>
                                        <div class="dropdown ms-2">
                                          <button
                                            class="btn btn-secondary"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i class="ri-more-2-fill"></i>
                                          </button>
                                          <ul class="dropdown-menu">
                                            <li>
                                              <a
                                                class="dropdown-item edit-org-btn"
                                                [id]="child.Id"
                                                (click)="editModal(child.Id)"
                                                data-bs-toggle="modal"
                                                data-bs-target="#editmodal"
                                                ><i class="ri-pencil-fill"></i>
                                                Edit</a
                                              >
                                            </li>
                                            <li>
                                              <a
                                                class="dropdown-item text-danger delete-org-btn"
                                                [id]="'del-' + child.Id"
                                                (click)="deleteModal(child.Id)"
                                                data-bs-toggle="modal"
                                                data-bs-target="#deleteModal"
                                                ><i
                                                  class="ri-delete-bin-2-fill"
                                                ></i>
                                                Delete</a
                                              >
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div *ngIf="child.children">
                                        <div *ngIf="child.children">
                                          <ul class="structure-list">
                                            <li
                                              *ngFor="
                                                let child of child.children
                                              "
                                              class="py-2"
                                            >
                                              <!-- <div>{{ child.AreaName }}</div> -->
                                              <div
                                                class="d-flex align-items-center"
                                              >
                                                <span>{{
                                                  child.AreaName
                                                }}</span>
                                                <div class="dropdown ms-2">
                                                  <button
                                                    class="btn btn-secondary"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                  >
                                                    <i
                                                      class="ri-more-2-fill"
                                                    ></i>
                                                  </button>
                                                  <ul class="dropdown-menu">
                                                    <li>
                                                      <a
                                                        class="dropdown-item edit-org-btn"
                                                        [id]="child.Id"
                                                        (click)="
                                                          editModal(child.Id)
                                                        "
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#editmodal"
                                                        ><i
                                                          class="ri-pencil-fill"
                                                        ></i>
                                                        Edit</a
                                                      >
                                                    </li>
                                                    <li>
                                                      <a
                                                        class="dropdown-item text-danger delete-org-btn"
                                                        [id]="'del-' + child.Id"
                                                        (click)="
                                                          deleteModal(child.Id)
                                                        "
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal"
                                                        ><i
                                                          class="ri-delete-bin-2-fill"
                                                        ></i>
                                                        Delete</a
                                                      >
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                              <div *ngIf="child.children">
                                                Child structures limit reached
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <!-- <app-structure
            *ngIf="structure.children"
            [children]="structure.children"
            [handleEditClick]="editModal"
            [organizations]="organizations"
          ></app-structure> -->
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- Add New Modal -->
<div
  class="modal fade"
  id="addOrganizationModal"
  tabindex="-1"
  aria-labelledby="addOrganizationModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="addOrganizationModalLabel">
          Add New Structure
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form [formGroup]="addStructureForm" (ngSubmit)="addNewStructure()">
        <div class="modal-body">
          <h6>Reports to:</h6>
          <div class="form-group mb-3">
            <div class="form-floating">
              <select
                (change)="noSuperiorsCode = false"
                [class.is-invalid]="noSuperiorsCode"
                formControlName="SuperiorsCode"
                class="form-select"
                id="floatingSelect"
                aria-label="Floating label select example"
              >
                <option [value]="o.Id" *ngFor="let o of organizations">
                  {{ o.AreaName }}
                </option>
              </select>
              <label for="floatingSelect">Superior's name</label>
            </div>
            <label for="" *ngIf="noSuperiorsCode" class="text-danger"
              >Please select a superior structure</label
            >
          </div>

          <div class="form-group mb-3">
            <div class="form-floating">
              <input
                (keyup)="noAreaName = false"
                [class.is-invalid]="noAreaName"
                formControlName="AreaName"
                type="text"
                class="form-control"
                placeholder="Eg.'St. Patrick Hospital'"
              />
              <label for="floatingInput">Name</label>
            </div>
            <label for="" *ngIf="noAreaName" class="text-danger"
              >Please assign a name for this structure</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            [disabled]="savingNewStructure"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            [disabled]="savingNewStructure"
            type="submit"
            class="btn btn-success"
          >
            <span *ngIf="!savingNewStructure">Save changes</span>
            <span *ngIf="savingNewStructure" class="d-inline">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span class="ms-2">Saving structure</span>
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <h1 class="modal-title fs-5" id="deleteModalLabel">
          Delete organizational structure
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          Are you sure you want to delete this structure? this action cannot be
          undone and the structure details will be lost forever.
        </p>
        <p>
          Records and patient's data related to this structure will stay intact.
        </p>
      </div>
      <div class="modal-footer bg-light">
        <button
          [disabled]="deletingStructure"
          type="button"
          class="btn btn-transparent"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button
          [disabled]="deletingStructure"
          type="button"
          class="btn btn-danger"
          (click)="confirmDelete()"
        >
          Yes, delete it
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Edit Modal -->
<div
  class="modal fade"
  id="editmodal"
  tabindex="-1"
  aria-labelledby="editmodal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <form>
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="editmodal">
            Edit organizational structure
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="editForm" action="">
            <div class="form-group mb-3">
              <label for="">Area Name</label>
              <input
                type="text"
                formControlName="AreaName"
                class="form-control"
              />
              <!-- [value]="objInMemory.AreaName" -->
            </div>
            <div class="form-group mb-3">
              <label for="">Reports To</label>
              <select
                formControlName="IdSuperiorsCode"
                name=""
                id=""
                class="form-select"
                (change)="handleSuperiorChange($event)"
              >
                <option
                  [selected]="org.Id == objInMemory.IdSuperiorsCode"
                  [value]="org.Id"
                  *ngFor="let org of organizations; index as i"
                >
                  {{ org.AreaName }}
                </option>
              </select>
            </div>
          </form>
          <div class="mb-3 row g-2">
            <div class="col-6">
              <small>Registered on:</small>
              <p class="text-secondary">{{ objInMemory.DateCreated | date }}</p>
            </div>
            <div class="col-6">
              <small>Structure Code:</small>
              <p class="text-secondary">{{ objInMemory.Code }}</p>
            </div>
          </div>
          <div class="mb-3 row g-2">
            <!-- <div class="col-6">
                            <small>Reports to:</small>
                            <p class="text-secondary">{{objInMemory.SuperiorsCode}}</p>
                        </div> -->
            <!-- <div class="col-6">
              <small>Admin:</small>
              <p class="text-secondary">Unkown</p>
            </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-transparent"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button type="button" class="btn btn-success" (click)="confirmEdit()">
            Save changes
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
