import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  
  //opciones
  //0 = menu
  //1 = geographical structure
  //2 = forms settings
  permissionLevel = 0;
  option:number = 0;
  constructor(private _auth: AuthService) {}
  ngOnInit(): void {
    this.permissionLevel = this._auth.getPermissionLevel();
    
  }

  changeView(num:number) {
    this.option = num;
  }
}
