import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StructureService {
  constructor() {}

  async transformToHierarchy(structures: any[]): Promise<any> {
    const structureMap = new Map<number, any>();

    // Create a map where each structure is indexed by its ID
    for (const structure of structures) {
      structure.children = [];
      structureMap.set(structure.Id, structure);
    }

    const hierarchy: any[] = [];

    // Build the hierarchy by assigning children to their parent structures
    for (const structure of structures) {
      if (
        structure.IdSuperiorsCode &&
        structureMap.has(structure.IdSuperiorsCode)
      ) {
        structureMap.get(structure.IdSuperiorsCode).children.push(structure);
      } else {
        hierarchy.push(structure);
      }
    }

    return hierarchy;
  }
}
