<div class="pt-0">
  <h5 class="mb-0 py-2 fw-bold">Reports</h5>
  <div class="row g-3 pb-4">
    <div class="col-auto">
      <div class="form-group">
        <label for="">Report type</label>
        <select
          [(ngModel)]="selectedReportType"
          class="form-select form-select-sm"
          aria-label="Default select example"
        >
          <option selected disabled [value]="null">Select report type</option>
          <option value="cases">Cases</option>
          <option value="patients">Patients</option>
        </select>
      </div>
    </div>
    <div class="col-auto">
      <div class="form-group">
        <label for="">Start date</label>
        <input
          [(ngModel)]="startDate"
          [class.is-invalid]="invalidStartDate"
          type="date"
          class="form-control form-control-sm"
          value="Dec 28, 2022"
        />
      </div>
      <p class="text-danger position-absolute" *ngIf="invalidRange">
        End date must be greater than start date
      </p>
    </div>
    <div class="col-auto">
      <div class="form-group">
        <label for="">End date</label>
        <input
          [(ngModel)]="endDate"
          [class.is-invalid]="invalidEndDate"
          type="date"
          class="form-control form-control-sm"
          value="Dec 28, 2022"
        />
      </div>
    </div>
    <div class="col-auto d-flex align-items-end">
      <div class="form-group">
        <button
          [disabled]="selectedReportType == null || selectedReportType == ''"
          (click)="search()"
          class="btn btn-sm btn-primary"
        >
          Search
        </button>
      </div>
    </div>
    <div class="col-auto d-flex align-items-end">
      <div class="input-group">
        <input
          type="text"
          value="PDF"
          class="form-control form-control-sm"
          disabled
          style="max-width: 60px"
        />
        <button
          class="btn btn-sm btn-sm btn-success float-right"
          printSectionId="printDiv"
          [useExistingCss]="true"
          [printStyle]="{
            body: { 'background-color': 'white' },
            div: { 'background-color': 'white' }
          }"
          ngxPrint
          [disabled]="selectedReportType == null || selectedReportType == ''"
        >
          Export
        </button>
      </div>
    </div>
  </div>
  <div
    class="card overflow-hidden rounded-4 shadow-sm"
    style="background-color: white"
  >
    <!-- <hr class="m-0"> -->
    <div class="card-body pt-0 bg-white" *ngIf="show">
      <div id="printDiv" style="background-color: white">
        <div class="row mt-4">
          <div class="col-12">
            <h3 *ngIf="selectedReportType == 'cases'">ESS Cases Report</h3>
            <h3 *ngIf="selectedReportType == 'patients'">
              ESS Patients Report
            </h3>
            <div class="mt-3 mb-3">
              <div class="report-bullet">
                <span class="report-label">Date Range</span>
                <span class="fw-bold me-2">
                  {{ startDate.toLocaleString() | date }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                  {{ endDate.toLocaleString() | date }}
                </span>
              </div>
              <div class="report-bullet">
                <span class="report-label">Report Date</span>
                <span class="fw-bold me-2">{{
                  today.toLocaleString() | date : "medium"
                }}</span>
              </div>
              <div class="report-bullet">
                <span class="report-label">User</span>
                <span class="fw-bold me-2">{{ username }}</span>
              </div>
              <div class="report-bullet">
                <span class="report-label">Records Found</span>
                <span class="fw-bold me-2">{{ casesForShow.length }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="table-responsive">
              <table
                class="table table-bordered"
                *ngIf="showingReportType == 'cases'"
              >
                <thead>
                  <tr>
                    <th>Case ID</th>
                    <th>Patient</th>
                    <!-- <th>Diagnosis</th> -->
                    <th>Registration Date</th>
                    <th>Last Assigned To</th>
                    <th>Case Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let c of casesForShow">
                    <td>{{ c?.Id }}</td>
                    <td>{{ c?.PatientName }}</td>
                    <!-- <td>{{c?.Diagnosis == '' ? 'N/A' : c?.Diagnosis}}</td> -->
                    <td>{{ c?.DateCreated | date }}</td>
                    <td>{{ c?.UserCreatedName }}</td>
                    <td>
                      <!-- if case is open '1' -->
                      <div
                        class="text-capitalize"
                        *ngIf="c?.IdRecordStatus == 1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#22bb22"
                          class="bi bi-check-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                          />
                        </svg>
                        Open
                      </div>
                      <!-- if case is closed -->
                      <div
                        class="text-capitalize"
                        *ngIf="c?.IdRecordStatus != 1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#dc3545"
                          class="bi bi-shield-lock-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm0 5a1.5 1.5 0 0 1 .5 2.915l.385 1.99a.5.5 0 0 1-.491.595h-.788a.5.5 0 0 1-.49-.595l.384-1.99A1.5 1.5 0 0 1 8 5z"
                          />
                        </svg>
                        Closed
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="table table-bordered"
                *ngIf="showingReportType == 'patients'"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Gender</th>
                    <th>National ID</th>
                    <th>Age</th>
                    <th>Home Address</th>
                    <!-- <th>Actions</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of patientsList">
                    <td>{{ p.Names }} {{ p.Surnames }}</td>
                    <td>{{ p.IdGender == 1 ? "Male" : "Female" }}</td>
                    <td>{{ p.IdCardNumber }}</td>
                    <td>N/A</td>
                    <td>{{ p.HomeAddress }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
