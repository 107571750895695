import { Component, OnInit } from '@angular/core';
import * as e from 'express';
import { CasesService } from 'src/app/services/cases.service';
import { PatientsService } from 'src/app/services/patients.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  orgId:any;
  orgStructureId: number = 0;
  casesList: any[] = [];
  casesForShow: any[] = [];
  patientsList: any[] = [];
  patientsForShow: any[] = [];
  loading: boolean = true;
  today = new Date();
  selectedReportType: string | null = null;
  showingReportType: string | null = null;
  startDate: string  = '';
  endDate: string  = '';
  username: string | null= '';
  constructor(private _cases: CasesService, private _patients: PatientsService) { }

  ngOnInit(): void {
    this.getStructure();
    this.getAllCases(this.orgId);
    this.getPatients();
  }

  getStructure() {
    const structure = localStorage.getItem("structure");
    this.username = localStorage.getItem("username");

    if(structure != null) {
      var deserialized = JSON.parse(structure)
      this.orgId = deserialized[0].IdOrganization;
      this.orgStructureId = deserialized[0].Id;
    }
  }

  getAllCases(id:number) {
    this.casesList = [];
    this._cases.getAllCases(id).subscribe((res) => {
      if(res) {
        for(let i = 0 ; i < res.length ; i++) {
          this.casesList.push(res[i]);
        }
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    })
  }

  getPatients() {
    this._patients.getAllPatients().subscribe((res) => {
      for(let i = 0 ; i < res.length ; i++) {
        this.patientsList.push(res[i])
      }

    },(e) => {
      console.log(e);
    }

    )
  }

  patients = [
    {
      firstname: 'Viktor',
      lastname: 'Kaplan',
      genre: 'm',
      age: 24,
      phone: '198-002292-0525',
      nationalid: '189-15615',
      email: 'user@email.com'
    },
    {
      firstname: 'Angelina',
      lastname: 'Spencer',
      genre: 'f',
      age: 42,
      phone: '198-002292-0525',
      nationalid: '189-15615',
      email: 'user@email.com'
    },
    {
      firstname: 'Ralph',
      lastname: 'Johnson',
      genre: 'm',
      age: 31,
      phone: '198-002292-0525',
      nationalid: '189-15615',
      email: 'user@email.com'
    },
  ]

  one = false; 
  two = false;
  three = false;

  show = false;

  invalidStartDate: boolean = false;
  invalidEndDate: boolean = false;
  invalidRange: boolean = false;
  search(){
    if(this.selectedReportType == null) return;

    if (this.validDateRange()) {
      this.casesForShow = [];
      this.showingReportType = this.selectedReportType;
      if(this.startDate != null && this.endDate != null) {
        const rangeStart = new Date(this.startDate);  
        const rangeEnd = new Date(this.endDate);  
        if(this.selectedReportType == 'cases') {
          this.casesList.map((e:any) => {
            const dateCreated = new Date(e.DateCreated);
            const formatedCreatedDate = dateCreated;
            const rangeInit = rangeStart;
            const rangeFin = rangeEnd;
            if(formatedCreatedDate >= rangeInit && formatedCreatedDate <= rangeFin) {
              this.casesForShow.push(e);
            }
          });
        } else {

        }
        this.show = true;
      }
    }

  }
  
  //validate that end date is greater than start date
  validDateRange() {
    this.invalidStartDate = false;
    this.invalidEndDate = false;
    this.invalidRange = false;
    if(this.startDate == '') {
      this.invalidStartDate = true; 
      return false;
    } else if(this.endDate == '') {
      this.invalidEndDate = true; 
      return false;
    } else if (new Date(this.startDate) > new Date(this.endDate)) {
      this.invalidStartDate = true;
      this.invalidEndDate = true;
      this.invalidRange = true;
      return false;
    } else {
      return true;
    }
  }
}
