import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiseasesService {

  constructor(private http: HttpClient) { }

  getAll(orgId:any): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/Diseases?idOrganization=${orgId}`);
  }

  addNew(body:any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/Diseases`, body);
  }

  delete(id:number): Observable<any> {
    return this.http.delete<any>(`${environment.apiURL}/Diseases/${id}`);
  }
  update(id:number, body:any): Observable<any> {
    return this.http.put<any>(`${environment.apiURL}/Diseases/${id}`, body);
  }
}
