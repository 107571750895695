import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Location } from '@angular/common';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrantGuardGuard implements CanActivate {

  constructor(private auth: AuthService, private location: Location, private common: CommonService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const permissionLevel = this.auth.getPermissionLevel();
      if(permissionLevel == 2) {
        return true;
      } else {
        this.location.back();
        this.common.customAlert('You are not authorized to access this page');
        return false;
      }
  }
  
}
