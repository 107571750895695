<div class="row">
    <div class="col-12">
        <h5 class="">Data Types</h5>
        <p class="text-secondary">These are the types of data inputs accepted when adding questions to a new form</p>
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Dominican Republic</td>
                        <td>5603</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
           