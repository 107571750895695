import { Component, OnInit } from '@angular/core';
import { CasesService } from 'src/app/services/cases.service';
import { CommonService } from 'src/app/services/common.service';
import { PatientsService } from 'src/app/services/patients.service';
import { UsersService } from 'src/app/services/users.service';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  orgId:any;
  orgStructureId: number = 0;

  constructor(
    private _cases: CasesService, 
    private _patients: PatientsService, 
    private _users: UsersService,
    private _common: CommonService) { }

  ngOnInit(): void {
    this.getStructure();
    this.getAllUsers(this.orgId);
    this.getAllPatients();
    this.getAllCases(this.orgId);
    
  }

  usersList: any[] = [];
  casesList: any[] = [];
  originalCasesList: any[] = [];
  patientsList: any[] = [];
  loading = true;
  p: number = 0; //page number

  getAllCases(id:number) {
    this._cases.getAllCases(id).subscribe((res) => {
      if(res) {
        for(let i = 0 ; i < res.length ; i++) {
          //format date before rendering on table
          let date = new Date(res[i]?.DateCreated);
          res[i].DateCreated = date.toLocaleDateString();
          res[i].PatientName = this.patientsList.find((e:any) => e.Id == res[i].IdPatient)?.Names + ' ' + this.patientsList.find((e:any) => e.Id == res[i].IdPatient)?.Surnames;
          this.casesList.push(res[i]);
          this.originalCasesList.push(res[i]);
        }
        this.loading = false;
      }
    }, (err) => {
      console.log(err);
    })
  }

  getAllPatients() {
    this._patients.getAllPatients().subscribe((res) => {
      if(res) {
        for(let i = 0 ; i < res.length ; i++) {
          this.patientsList.push(res[i]);
        }
      }
    }, (err) => {
      console.log(err);
    })
  }

  getStructure() {
    const structure = localStorage.getItem("structure");
    if(structure != null) {
      var deserialized = JSON.parse(structure)
      this.orgId = deserialized[0].IdOrganization;
      this.orgStructureId = deserialized[0].Id;
    }
  }

  getAllUsers(orgId:number) {
    this._users.getAll(orgId).subscribe((res) => {
      if(res) {
        for(let i = 0 ; i < res.length ; i++) {
          this.usersList.push(res[i])
        }
      }
    },(err) => {
      console.log(err);
    })
  }

  filter(event:any) {
    let searchTerm = event.target.value;
    let result = this.originalCasesList.filter( (e:any) => e.PatientName.toLowerCase().includes(searchTerm.toLowerCase()))
    this.casesList = result;
  }
}
