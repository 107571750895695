import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  getAllTypes(): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/GeographicStructuresTypes`);
  }

  getAllLocations(orgId:number): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/GeographicStructures?idOrganization=${orgId}`);
  }

  saveNewLocation(body:any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/GeographicStructures`, body);
  }

  updateLocation(id:number, body:any): Observable<any> {
    return this.http.put<any>(`${environment.apiURL}/GeographicStructures/${id}`, body);
  }
  
  deleteLocation(id:number): Observable<any> {
    return this.http.delete<any>(`${environment.apiURL}/GeographicStructures/${id}`)
  }
}
