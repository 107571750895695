import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { PasswordService } from 'src/app/services/password/password.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  showPasswordResetForm: boolean = false;
  nationalId: string = '';
  orgId: any;
  thisUserData: any;
  username: string = '';
  loading = false;
  constructor(
    private _auth: AuthService,
    private _users: UsersService,
    private _password: PasswordService
    ) { }
    
  ngOnInit(): void {
    const structure = localStorage.getItem("structure");
    if(structure != null) {
      var deserialized = JSON.parse(structure)
      this.orgId = deserialized[0].IdOrganization;
    }

  }
  form = new FormGroup({
    NationalID: new FormControl('')
  })

  showPasswordReset() {
    this.showPasswordResetForm = true;
  }
  
  invalidNationalId = false;
  inputIsEmpty = false;
  statusOK = false;
  loadingResponse = false;
  requestPasswordReset() {
    this.loadingResponse = true;

    const input = this.form.controls['NationalID'].value;
    if(input?.trim() == '') {
      this.inputIsEmpty = true;
      return;
    }
    this._password.requestPasswordReset(this.form.value).subscribe((res) => {
      this.loadingResponse = false;
      if(res.Status == 404) {
        this.invalidNationalId = true;
      } else if (res.Status == 200) {
        this.statusOK = true;
      }
    }, (e) => {console.log(e)});
  }

  resetValidations() {
    this.inputIsEmpty=false;
    this.invalidNationalId=false;
  }
}
