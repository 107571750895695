import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Location } from '@angular/common';
import { CasesService } from 'src/app/services/cases.service';
import { PatientsService } from 'src/app/services/patients.service';
import { FormsService } from 'src/app/services/forms.service';
import { DiseasesService } from 'src/app/services/diseases.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
})
export class PatientComponent implements OnInit {
  id: any = 0;
  patient: any = {};

  listOfLevels: any[] = [{}, {}, {}];
  loading = true;
  changingDiagnosis = false;
  permissionLevel: number | null = null;
  constructor(
    private _auth: AuthService,
    private router: Router,
    private location: Location,
    private _cases: CasesService,
    private _patients: PatientsService,
    private _forms: FormsService,
    private _diseases: DiseasesService
  ) {}

  ngOnInit(): void {
    this.getParams();
    this.getStructure();
    this.getAllForms(this.orgId);
    this.getAllDiseases(this.orgId);
    this.getUserPermissionLevel();
  }

  getUserPermissionLevel() {
    this.permissionLevel = this._auth.getPermissionLevel();
  }
  //get id from URL
  getParams() {
    const _url: UrlTree = this.router.parseUrl(this.location.path());
    if (_url.queryParams?.id) {
      this.id = _url.queryParams?.id;
      if (this.id != 0) {
        this.getCase(this.id);
      }
    }
  }

  orgId: any;
  orgStructureId: number = 0;
  getStructure() {
    const structure = localStorage.getItem('structure');
    if (structure != null) {
      var deserialized = JSON.parse(structure);
      this.orgId = deserialized[0].IdOrganization;
      this.orgStructureId = deserialized[0].Id;
    }
  }

  Diagnosis: any = '';
  case: any[] = [];
  originalCase: any = {};
  getCase(id: number) {
    this._cases.getSingleCase(id).subscribe(
      (res) => {
        this.originalCase = res;
        this.Diagnosis = res?.Diagnosis;
        this.case = JSON.parse(res.RegistryCaseJson);
        this.getDiagnosedDiseases(this.case);

        // Since Oct 3, 2023, to fetch a patient's data, we need to use their idCardNumber
        //so, first let's fetch all patients and filter using this "IdPatient"
        this._patients.getAllPatients().subscribe((response) => {
          let patientDataObject = response.filter(
            (patient: any) => patient.Id == res.IdPatient
          );
          const IdCardNumber = patientDataObject[0].IdCardNumber;
          this.getPatient(IdCardNumber);
        });

        // this.loading = false;
      },
      (res) => {
        console.log(res);
      }
    );
  }

  diagnosedDiseasesNames: any[] = [];
  //get names of diseases that have been diagnosed
  getDiagnosedDiseases(levels: any) {
    for (let i = 0; i < levels.length; i++) {
      let questions = levels[i].Form;
      if (levels[i].LevelType != 'title') {
        for (let j = 0; j < questions.length; j++) {
          //Type 1006 = Diagnosed diseases data type
          if (questions[j].Type == 1006) {
            this.diagnosedDiseasesNames = questions[j].OptionsAnswers;
          }
        }
      }
    }
    this.getDiagnosedDiseasesIds(this.diagnosedDiseasesNames);
    // this.findCompatibleForms(this.listOfForms);
  }

  diagnosedDiseasesIds: any[] = [];
  getDiagnosedDiseasesIds(arrOfNames: any) {
    for (let i = 0; i < arrOfNames.length; i++) {
      for (let j = 0; j < this.diseasesList.length; j++) {
        if (arrOfNames[i] == this.diseasesList[j].Disease) {
          this.diagnosedDiseasesIds.push(this.diseasesList[j].Id);
        }
      }
    }
  }

  diseasesList: any[] = [];
  getAllDiseases(id: number) {
    this._diseases.getAll(id).subscribe((res) => {
      if (res) {
        for (let i = 0; i < res.length; i++) {
          this.diseasesList.push(res[i]);
        }
        this.loading = false;
      }
    });
  }

  getPatient(id: any) {
    this._patients.getSinglePatient(id).subscribe(
      (res) => {
        this.patient = res;
      },
      (e) => {
        console.log(e);
      }
    );
  }

  showDiagnosisTextarea() {
    this.changingDiagnosis = true;
  }

  cancelDiagnosisTextarea() {
    this.changingDiagnosis = false;
    this.Diagnosis = null;
  }

  listOfForms: any[] = [];
  getAllForms(id: number) {
    this.listOfForms = [];
    this._forms.getAll(id).subscribe(
      (res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            if (res[i].IdFormType == 1) {
              this.listOfForms.push(res[i]);
            }
          }
          this.findCompatibleForms(this.listOfForms);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  findCompatibleForms(forms: any) {
    let compatibleForms: any[] = [];
    setTimeout(() => {
      for (let i = 0; i < forms.length; i++) {
        let formDiseases = JSON.parse(forms[i].DiseasesForInvestigation);
        let count = 0;
        for (let j = 0; j < formDiseases.length; j++) {
          if (this.diagnosedDiseasesIds.includes(formDiseases[j])) {
            // compatibleForms.push(forms[i]);
            count++;
          }
        }
        if (count > 0) {
        }
      }
    }, 1000);
  }

  getDiseaseName(id: any) {
    let disease = this.diseasesList.find((dis) => dis.Id == id);
    let result = disease?.Disease;
    return result;
  }

  caseUpdateForm = new FormGroup({
    IdOrganization: new FormControl(0, Validators.required),
    IdForm: new FormControl(0, Validators.required),
    FormLevel: new FormControl(0, Validators.required),
    IdOrganizationalStructure: new FormControl(0, Validators.required),
    IdPatient: new FormControl(0, Validators.required),
    RegistryCaseJson: new FormControl('', Validators.required),
    Diagnosis: new FormControl('', Validators.required),
    IdRecordStatus: new FormControl(0, Validators.required),
    UserOpenedIt: new FormControl(true),
  });

  updatingCase = false;
  updateCase() {
    this.updatingCase = true;
    //update case metadata
    this.updateFormFields();

    this._cases
      .updateCase(this.originalCase.Id, this.caseUpdateForm.value)
      .subscribe(
        (res) => {
          window.location.reload();
        },
        (err) => {
          this.updatingCase = false;
          console.log(err);
        }
      );
  }

  updateFormFields() {
    this.caseUpdateForm.controls['IdOrganization'].setValue(
      this.originalCase.IdOrganization
    );
    this.caseUpdateForm.controls['IdForm'].setValue(this.originalCase.IdForm);
    this.caseUpdateForm.controls['FormLevel'].setValue(
      this.originalCase.FormLevel
    );
    this.caseUpdateForm.controls['IdOrganizationalStructure'].setValue(
      this.originalCase.IdOrganizationalStructure
    );
    this.caseUpdateForm.controls['IdPatient'].setValue(
      this.originalCase.IdPatient
    );
    this.caseUpdateForm.controls['RegistryCaseJson'].setValue(
      JSON.stringify(this.case)
    );
    this.caseUpdateForm.controls['Diagnosis'].setValue(
      this.originalCase.Diagnosis
    );
    this.caseUpdateForm.controls['IdRecordStatus'].setValue(
      this.originalCase.IdRecordStatus
    );
  }

  selectedConditionalDiseases: any[] = [];
  pushCheckVal(
    questionIndex: any,
    type: string,
    levelIndex: number,
    clickedInputId: string,
    answerIndex: number
  ) {
    let input = document.getElementById(
      'form-check' + clickedInputId
    ) as HTMLInputElement;
    let answers = this.case[levelIndex].Form[questionIndex].OptionsAnswers;

    if (answers.includes(answerIndex)) {
      let target = answers.indexOf(answerIndex);
      answers.splice(target, 1);
      input.checked = false;
    } else {
      answers.push(answerIndex);
      input.checked = true;
    }
  }

  uniqueIds: any[] = [];
  genUniqueId() {
    let next = this.uniqueIds.length + 1;
    let result = 'item' + next;
    this.uniqueIds.push(result);
    return result;
  }

  //stepIndex
  //questionIndex = 'qi'

  showCloseCaseConfirmation() {
    this.updatingCase = true;
  }

  cancelCaseClose() {
    this.updatingCase = false;
  }

  closeCase() {
    this.updateFormFields();
    this.caseUpdateForm.controls['IdRecordStatus'].setValue(5); //'5' for deleted
    this._cases
      .updateCase(this.originalCase.Id, this.caseUpdateForm.value)
      .subscribe(
        (res) => {
          window.location.reload();
        },
        (err) => {
          this.updatingCase = false;
          console.log(err);
        }
      );
  }
}
