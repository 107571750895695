<div class="pt-0">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb pt-2">
          <li class="breadcrumb-item"><a href="" routerLink="/activity"><h5 class="mb-0 fw-bold">My Activity</h5></a></li>
          <li *ngIf="option == 1" class="breadcrumb-item active fw-bold" aria-current="page"><h5 class="mb-0 fw-bold">undefined</h5></li>
          <li *ngIf="option == 2" class="breadcrumb-item active fw-bold" aria-current="page"><h5 class="mb-0 fw-bold">undefined</h5></li>
        </ol>
    </nav>
    <p class="text-secondary">Lorem ipsum dolor sit amet.</p>
    <div class="card rounded-4 shadow-sm">
        <div class="card-body">
            
                <div class="row">
                    <div class="col-12">
                        <h6 class="text-muted">Showing 0 results</h6>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Activity Type</th>
                                        <th>Date/Time</th>
                                        <th>Summary</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Case Transfer</td>
                                        <td>Feb 5, 2023</td>
                                        <td>Lorem ipsum dolor sit amet consectetur adipisicing.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-center">
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span class="sr-only">Previous</span>
                        </a>
                      </li>
                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                          <span class="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
            </div>
        </div>
        
    </div>
</div>