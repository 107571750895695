<app-topnav *ngIf="isLoggedIn"></app-topnav>
<div class="p-3 pt-0 overflow-hidden" style="flex:1;">
  <div class="row  g-3 h-100">
    <div *ngIf="isLoggedIn" class="" style="width:277px;" id="sidebar">
  
      <app-sidenav></app-sidenav>
    </div>
    <div id="content" class="col h-100 pb-5" style="overflow-y:scroll;">
      <div id="scroll-ref"></div>
      <router-outlet></router-outlet>
    </div>
</div>
</div>

<div id="popup-alert">transaction received!</div>
