import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  getAll(orgId:number): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/User?idOrganization=${orgId}`);
  }

  delete(id:number): Observable<any> {
    return this.http.delete<any>(`${environment.apiURL}/User/${id}`);
  }

  addNew(body:any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/User`, body);
  }

  update(id:number, body:any): Observable<any> {
    return this.http.put<any>(`${environment.apiURL}/User/${id}`, body);
  }

  getUserRoles(): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/Roles`);
  }
  
  changeUserRole(body:any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/UserRoles`, body);
  }

  getStructuresWhereUserBelongs(): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/UserOrganizationalStructures`);
  }
}
