import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'epidemic';
  isLoggedIn = false;

  constructor(private auth: AuthService, private router: Router, private location: Location) {}
  ngOnInit() {
    const path = this.location.path();
    if(path == '/password-recovery' || path.includes('/password-reset') ) {
      return;
    } else {
      this.auth.isLoggedIn().then((e) => {
        if (!e) {
          this.auth._isConnected.next(false);
          this.router.navigate(['/login'])
        } else {
          this.auth._isConnected.next(true);
        }
      });
      this.auth.userConnected().subscribe((e) => {
        this.isLoggedIn = e;
        if(!e) {
          this.router.navigate(['/login'])
        }
      });
    }
  }

}
