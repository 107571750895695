import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private http: HttpClient) {}

  //get all departments within this organization
  getAll(orgId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiURL}/OrganizationalStructure?idOrganization=${orgId}`
    );
  }

  addNewStructure(body: any): Observable<any> {
    return this.http.post<any>(
      `${environment.apiURL}/OrganizationalStructure`,
      body
    );
  }

  updateStructure(orgId: any, body: any): Observable<any> {
    return this.http.put<any>(
      `${environment.apiURL}/OrganizationalStructure/${orgId}`,
      body
    );
  }

  //get structure levels
  getAllLevels(orgId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiURL}/OrganizationalStructuresLevels?idOrganization=${orgId}`
    );
  }

  deleteStructure(orgId: any): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiURL}/OrganizationalStructure/${orgId}`
    );
  }
}
