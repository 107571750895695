import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
// PurchasePrice: new UntypedFormControl(0, [Validators.min(1), Validators.required]),

export class LoginComponent implements OnInit {
  invalid = false;
  emptyForm = false;
  loading = false;
  serverError = false;
  get f() { return this.loginForm.controls; }

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    const token = localStorage.getItem("token"); 
    if(token != null) {
      this.router.navigate(['/home'])
    }
    
  }

  loginForm = new UntypedFormGroup({
    Email: new UntypedFormControl('', Validators.required),
    Password: new UntypedFormControl('', Validators.required)
  })

  submit() {
    if (this.loginForm.invalid) {
      this.invalid = true;
      return;
    } 

    this.invalid = false;
    this.loading = true;
    this.serverError = false;
    
    this.auth.login(this.loginForm.value).subscribe((response:any) => {

      //default role is Guess
      let role = 'Guess';
      if(response.userRoles.length > 0) {
        role = response.userRoles[response.userRoles.length - 1].RoleName;
      }

      if (response) {
        this.auth.setToken(response?.token);
        this.auth.setUserData(response?.UserName, JSON.stringify(response.userOrganizationStructure), role);
        this.auth._isConnected.next(true);
        this.router.navigate(['/home']);
        window.location.reload();
      } 
    }, (e) => {
      if(e.status == 401 || e.status == 404) {
        this.invalid = true;
      }
      if(e == "Error") {
        this.serverError = true;
      }
      this.loading = false;
      console.log(e)
    })
  }

}
