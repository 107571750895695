import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { CasesService } from 'src/app/services/cases.service';
import { CommonService } from 'src/app/services/common.service';
import { PatientsService } from 'src/app/services/patients.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  orgId:any;
  casesAmount = 0;
  patientsAmount = 0;
  loading = true;
  constructor(
    private _patients: PatientsService,
    private _cases: CasesService
  ) { }

  ngOnInit(): void {
    this.getStructure();
    this.getPatients();
    this.getCases();
  }

  
  getPatients() {
    this._patients.getAllPatients().subscribe((res) => {
      this.patientsAmount = res.length;
    }, (e) => {
      console.log(e);
    })
  }

  getStructure() {
    const structure = localStorage.getItem("structure");
    if(structure != null) {
      var deserialized = JSON.parse(structure)

      this.orgId = deserialized[0].IdOrganization;

    }
  }
  arrOfDates: any[] = [];
  dataCount: any[] = [];
  formattedArrOfDates: any[] = [];

  getCases() {
    this._cases.getAllCases(this.orgId).subscribe((res) => {
      // filter arr to count only the ones with RecordStatus == true
      let filteredArr = res.filter( (e:any) => {
         return e.RecordStatus == true && e.IdRecordStatus == 1;
      })
      // 
      this.casesAmount = filteredArr.length;
      
      //organize the dates for the x axis and store them in arrOfDates
      res.forEach((e:any) => {
        var date = new Date(e.DateCreated);
        var formatted = date.toLocaleDateString("en-US")
        if(!this.arrOfDates.includes(formatted)) {
          this.arrOfDates.push(formatted);
        }
      })
      
      this.arrOfDates.forEach((e) => {
        let count = 0;
        res.forEach((el:any) => {
          var date = new Date(el.DateCreated);
          var formatted = date.toLocaleDateString("en-US")
          if(e == formatted) {
            count++;
          }
        })
        this.dataCount.push(count);
      })

      this.arrOfDates.forEach((e) => {
        var date = new Date(e);
        var formatted = date.toLocaleDateString("en-US", {month: 'short', day: 'numeric'})
        this.formattedArrOfDates.push(formatted);
      })

      this.loading = false;
    }, (e) => {
      console.log(e);
    })
  }
  chartOption: EChartsOption = {
    tooltip: {
      trigger: 'item',

    },
    xAxis: {
      type: 'category',

      data: this.formattedArrOfDates
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {

        data: this.dataCount,
        type: 'line',
        smooth: true,

      }
    ],
  };
}
