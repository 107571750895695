import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CasesService } from 'src/app/services/cases.service';
import { CommonService } from 'src/app/services/common.service';
import { DiseasesService } from 'src/app/services/diseases.service';
import { FormsService } from 'src/app/services/forms.service';
import { GenderService } from 'src/app/services/gender.service';
import { LocationService } from 'src/app/services/location.service';
import { PatientsService } from 'src/app/services/patients.service';

@Component({
  selector: 'app-record-new-case',
  templateUrl: './record-new-case.component.html',
  styleUrls: ['./record-new-case.component.scss'],
})
export class RecordNewCaseComponent implements OnInit {
  orgId: any;
  orgStructureId: number = 0;
  diseasesList: any[] = [];
  locationsList: any[] = [];
  selectedDiseaseId: any;
  listOfForms: any[] = [];
  today = new Date().toDateString();
  diseaseSelected = false;
  noFormAlert = false;
  formJson: any[] = [];
  showFormCreator = false;
  formId = 0;
  gendersList: any[] = [];
  ID_CARD_NUMBER_LENGTH = 6;
  //view options
  //0 = menu
  //1 = new case
  //2 = follow up
  viewOption = 0;

  diagnosisOnMemory: string = '';
  switchView(num: number) {
    this.viewOption = num;
  }

  patientForm = new FormGroup({
    IdCardNumber: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
    Names: new FormControl('', [Validators.required, Validators.minLength(2)]),
    Surnames: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
    ]),
    IdGender: new FormControl(0, Validators.required),
    IdHomeAddress: new FormControl(0, Validators.required),
    IdWorkAddress: new FormControl(0, Validators.required),
    WorkAddress: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
    HomeAddress: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
    ]),
  });

  caseForm = new FormGroup({
    IdOrganization: new FormControl(0),
    IdForm: new FormControl(0),
    FormLevel: new FormControl(0),
    IdOrganizationalStructure: new FormControl(0),
    IdPatient: new FormControl(0),
    RegistryCaseJson: new FormControl(''),
    Diagnosis: new FormControl(''),
    IdRecordStatus: new FormControl(0),
    UserOpenedIt: new FormControl(true),
  });

  constructor(
    private _diseases: DiseasesService,
    private _forms: FormsService,
    private _patients: PatientsService,
    private _cases: CasesService,
    private _location: LocationService,
    private _genders: GenderService,
    private _common: CommonService
  ) {}

  ngOnInit(): void {
    this.getStructure();
    this.getAllDiseases(this.orgId);
    this.fetchAllLocations(this.orgId);
    this.getGenders();
    this.getAllForms(this.orgId);
  }

  selectDisease() {
    this.diseaseSelected = true;
  }

  selectForm(form: any) {
    this.formSelectedForRegistration = form;
    this.viewOption = 1;
    this.formJson = JSON.parse(form.FormJson);

    this.showFormCreator = true;
  }
  getGenders() {
    this._genders.getAllGenders().subscribe(
      (res) => {
        for (let i = 0; i < res.length; i++) {
          this.gendersList.push(res[i]);
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  fetchAllLocations(orgId: number) {
    this.locationsList = [];
    this._location.getAllLocations(orgId).subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        this.locationsList.push(res[i]);
      }
    });
  }

  ngOnDestroy() {
    this.diseaseSelected = false;
  }

  getStructure() {
    const structure = localStorage.getItem('structure');
    if (structure != null) {
      var deserialized = JSON.parse(structure);
      this.orgId = deserialized[0].IdOrganization;
      this.orgStructureId = deserialized[0].Id;
    }
  }

  getAllDiseases(id: number) {
    this._diseases.getAll(id).subscribe((res) => {
      if (res) {
        for (let i = 0; i < res.length; i++) {
          this.diseasesList.push(res[i]);
        }
      }
    });
  }
  resetFormVariables() {
    this.noFormAlert = false;
    this.listOfForms = [];
    this.formJson = [];
  }

  investigationForms: any[] = [];
  formSelectedForRegistration: any;
  getAllForms(id: number) {
    this.resetFormVariables();
    this._forms.getAll(id).subscribe(
      (res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            if (res[i].IdFormType == 3) {
              //notification forms
              this.listOfForms.push(res[i]);
              this.formId = res[i].Id;
            } else {
              //investigation forms
              this.investigationForms.push(res[i]);
            }
          }

          if (this.listOfForms.length == 0) {
            this.noFormAlert = true;
            this.showFormCreator = false;
          }
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }

  selectDiseaseId() {
    if (this.selectedDiseaseId != undefined) {
      this.getAllForms(this.orgId);
    }
  }

  selectedConditionalDiseases: any[] = [];
  pushCheckVal(
    answerIndex: any,
    questionIndex: any,
    type: string,
    index: number,
    clickedInputId: string
  ) {
    let input = document.getElementById(clickedInputId) as HTMLInputElement;
    let answers = this.formJson[index].Form[questionIndex].OptionsAnswers;
    if (type == 'diagnosis') {
      // let disease = this.diseasesList[answerIndex].Disease;
      let disease = this.diseasesList[answerIndex].Id;
      if (answers.includes(disease)) {
        let target = answers.indexOf(disease);
        answers.splice(target, 1);
        this.selectedConditionalDiseases.splice(target, 1);
        input.checked = false;
      } else {
        answers.push(disease);
        this.selectedConditionalDiseases.push(disease);
        input.checked = true;
      }
      // console.log(this.formJson);
    } else {
      if (answers.includes(answerIndex)) {
        let target = answers.indexOf(answerIndex);
        answers.splice(target, 1);
        input.checked = false;
      } else {
        answers.push(answerIndex);
        input.checked = true;
      }
    }
  }

  loading = false;
  missingMandatoryFields = false;
  areThereEmptyMandatoryFields() {
    //check all mandatory fields are filled
    let emptyMandatoryCount = 0;
    for (let form of this.formJson) {
      form.Form.map((question: any) => {
        if (
          question.Mandatory &&
          question.Answer.trim() == '' &&
          question.Type != '4' &&
          question.Type != '5'
        ) {
          emptyMandatoryCount++;
        }
      });
    }

    if (emptyMandatoryCount > 0) {
      this.missingMandatoryFields = true;
      return true;
    } else {
      return false;
    }
  }

  //patient validations
  noIdCardNumber = false;
  noNames = false;
  noSurnames = false;
  noGender = false;
  noHomeAddress = false;
  noWorkAddress = false;
  noHomeLocation = false;
  noWorkLocation = false;
  resetPatientValidations() {
    this.noIdCardNumber = false;
    this.noNames = false;
    this.noSurnames = false;
    this.noGender = false;
    this.noHomeAddress = false;
    this.noWorkAddress = false;
    this.noHomeLocation = false;
    this.noWorkLocation = false;
  }

  areThereEmptyPatientFields() {
    this.resetPatientValidations();

    if (this.patientForm.controls['IdCardNumber'].value?.trim() == '') {
      this.noIdCardNumber = true;
      return true;
    }
    if (this.patientForm.controls['Names'].value?.trim() == '') {
      this.noNames = true;
      return true;
    }
    if (this.patientForm.controls['Surnames'].value?.trim() == '') {
      this.noSurnames = true;
      return true;
    }
    if (this.patientForm.controls['IdGender'].value == 0) {
      this.noGender = true;
      return true;
    }
    if (this.patientForm.controls['IdHomeAddress'].value == 0) {
      this.noHomeLocation = true;
      return true;
    }
    if (this.patientForm.controls['IdWorkAddress'].value == 0) {
      this.noWorkLocation = true;
      return true;
    }
    if (this.patientForm.controls['WorkAddress'].value?.trim() == '') {
      this.noWorkAddress = true;
      return true;
    }
    if (this.patientForm.controls['HomeAddress'].value?.trim() == '') {
      this.noHomeAddress = true;
      return true;
    }

    return false;
  }
  saveRecord() {
    //next step (JUNE 24) validate patient data before submitting form
    const patientDataEmpty = this.areThereEmptyPatientFields();
    if (patientDataEmpty) {
      this._common.scrollToTop();
      return;
    }

    const mandatoriesAreEmpty = this.areThereEmptyMandatoryFields();
    if (mandatoriesAreEmpty) {
      return;
    }

    this.loading = true;

    this.removeUnusedConditionalLevels();
    this.addDiseaseInvestigationLevels();

    if (this.patientExists) {
      //update patient info
      this._patients
        .updateSinglePatient(this.existingPatiendId, this.patientForm.value)
        .subscribe((res) => {
          let patientId = this.existingPatiendId;
          this.caseForm.controls['IdOrganization'].setValue(this.orgId);
          this.caseForm.controls['IdForm'].setValue(this.formId);
          this.caseForm.controls['FormLevel'].setValue(
            this.formJson[0].LevelNumber
          );
          this.caseForm.controls['IdOrganizationalStructure'].setValue(
            this.orgStructureId
          );
          this.caseForm.controls['IdPatient'].setValue(patientId);
          this.caseForm.controls['RegistryCaseJson'].setValue(
            JSON.stringify(this.formJson)
          );
          this.caseForm.controls['IdRecordStatus'].setValue(1);
          this.caseForm.controls['Diagnosis'].setValue(this.diagnosisOnMemory);
          this.postRecord();
        });
    } else {
      this._patients.addNew(this.patientForm.value).subscribe(
        (res) => {
          if (res) {
            let patientId = res.Id;
            this.caseForm.controls['IdOrganization'].setValue(this.orgId);
            this.caseForm.controls['IdForm'].setValue(this.formId);
            this.caseForm.controls['FormLevel'].setValue(
              this.formJson[0].LevelNumber
            );
            this.caseForm.controls['IdOrganizationalStructure'].setValue(
              this.orgStructureId
            );
            this.caseForm.controls['IdPatient'].setValue(patientId);
            this.caseForm.controls['RegistryCaseJson'].setValue(
              JSON.stringify(this.formJson)
            );
            this.caseForm.controls['IdRecordStatus'].setValue(1);
            this.caseForm.controls['Diagnosis'].setValue(
              this.diagnosisOnMemory
            );

            this.postRecord();
          }
        },
        (e) => {
          this.loading = false;
          console.log(e);
        }
      );
    }
  }

  removeUnusedConditionalLevels() {
    //find conditional levels
    const names = this.getDiseaseNames(this.selectedConditionalDiseases);
    for (let i = 0; i < this.formJson.length; i++) {
      if (this.formJson[i].LevelIsConditional) {
        let levelDiseases = this.formJson[i].ConditionalDiseases;
        let countMatchingDiseases = 0;
        for (let j = 0; j < names.length; j++) {
          if (levelDiseases.includes(names[j])) {
            countMatchingDiseases++;
          }
        }
        if (countMatchingDiseases == 0) {
          this.formJson.splice(i, 1);
          i--;
        }
      }
    }
  }

  addDiseaseInvestigationLevels() {
    //iterate over investigation forms and find compatible diseases
    for (let i = 0; i < this.investigationForms.length; i++) {
      const targetDiseases = JSON.parse(
        this.investigationForms[i].DiseasesForInvestigation
      );
      //if any investigation form includes a disease present in selectedConditionalDiseases
      let exists = targetDiseases.some((dis: any) =>
        this.selectedConditionalDiseases.includes(dis)
      );
      if (exists) {
        const levelsToAdd = JSON.parse(this.investigationForms[i].FormJson);

        const titleLevel = {
          LevelType: 'title',
          LevelName: this.investigationForms[i].FormName,
        };
        this.formJson.push(titleLevel);
        //por cada nivel, agregarlo al formJson
        for (let j = 0; j < levelsToAdd.length; j++) {
          this.formJson.push(levelsToAdd[j]);
        }
      }
    }
  }

  postRecord() {
    this._cases.addNew(this.caseForm.value).subscribe(
      (res) => {
        if (res) {
          // console.log(res);
          window.location.reload();
        }
      },
      (e) => {
        this.loading = false;
        // console.log(e);
      }
    );
  }

  containsConditionalDiseases(levelDiseases: any) {
    let result = false;
    let names = this.getDiseaseNames(this.selectedConditionalDiseases);
    if (this.selectedConditionalDiseases.length > 0) {
      for (let i = 0; i < this.selectedConditionalDiseases.length; i++) {
        if (levelDiseases?.ConditionalDiseases.includes(names[i])) {
          result = true;
        }
      }
    }
    return result;
  }

  getDiseaseNames(listOfIds: any) {
    let result: any[] = [];
    for (let i = 0; i < listOfIds.length; i++) {
      let target = this.diseasesList.find((dis) => dis.Id == listOfIds[i]);
      if (target) {
        result.push(target.Disease);
      }
    }
    return result;
  }

  patientExists = false;
  loadingPatient = false;
  existingPatiendId: number | null = null;
  //check if there is a patient who
  //matches the id card number
  searchExistingPatient(event: any) {
    const IdCardNumber = event.target.value;
    if (IdCardNumber.length < this.ID_CARD_NUMBER_LENGTH) {
      return;
    }

    this.loadingPatient = true;

    setTimeout(() => {
      this._patients.getSinglePatient(IdCardNumber).subscribe((res) => {
        //if a patient exists, autofill the "patient info" form
        try {
          if (res.Id != null) {
            this.patientExists = true;
            this.existingPatiendId = res.Id;
            this.patientForm.controls['Names'].setValue(res.Names);
            this.patientForm.controls['Surnames'].setValue(res.Surnames);
            this.patientForm.controls['IdGender'].setValue(res.IdGender);
            this.patientForm.controls['IdHomeAddress'].setValue(
              res.IdHomeAddress
            );
            this.patientForm.controls['IdWorkAddress'].setValue(
              res.IdWorkAddress
            );
            this.patientForm.controls['WorkAddress'].setValue(res.WorkAddress);
            this.patientForm.controls['HomeAddress'].setValue(res.HomeAddress);
            this.patientExists = true;
            this.loadingPatient = false;
          }
        } catch {
          //if a patient doesn't exist, reset the form
          //save patient id card number before resetting the form
          //so the user doesn't have to type it again
          const idCardNumber = this.patientForm.controls['IdCardNumber'].value;
          this.patientForm.reset();

          //then assign the id card number again
          this.patientForm.controls['IdCardNumber'].setValue(idCardNumber);
          this.loadingPatient = false;
        }
      });
    }, 1600);
  }
}
