<ul class="structure-list">
  <li *ngFor="let child of children" class="py-2">
    <!-- <div>{{ child.AreaName }}</div> -->
    <div class="d-flex align-items-center">
      <span>{{ child.AreaName }}</span>
      <div class="dropdown ms-2">
        <button
          class="btn btn-secondary"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="ri-more-2-fill"></i>
        </button>
        <ul class="dropdown-menu">
          <li>
            <a
              class="dropdown-item edit-org-btn"
              [id]="child.Id"
              (click)="handleClickForEdit($event)"
              data-bs-toggle="modal"
              data-bs-target="#editmodal"
              ><i class="ri-pencil-fill"></i> Edit</a
            >
          </li>
          <li>
            <a
              class="dropdown-item text-danger delete-org-btn"
              [id]="'del-' + child.Id"
              data-bs-toggle="modal"
              data-bs-target="#deleteModal"
              ><i class="ri-delete-bin-2-fill"></i> Delete</a
            >
          </li>
        </ul>
      </div>
    </div>
    <app-structure [children]="child.children"></app-structure>
  </li>
</ul>
