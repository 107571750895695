import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivityComponent } from './components/activity/activity.component';
import { CreateNewFormComponent } from './components/create-new-form/create-new-form.component';
import { EditFormComponent } from './components/forms/edit-form/edit-form/edit-form.component';
import { FormsComponent } from './components/forms/forms.component';
import { HomeComponent } from './components/home/home.component';
import { IllnesessComponent } from './components/illnesess/illnesess.component';
import { LoginComponent } from './components/login/login.component';
import { MyCasesComponent } from './components/my-cases/my-cases.component';
import { OrganizationalStructureComponent } from './components/organizational-structure/organizational-structure.component';
import { PatientComponent } from './components/patient/patient.component';
import { RecordNewCaseComponent } from './components/record-new-case/record-new-case.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SearchComponent } from './components/search/search.component';
import { SettingsComponent } from './components/settings/settings.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UserRegistrationComponent } from './components/user-registration/user-registration.component';
import { AuthGuard as AuthorizeUser } from './middleware/auth/auth.guard';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery/password-recovery.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { RegistrantGuardGuard } from './guards/registrant-guard.guard';
import { AdminGuard } from './guards/admin.guard';
const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'home', component: HomeComponent},
  {path: 'record-new-case', component: RecordNewCaseComponent, canActivate: [RegistrantGuardGuard]},
  {path: 'search', component: SearchComponent},
  {path: 'search/patient', component: PatientComponent},
  {path: 'reports', component: ReportsComponent, canActivate: [AdminGuard]},
  {path: 'create-new-form', component: CreateNewFormComponent},
  {path: 'settings', component: SettingsComponent},
  {path: 'illnesess', component: IllnesessComponent},
  {path: 'forms', component: FormsComponent},
  {path: 'forms/edit-form', component: EditFormComponent},
  {path: 'my-cases', component: MyCasesComponent, canActivate: [RegistrantGuardGuard]},
  {path: 'activity', component: ActivityComponent},
  {path: 'password-recovery', component: PasswordRecoveryComponent},
  {path: 'password-reset', component: PasswordResetComponent},
  {path: '', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
