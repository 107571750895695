<span [class]="'pill ' + state.toLowerCase()">
    <span *ngIf="showIcon">
        <i class="ri-check-line" *ngIf="state.toLowerCase() == 'success' "></i> 
        <i class="ri-close-fill" *ngIf="state.toLowerCase() == 'danger' "></i> 
        <i class="ri-alert-fill" *ngIf="state.toLowerCase() == 'warning' "></i> 
        <i class="ri-lock-fill" *ngIf="state.toLowerCase() == 'closed' "></i>
        <i class="ri-lock-unlock-fill" *ngIf="state.toLowerCase() == 'open' "></i>
        <i class="ri-error-warning-fill" *ngIf="state.toLowerCase() == 'info' "></i> 
        <div class="spinner-border spinner-border-sm" role="status" *ngIf="state.toLowerCase() == 'loading' ">
            <span class="visually-hidden">Loading...</span>
          </div>
    </span>
    {{text}}
</span>