<div class="row g-2 mb-3">
    <div class="col-auto ms-auto">
        <div class="form-group">
            <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modal">Add New</button>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12">
    <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Location Name</th>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let l of locationsList">
                        <td class="text-capitalize">{{l.GeographicName}}</td>
                        <td class="text-uppercase">{{l.Code}}</td>
                        <td>
                            <span *ngFor="let t of locationTypes" class="text-capitalize">{{t.Id == l.IdGeographicStructureType ? t.TypeName : ''}}</span>
                        </td>
                        <td class="d-flex">
                            <button class="btn btn-secondary me-2">View Details</button>
                            <button class="btn btn-warning" (click)="editModal(l)" data-bs-toggle="modal" data-bs-target="#editmodal">
                                <i class="bi bi-pencil-fill"></i>
                            </button>
                            <button class="btn btn-danger ms-2" (click)="deleteModal(l.Id)" data-bs-toggle="modal" data-bs-target="#deleteModal">
                                <i class="bi bi-trash-fill"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Transfer Modal -->
<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="modalLabel">Action Title</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row g-2">
            <div class="col-12">
                <form [formGroup]="newLocationForm" action="">
                    <div class="form-floating mb-3">
                        <select formControlName="IdGeographicStructureType" [(ngModel)]="selectedTypePriorityNumber" (change)="checkLocationType()" name="" id="" class="form-select">
                            <option *ngFor="let t of locationTypes" class="text-capitalize" [value]="t.Id">{{t.TypeName}}</option>
                        </select>
                        <label class="form-floating-label" for="">Location type</label>
                    </div>
                    <div class="form-floating mb-3">
                        <select formControlName="IdSuperiorsCode" name="" id="" class="form-select">
                            <option [value]="l.Id" *ngFor="let l of locationsList">{{l.GeographicName}}</option>
                        </select>
                        <label class="form-floating-label" for="">Belongs to</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input formControlName="GeographicName" type="text" class="form-control">
                        <label for="" class="form-floating-label">Location name</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input formControlName="Code" type="text" class="form-control">
                        <label for="" class="form-floating-label">Custom code</label>
                    </div>
                </form>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-transparent" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-success d-flex align-items-center" (click)="saveNewLocation()">
                <span class="me-1">Save location</span> 
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
            </button>
        </div>
      </div>
    </div>
</div>

<!-- edit modal -->
<div class="modal fade" id="editmodal" tabindex="-1" aria-labelledby="editmodal" aria-hidden="true">
    <div [class]="confirmingUpdate? 'd-none' : 'modal-dialog' ">
        <form  [formGroup]="editGeoForm">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="editmodal">Edit Geographical Structure</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-floating mb-3 text-capitalize">
                        <select formControlName="IdGeographicStructureType" [(ngModel)]="selectedTypePriorityNumber" (change)="checkLocationType()" name="" id="" class="form-select">
                            <option  *ngFor="let t of locationTypes" class="text-capitalize" [value]="t.Id">{{t.TypeName}}</option>
                        </select>
                        <label class="form-floating-label" for="">Location type</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input formControlName="GeographicName"  type="text" class="form-control" >
                        <label for="">Location Name</label>
                    </div>
                    <div class="form-floating mb-3">
                        <input formControlName="Code" type="text" class="form-control">
                        <label for="" class="form-floating-label">Custom code</label>
                    </div>
                    <div class="form-floating mb-3">
                        <select formControlName="IdSuperiorsCode"  name="" id="" class="form-select">
                            <option class="text-capitalize" [value]="l.Id" *ngFor="let l of locationsList">{{l.GeographicName}}</option>
                        </select>
                        <label for="">Parent Location</label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-transparent" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-success" (click)="showSaveConfirmation()">Save changes</button>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-dialog" *ngIf="confirmingUpdate">
        <div class="modal-content">
            <div class="modal-header border-0">
                <h1 class="modal-title fs-5" id="deleteModalLabel">Update disease</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            <div class="modal-body">
                <p>This disease will be updated for all users and records.</p>
            </div>
            <div class="modal-footer bg-light">
                <button type="button" class="btn btn-transparent" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-success" (click)="confirmUpdate()">Confirm update</button>
              </div>
        </div>
    </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h1 class="modal-title fs-5" id="deleteModalLabel">Delete this location</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to delete this location? this action cannot be undone and the location details will be lost forever.</p>
          <p>Records and patient's data related to this location will stay intact.</p>
        </div>
        <div class="modal-footer bg-light">
          <button type="button" class="btn btn-transparent" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger" (click)="confirmDelete()">Yes, delete it</button>
        </div>
      </div>
    </div>
</div>