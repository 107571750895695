import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { SidenavComponent } from './components/shared/sidenav/sidenav.component';
import { TopnavComponent } from './components/shared/topnav/topnav.component';
import { LoginComponent } from './components/login/login.component';
import { RecordNewCaseComponent } from './components/record-new-case/record-new-case.component';
import { SearchComponent } from './components/search/search.component';
import { PatientComponent } from './components/patient/patient.component';
import { ReportsComponent } from './components/reports/reports.component';

//echarts
import { NgxEchartsModule } from 'ngx-echarts';
import { UserRegistrationComponent } from './components/user-registration/user-registration.component';
import { CreateNewFormComponent } from './components/create-new-form/create-new-form.component';
import { SettingsComponent } from './components/settings/settings.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { OrganizationalStructureComponent } from './components/organizational-structure/organizational-structure.component';

import { HttpClientModule,  HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingHttpRequestInterceptor } from './interceptors/loading.interceptor';
// INTERCEPTORES
import { TokenInterceptor } from './interceptors/token.interceptor';
import { IllnesessComponent } from './components/illnesess/illnesess.component';
import { FormsComponent } from './components/forms/forms.component';
import { ChevronRightComponent } from './components/shared/icons/chevron-right/chevron-right.component';
import { GeographicalStructureComponent } from './components/settings/geographical-structure/geographical-structure.component';
import { FormsSettingsComponent } from './components/settings/forms-settings/forms-settings.component';
import { NewFormComponent } from './components/forms/new-form/new-form.component';
import { CloseComponent } from './components/shared/icons/close/close.component';
import { MyCasesComponent } from './components/my-cases/my-cases.component';
import { ActivityComponent } from './components/activity/activity.component';
import { EditFormComponent } from './components/forms/edit-form/edit-form/edit-form.component';
import { DiseaseNamePipe } from './middleware/disease-name.pipe';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery/password-recovery.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';

import { NgxPaginationModule } from 'ngx-pagination';
import {NgxPrintModule} from 'ngx-print';
import { PasswordComponent } from './components/settings/password/password/password.component';
import { PillComponent } from './components/shared/pill/pill.component';
import { BackBtnComponent } from './components/shared/back-btn/back-btn.component';
import { StructureComponent } from './components/shared/structure/structure.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SidenavComponent,
    TopnavComponent,
    LoginComponent,
    RecordNewCaseComponent,
    SearchComponent,
    PatientComponent,
    ReportsComponent,
    UserRegistrationComponent,
    CreateNewFormComponent,
    SettingsComponent,
    UserManagementComponent,
    OrganizationalStructureComponent,
    IllnesessComponent,
    FormsComponent,
    ChevronRightComponent,
    GeographicalStructureComponent,
    FormsSettingsComponent,
    NewFormComponent,
    CloseComponent,
    MyCasesComponent,
    ActivityComponent,
    EditFormComponent,
    DiseaseNamePipe,
    PasswordRecoveryComponent,
    PasswordResetComponent,
    PasswordComponent,
    PillComponent,
    BackBtnComponent,
    StructureComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxPaginationModule, 
    NgxPrintModule,
    NgxEchartsModule.forRoot({
      echarts: chartModule
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingHttpRequestInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function chartModule(): any {
  return import('echarts');
}