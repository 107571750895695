<div class="pt-0">
    <div class="row g-3">
        <div class="col-12">
            <div class="card rounded-4 shadow-sm animate__animated animate__fadeIn">
                <div class="card-body">
                    <h5 class="mb-0 py-2 fw-bold">Dashboard</h5>
                    <div class="row g-3">
                        <div class="col-md-8">
                            <div class="card rounded-4 shadow-sm">
                                <div class="card-header py-3">
                                    <div class="d-flex justify-content-between">
                                        <h6 class="mb-0"  *ngIf="!loading">Reported Cases</h6>
                                        <div style="width:180px;" class="placeholder-glow" *ngIf="loading">
                                            <div class="d-flex">
                                                <span class="placeholder col-6"></span>
                                            </div>
                                        </div>
                                        <div style="width:180px;" class="placeholder-glow" *ngIf="loading">
                                            <div class="d-flex">
                                                <span class="placeholder col-6"></span>
                                                <span class="placeholder col-5 ms-3"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div *ngIf="!loading" echarts [options]="chartOption" class="demo-chart"></div>
                                    <div *ngIf="loading">
                                        <div class="d-flex justify-content-center align-items-center" style="height: 300px;">
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <span class="ms-3">Loading chart...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="row g-3">
                                <div class="col-12">
                                    <div class="card rounded-4 shadow-sm h-100">
                                        <div class="card-body">
                                            <div class="" *ngIf="!loading">
                                                <div>
                                                    <div class="tab-icon-div col-og">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
                                                        </svg>
                                                    </div>
                                                    <h2 class="fw-bold mb-1">{{patientsAmount}}</h2>
                                                </div>
                                                <p class="mb-0">Registered Patients</p>
                                            </div>
                                            <div class="placeholder-glow d-flex flex-column align-items-center" *ngIf="loading">
                                                <span class="placeholder col-3"></span>
                                                <br>
                                                <span class="placeholder col-6"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card rounded-4 shadow-sm h-100">
                                        <div class="card-body">
                                            <div class="" *ngIf="!loading">
                                                <div>
                                                    <div class="tab-icon-div col-dg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-vcard-fill" viewBox="0 0 16 16">
                                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5ZM9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8Zm1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5Zm-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96c.026-.163.04-.33.04-.5ZM7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z"/>
                                                          </svg>
                                                    </div>
                                                    <h2 class="fw-bold mb-1">{{casesAmount}}</h2>
                                                </div>
                                                <p class="mb-0">Active Cases</p>
                                            </div>
                                            <div class="placeholder-glow d-flex flex-column align-items-center" *ngIf="loading">
                                                <span class="placeholder col-3"></span>
                                                <br>
                                                <span class="placeholder col-6"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>