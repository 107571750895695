import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, UrlSegmentGroup, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

public _isConnected = new Subject<boolean>();
private _userData = new Subject<any>();
public _permissionLevel = new Subject<number>();

constructor(private http: HttpClient, private router: Router) { }

  public userConnected(): Observable<boolean> {
    return this._isConnected.asObservable();
  }

  public permissionLevel(): Observable<number> {
    return this._permissionLevel.asObservable();
  }

  async setUserData(username: string, orgStructure: any, role: string) {
    localStorage.setItem("username", username);
    localStorage.setItem("structure", orgStructure);
    localStorage.setItem("xn02g39", role == 'Admin' ? '4c1mm' : 'g3s5' )
    this._permissionLevel.next(role == 'Admin' ? 1 : 2);

  }

  async setToken( token: string )
  {
    await localStorage.setItem("token", token);
  }

  getPermissionLevel() {
    const role = localStorage.getItem("xn02g39")
    if(role != null && role == "g3s5") {
      return 2;
    } else {
      return 1;
    } 
  }

  async isLoggedIn()
  {
    const token = await localStorage.getItem("token");
    return token == null ? false :  true;
  }

  login(credentials:any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/Login`, credentials)
  }

  logOut()
  {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("structure");
    //remove role on logout
    localStorage.removeItem("xn02g39")
    this._isConnected.next(false);
    this.router.navigate(['/login']);
  }

  redireccionar( returnUrl: string )
  {
    const _: UrlTree = this.router.parseUrl(returnUrl);
    const url: UrlSegmentGroup = _.root.children["primary"];
    const urlPrincipal = url?.segments[0]?.path;

    if (!urlPrincipal) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: '/', authRequired: true } });
      return;
    }
    // SI EXISTE UN PARAMETRO EN LA URL
    if ( _.queryParams?.section ) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: urlPrincipal, section: _.queryParams?.section, authRequired: true } });
      return;
    }
    // SI NO EXISTE UN PARAMETRO EN LA URL
    else{
      this.router.navigate(['/login'], { queryParams: { returnUrl: urlPrincipal, authRequired: true } });
      return;
    }
  }

  recoverPassword(body:any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/Password`, body);
  }
}
