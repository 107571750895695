<div class="text-center login-wrap">
    <main class="form-signin w-100 pt-5">
      <img class="mb-0" src="/assets/img/logo.webp" alt="ESS brand image" width="172" >
      <div class="mt-3">
        <h1 class="fs-5 mb-3 fw-normal">Password Recovery</h1>
      </div>
      <!-- CONFIRMATION MESSAGE -->
      <div class="mt-4" *ngIf="linkSent">
        <h5 class="fw-bold">A password-recovery link has been sent to the email associated to this National ID.</h5>
      </div>
      <form [formGroup]="form" *ngIf="!linkSent">
        <div *ngIf="invalid" class="alert alert-danger mt-3" role="alert">
          Invalid National ID. <br>Please try again.
        </div>
        <div class="form-floating mb-2">
          <input (keyup)="invalid = false" [class.is-invalid]="invalid || empty" formControlName="NationalID" type="text" class="form-control" id="emailInput" placeholder="">
          <label for="emailInput">National ID</label>
        </div>
    
        <button [disabled]="loading" [class]="loading ? 'w-100 btn btn btn-primary disabled' : 'w-100 btn btn btn-primary'" (click)="submit()">
          <span *ngIf="!loading">Send a recovery link</span>
          <span *ngIf="loading">
            <div class="spinner-border text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </span>
        </button>
        
      </form>
      <p class="mt-4"><a routerLink="/login">Back to sign in</a></p>
      <p class="mt-5 mb-3 text-muted">© 2023</p>
    </main>
</div>