<div class="pt-0 pb-3">
    <div class="" *ngIf="option == 0">
        <div class="">
                <div class="row g-2 mb-3">
                    <div class="col">
                        <h5 class="mb-0">{{listOfForms.length}} forms</h5>
                    </div>
                    <div class="col-auto ms-auto">
                        <div class="form-group">
                            <button  [disabled]="loading" (click)="changeView(1)" class="btn btn-success btn-sm">Add New</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Form Name</th>
                                        <th>Form Id</th>
                                        <th>Current Version</th>
                                        <th style="width:120px;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="!loading">
                                    <tr *ngFor="let f of listOfForms | paginate: { id: 'pagination2', itemsPerPage: resultsPerPage, currentPage: currentPage, totalItems: listOfForms.length }">
                                        <td class="text-capitalize">{{f.FormName}}</td>
                                        <td>{{f.FormCode}}</td>
                                        <td>{{f.FormVersion}}</td>
                                        <td class="d-flex">
                                            <button (click)="previewForm(f)" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#viewFormModal">
                                                View <i class="ri-arrow-right-s-line"></i>
                                            </button>
                                            <div class="dropdown ms-2">
                                                <button class="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="ri-more-2-fill"></i>
                                                </button>
                                                <ul class="dropdown-menu">
                                                  <li><a class="dropdown-item" (click)="editForm(f)"><i class="ri-pencil-fill"></i> Edit</a></li>
                                                  <li><a class="dropdown-item text-danger" (click)="deleteModal(f.Id)" data-bs-toggle="modal" data-bs-target="#deleteModal"><i class="ri-delete-bin-2-fill"></i> Delete</a></li>
                                                </ul>
                                            </div>
                                        </td>
                                        
                                    </tr>
                                </tbody>

                                <tbody *ngIf="loading" class="placeholder-glow">
                                    <tr>
                                        <td>
                                            <span class="placeholder col-12"></span>
                                        </td>
                                        <td>
                                            <span class="placeholder col-3"></span>
                                        </td>
                                        <td>
                                            <span class="placeholder col-12"></span>
                                        </td>
                                    </tr>
                            </table>
                        </div>
                    </div>
                </div>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-center">
                  <pagination-controls  id="pagination2"
                    (pageChange)="currentPage = $event"
                    (pageBoundsCorrection)="currentPage = $event"
                    [maxSize]="9"
                    [directionLinks]="true"
                      [autoHide]="true"
                      [responsive]="true"
                      previousLabel="Previous"
                      nextLabel="Next"
                      screenReaderPaginationLabel="Paginacion"
                      screenReaderPageLabel="pagina"
                      screenReaderCurrentLabel="Estas en la pagina">
                    >
        </pagination-controls>
            </div>
        </div>
    </div>
    <app-new-form *ngIf="option == 1"></app-new-form>
    <app-edit-form *ngIf="option == 2" [diseasesList]="diseasesList" [formForEdit]="formSelectedForEdit" [dataTypes]="dataTypes"></app-edit-form>
</div>


<!-- Modal -->
<div class="modal fade" id="viewFormModal" tabindex="-1" aria-labelledby="viewFormModal" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <!-- SI ES PARA AGREGAR ENFERMEDAD-->
        <div>
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Form Preview</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cancelForm()"></button>
                </div>
                <div class="modal-body">
                    <div class="card bg-light mb-3" *ngFor="let l of levelsForPreview; index as a">
                        <div class="card-body">
                            <h6>Level name: <strong>{{l.LevelName}}</strong></h6>
                            <h6 *ngIf="l.LevelIsConditional" class="">Condition: {{l.LevelConditionalTitle}}</h6>
                            <div *ngFor="let q of l.Form; index as i">
                                <p class="fw-bold mb-1 mt-3 text-capitalize" *ngIf="q.Type == '1008' ">{{q.Question}}</p>
                                <div class="ps-3" *ngIf="q.Type != '1008' ">
                                    <p class="mb-0"><i class="bi bi-dot"></i> {{q.Question}}</p>
                                    <div *ngFor="let d of dataTypes">
                                        <label for="" class="text-secondary text-capitalize ps-3" *ngIf="d.Id == q.Type"><small>{{d.DataTypeName}} <span *ngIf="q.Mandatory" class="badge fw-medium text-bg-danger"><strong>Mandatory</strong></span></small></label>  
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item bg-light" *ngFor="let o of q.Options; index as j">{{o}}</li>
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h1 class="modal-title fs-5" id="deleteModalLabel">Delete this form</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Are you sure you want to delete this form? this action cannot be undone and the form will be lost forever.</p>
          <p>Records and patient's data related to this form will stay intact.</p>
        </div>
        <div class="modal-footer bg-light">
          <button [disabled]="deletingForm" type="button" class="btn btn-transparent" data-bs-dismiss="modal">Cancel</button>
          <button [disabled]="deletingForm" type="button" class="btn btn-danger" (click)="confirmDelete()">
            <span *ngIf="deletingForm" class="d-inline">
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span class="ms-2">Deleting form</span>
            </span>
            <span *ngIf="!deletingForm" class="d-inline">
                <span>Yes, delete it</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>