import { Component, OnInit } from '@angular/core';
import { DiseasesService } from 'src/app/services/diseases.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-illnesess',
  templateUrl: './illnesess.component.html',
  styleUrls: ['./illnesess.component.scss']
})
export class IllnesessComponent implements OnInit{

  //list of diseases to be rendered
  diseasesList: any = [];
  orgId:any;
  formSent = false;
  formSuccess = false;
  invalidForm = false;
  objInMemory:any = {};
  diseaseToBeDeleted:number = 0;
  confirmingUpdate = false;
  loading = true;
  constructor(private diseases: DiseasesService, private _modal: ModalService) {}
  
  resetDirectives() {
    this.formSent = false;
    this.formSuccess = false;
    this.invalidForm = false;
  }
  getStructure() {
    const structure = localStorage.getItem("structure");
    if(structure != null) {
      var deserialized = JSON.parse(structure)
      this.orgId = deserialized[0].IdOrganization;
    }

  }

  ngOnInit(): void {
    this.getStructure();
    this.getDiseases();
  }

  addDiseaseForm = new UntypedFormGroup({
    IdOrganization: new UntypedFormControl(),
    Disease: new UntypedFormControl('', Validators.required),
    Code: new UntypedFormControl('xyz', Validators.required),
    DiseaseDescription: new UntypedFormControl('', [Validators.required, Validators.maxLength(200)]),
    TypeDescription: new UntypedFormControl('')
  })

  addNewDisease() {

    this.resetDirectives()

    if (this.addDiseaseForm.invalid) {
      this.invalidForm = true;
      return;
    } 

    this.addDiseaseForm.controls['IdOrganization'].setValue(this.orgId);

    this.formSent = true;

    this.diseases.addNew(this.addDiseaseForm.value).subscribe((res) => {
      // console.log(res)
      if(res && res.RecordStatus) {
        this.toggleModal();
        this.resetDirectives();
        this.resetAddForm();
        this.getDiseases();
      } 
    }, (e) => {
      console.log(e);
    })

  }

  getDiseases() {
    this.diseasesList = [];
    this.diseases.getAll(this.orgId).subscribe((res) => {

      for(let i=0 ; i < res.length ; i++) {
        this.diseasesList.push(res[i]);
      }
      this.loading = false;
    })

  }

  resetAddForm() {
    this.addDiseaseForm.controls['Disease'].setValue('')
    this.addDiseaseForm.controls['DiseaseDescription'].setValue('')
  }

  toggleModal() {
    var modal = document.getElementsByClassName('btn-close');
    for(let i = 0 ; i < modal.length ; i++) {
      let closebtn = modal[i] as HTMLElement;
      closebtn.click();
    }
  }

  deleteDisease(id:number) {
    this.diseases.delete(id).subscribe((res) => {
      if(res) {
        this._modal.hideModal();
        this.getDiseases();
      }
    }, e => console.log(e)
    )
  }

  
  deleteModal(id:number) {
    this.diseaseToBeDeleted = id;
  }

  confirmDelete() {
    this.deleteDisease(this.diseaseToBeDeleted);
  }

  editModal(obj: any) {

    this.addDiseaseForm.controls['IdOrganization'].setValue(obj.IdOrganization);
    this.addDiseaseForm.controls['Disease'].setValue(obj.Disease);
    this.addDiseaseForm.controls['Code'].setValue(obj.Code);
    this.addDiseaseForm.controls['DiseaseDescription'].setValue(obj.DiseaseDescription);
    this.addDiseaseForm.controls['TypeDescription'].setValue('');

    this.objInMemory = obj;
  }

  showSaveConfirmation() {
    this.confirmingUpdate = true;

  }

  confirmUpdate() {
    this.diseases.update(this.objInMemory.Id, this.addDiseaseForm.value).subscribe((res) => {
      
        this._modal.hideModal();
        this.getDiseases();
      this.addDiseaseForm.reset();
      this.objInMemory = {};
      this.confirmingUpdate = false;
    }, (e) => {
      console.log(e);
    })
  }
}
