<!-- Modal -->
<div
  class="modal fade"
  id="illnessModal"
  tabindex="-1"
  aria-labelledby="illnessModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <!-- SI ES PARA AGREGAR ENFERMEDAD-->
    <form [formGroup]="addDiseaseForm" (ngSubmit)="addNewDisease()">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="illnessModalLabel">Add Diseases</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div *ngIf="invalidForm" class="alert alert-danger" role="alert">
            Please fill all fields.
          </div>
          <div class="form-floating mb-3">
            <input
              formControlName="Disease"
              type="text"
              [class]="invalidForm ? 'form-control is-invalid' : 'form-control'"
              id="floatingInput"
              placeholder="Flu"
            />
            <label for="floatingInput">Name</label>
          </div>
          <div class="form-floating mb-3">
            <input
              formControlName="DiseaseDescription"
              type="text"
              [class]="invalidForm ? 'form-control is-invalid' : 'form-control'"
              id="floatingInput2"
              placeholder="Flu"
            />
            <label for="floatingInput2">Description</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="submit"
            [class]="formSent ? 'btn btn-success disabled' : 'btn btn-success'"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="pt-0">
  <!-- <h5 class="mb-0 py-2 fw-bold">Diseases</h5>
    <p class="text-secondary">Visualize, add and manage diseasess.</p> -->
  <div class="">
    <div>
      <form action="">
        <div class="row g-2 mb-3">
          <!-- <div class="col">
                        <div class="form-group">
                            <div class="input-group">
                                <input [disabled]="loading" type="text" class="form-control">
                                <button [disabled]="loading" class="btn btn-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                      </svg>
                                </button>
                            </div>
                        </div>
                    </div> -->
          <div class="col-auto ms-auto">
            <div class="form-group">
              <button
                [disabled]="loading"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#illnessModal"
              >
                Add New
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 placeholder-glow" *ngIf="loading">
            <span class="placeholder col-2"></span>
            <br />
            <table class="table">
              <tbody>
                <tr>
                  <td><span class="placeholder col-3"></span></td>
                  <td><span class="placeholder col-6"></span></td>
                  <td><span class="placeholder col-11"></span></td>
                  <td><span class="placeholder col-7"></span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12" *ngIf="!loading">
            <h6 class="text-secondary">
              Showing
              <strong
                >{{ diseasesList.length }}
                {{ diseasesList.length == 1 ? "result" : "results" }}</strong
              >
            </h6>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Disease</th>
                    <th>Description</th>
                    <th style="width: 120px">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let p of diseasesList">
                    <td>{{ p.Id }}</td>
                    <td>{{ p.Disease }}</td>
                    <td>{{ p.DiseaseDescription }}</td>
                    <th class="d-flex">
                      <button
                        class="btn btn-warning"
                        (click)="editModal(p)"
                        data-bs-toggle="modal"
                        data-bs-target="#editmodal"
                      >
                        <i class="bi bi-pencil-fill"></i>
                      </button>
                      <button
                        class="btn btn-danger ms-2"
                        (click)="deleteModal(p.Id)"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                      >
                        <i class="bi bi-trash-fill"></i>
                      </button>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <h1 class="modal-title fs-5" id="deleteModalLabel">
          Delete this disease
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          Are you sure you want to delete this disease? this action cannot be
          undone and the disease details will be lost forever.
        </p>
        <p>
          Records and patient's data related to this disease will stay intact.
        </p>
      </div>
      <div class="modal-footer bg-light">
        <button
          type="button"
          class="btn btn-transparent"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger" (click)="confirmDelete()">
          Yes, delete it
        </button>
      </div>
    </div>
  </div>
</div>

<!-- edit modal -->
<div
  class="modal fade"
  id="editmodal"
  tabindex="-1"
  aria-labelledby="editmodal"
  aria-hidden="true"
>
  <div [class]="confirmingUpdate ? 'd-none' : 'modal-dialog'">
    <form [formGroup]="addDiseaseForm">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="editmodal">Edit disease</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <input
              formControlName="Disease"
              type="text"
              class="form-control"
              [value]="objInMemory.Disease"
            />
            <label for="">Disease Name</label>
          </div>
          <div class="form-floating mb-3">
            <textarea
              formControlName="DiseaseDescription"
              style="min-height: 140px"
              cols="30"
              rows="10"
              type="text"
              class="form-control"
              [value]="objInMemory.DiseaseDescription"
            ></textarea>
            <label for="">Description</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-transparent"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-success"
            (click)="showSaveConfirmation()"
          >
            Save changes
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-dialog" *ngIf="confirmingUpdate">
    <div class="modal-content">
      <div class="modal-header border-0">
        <h1 class="modal-title fs-5" id="deleteModalLabel">Update disease</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>This disease will be updated for all users and records.</p>
      </div>
      <div class="modal-footer bg-light">
        <button
          type="button"
          class="btn btn-transparent"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-success" (click)="confirmUpdate()">
          Confirm update
        </button>
      </div>
    </div>
  </div>
</div>
