<div class="py-4">
  <div class="col-12 col-lg-8 offset-lg-2">
    <div class="mb-4">
      <h6 class="mb-2"><strong>Form type:</strong></h6>
      <div class="form-floating">
        <select
          [class.is-invalid]="noFormType"
          (change)="changeFormType()"
          [(ngModel)]="formTypeId"
          name=""
          id=""
          class="form-select"
        >
          <option value="3">Notification</option>
          <option value="1">Disease Investigation</option>
        </select>
        <label for="">Select form type</label>
      </div>
      <label for="" *ngIf="noFormType" class="text-danger"
        >Please select a form type</label
      >
    </div>
    <div class="mb-5" *ngIf="showDiseaseSelector">
      <h6 class="mb-2">Select the diseases this form will be used for</h6>
      <div
        class="form-check"
        *ngFor="let disease of diseasesList"
        (click)="toggleDiseaseCheck(disease.Id)"
      >
        <input class="form-check-input" type="checkbox" [value]="disease.Id" />
        <label class="form-check-label">
          {{ disease.Disease }}
        </label>
      </div>
    </div>
    <div class="mb-5">
      <h6 class="mb-2 mt-3"><strong>General deails:</strong></h6>
      <div class="row g-2 mb-3">
        <div class="col-12 col-md-6">
          <div class="form-floating">
            <input
              [(ngModel)]="formCodeInMemory"
              type="text"
              [class]="noFormCode ? 'form-control is-invalid' : 'form-control'"
              placeholder="0000"
            />
            <label for="">Form Code</label>
          </div>
          <label for="" *ngIf="noFormCode" class="text-danger"
            >Please assign a code to this form</label
          >
        </div>
        <div class="col-12 col-md-6">
          <div class="form-floating">
            <input
              [(ngModel)]="formVersionInMemory"
              [disabled]="true"
              type="text"
              value="1"
              class="form-control"
            />
            <label for="">Version</label>
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating">
            <input
              [class.is-invalid]="noFormName"
              [(ngModel)]="formNameInMemory"
              type="text"
              class="form-control"
            />
            <label for="">Form Name</label>
          </div>
          <label for="" *ngIf="noFormName" class="text-danger"
            >Please assign a name to this form</label
          >
        </div>
      </div>
      <!-- display list of completed levels in memory-->
      <div class="accordion" id="accordionExample">
        <div class="accordion-item" *ngFor="let f of generalForm; index as a">
          <h2 class="accordion-header" id="heading{{ a }}">
            <!-- data-bs-target="#collapse{{a}}" -->
            <button
              class="accordion-button bg-light"
              type="button"
              data-bs-toggle="collapse"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <span>Level name:</span>
              <strong class="ms-1"> {{ f.LevelName }}</strong>
            </button>
          </h2>
          <div
            id="collapse{{ a }}"
            class="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <h6 class="mb-1"><strong>Questions:</strong></h6>
              <div class="mb-2" *ngFor="let q of f.Form; index as i">
                <div class="fw-bold" *ngIf="q.Type == '1008'">
                  {{ q.Question }}
                </div>
                <div class="ps-2" *ngIf="q.Type != '1008'">
                  <p class="mb-0">{{ q.Question }}</p>
                  <div *ngFor="let d of dataTypes">
                    <label
                      for=""
                      class="text-secondary ps-3 text-capitalize"
                      *ngIf="d.Id == q.Type"
                      ><small>{{ d.DataTypeName }}</small></label
                    >
                  </div>
                  <ul class="list-group list-group-flush">
                    <li
                      class="list-group-item"
                      *ngFor="let o of q?.Options; index as j"
                    >
                      {{ o }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- create a new level-->
      <div class="row">
        <!-- *ngIf="showFormCreator" -->
        <div class="col-12" *ngIf="showLevelCreator">
          <hr />
          <div class="row g-3">
            <div
              class="col-12"
              *ngIf="isNotificationForm && generalForm.length > 0"
            >
              <div>
                <div class="form-check mb-2">
                  <input
                    (click)="changeLevelConditionStatus()"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label">
                    This is a conditional level
                  </label>
                </div>
                <div *ngIf="levelIsConditional">
                  <div class="form-floating mb-3">
                    <input
                      [(ngModel)]="levelOnMemory.LevelConditionalTitle"
                      type="text"
                      class="form-control"
                    />
                    <label for="">Condition title</label>
                  </div>
                  <div class="form-check" *ngFor="let disease of diseasesList">
                    <input
                      (click)="toggleConditionalDisease(disease.Disease)"
                      type="checkbox"
                      class="form-check-input"
                      [value]="disease.Disease"
                    />
                    <label for="" class="form-check-label">{{
                      disease.Disease
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-floating mb-3">
                <input
                  (keyup)="noLevelName = false"
                  [class.is-invalid]="noLevelName"
                  [(ngModel)]="levelOnMemory.LevelName"
                  type="text"
                  class="form-control"
                  placeholder="Triage"
                />
                <label for="">Level name</label>
              </div>
              <label for="" class="text-danger" *ngIf="noLevelName"
                >Please assign a name to this level</label
              >
              <label for="" class="text-danger" *ngIf="noQuestionsInLevel"
                >Please add at least 1 question to this level</label
              >
            </div>
          </div>
          <!-- <h6 class="mb-1"><strong>Questions:</strong></h6> -->
          <div class="mb-2" *ngFor="let q of levelOnMemory.Form; index as i">
            <div *ngIf="q.Type == 1008">
              Section: <strong>{{ q.Question }}</strong>
            </div>
            <!-- section title-->
            <div class="ps-3" *ngIf="q.Question != '' && q.Type != 1008">
              <p class="mb-0">
                <span>{{ q?.Question }}</span>
              </p>
              <div *ngFor="let d of dataTypes">
                <label
                  for=""
                  class="text-secondary ps-3 text-capitalize"
                  *ngIf="d.Id == q.Type"
                  ><small>{{ d.DataTypeName }}</small></label
                >
              </div>
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item"
                  *ngFor="let o of q?.Options; index as j"
                >
                  {{ i + 1 }}.{{ j + 1 }} {{ o }}
                </li>
              </ul>
            </div>
          </div>
          <p>
            <a
              class="custom-link ps-3"
              data-bs-toggle="modal"
              data-bs-target="#newquestionmodal"
              >Add new question
              <svg
                fill="currentColor"
                width="20"
                height="20"
                viewBox="0 0 22 22"
                xmlns="http://www.w3.org/2000/svg"
                id="memory-plus"
              >
                <path d="M12 17H10V12H5V10H10V5H12V10H17V12H12Z" />
              </svg>
            </a>
          </p>
        </div>
        <!-- control buttons column-->
        <div class="col-12">
          <hr />
          <div class="d-flex justify-content-between">
            <button
              class="btn btn-danger"
              *ngIf="!showLevelCreator"
              (click)="goBack()"
            >
              Cancel Form <app-close></app-close>
            </button>
            <div class="row g-2" *ngIf="!showLevelCreator">
              <div class="col-auto">
                <button class="btn btn-secondary" (click)="addLevel()">
                  Add Level
                  <svg
                    fill="currentColor"
                    width="20"
                    height="20"
                    viewBox="0 0 22 22"
                    xmlns="http://www.w3.org/2000/svg"
                    id="memory-plus"
                  >
                    <path d="M12 17H10V12H5V10H10V5H12V10H17V12H12Z" />
                  </svg>
                </button>
              </div>
              <div class="col-auto">
                <button
                  [disabled]="submittingNewForm"
                  class="btn btn-success"
                  (click)="saveForm()"
                >
                  <span *ngIf="!submittingNewForm">Save Form</span>
                  <span *ngIf="submittingNewForm" class="d-inline">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <span>Saving form</span>
                  </span>
                </button>
              </div>
            </div>
            <button
              class="btn btn-danger"
              *ngIf="showLevelCreator"
              (click)="discardLevel()"
            >
              Discard Level
            </button>
            <button
              class="btn btn-success"
              *ngIf="showLevelCreator"
              (click)="saveLevel()"
            >
              Save Level
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="newquestionmodal"
  tabindex="-1"
  aria-labelledby="newquestionmodalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div>
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="newquestionmodal">
            Add new question
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="newQuestion" action="">
            <div class="row g-2 mb-3">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="">Question type:</label>
                  <select
                    formControlName="Type"
                    (change)="checkDataType()"
                    name=""
                    id=""
                    class="form-select"
                  >
                    <option *ngFor="let t of dataTypes" [value]="t.Id">
                      {{ t.DataTypeName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-md-6" *ngIf="showContactField">
                <div class="form-group">
                  <label for="">Contact type:</label>
                  <select
                    formControlName="ContactType"
                    class="form-select"
                    name=""
                    id=""
                  >
                    <option value="phone">Phone</option>
                    <option value="email">Email</option>
                  </select>
                </div>
              </div>
              <div class="col-12" *ngIf="showDiseaseMessage">
                <p>
                  This option will display a list of diseases for selection.
                </p>
              </div>
              <div class="col" *ngIf="showQuestionFieldForNewQuestion">
                <div class="form-group">
                  <label for="">Question:</label>
                  <input
                    (keyup)="newQuestionIsEmpty = false"
                    [class.is-invalid]="newQuestionIsEmpty"
                    formControlName="Question"
                    type="text"
                    class="form-control"
                  />
                  <label for="" class="text-danger" *ngIf="newQuestionIsEmpty"
                    >Please enter a question.</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-check">
                  <input
                    formControlName="Mandatory"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Mandatory
                  </label>
                </div>
              </div>
            </div>
          </form>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item"
              *ngFor="let o of optionsInMemory; index as i"
            >
              {{ i + 1 }}. {{ o }}
              <span class="text-danger fs-5 float-end"
                ><i class="bi bi-x-square-fill"></i
              ></span>
            </li>
          </ul>
          <form [formGroup]="singleOption" action="" *ngIf="showOptionsInput">
            <label for="" class="mt-3">New option:</label>
            <div class="row g-2">
              <div class="col">
                <div class="form-group">
                  <input
                    formControlName="Option"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-auto">
                <button class="btn btn-success" (click)="addOption()">
                  Add Option
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <!-- || newQuestion.controls.Question.pristine || newQuestionIsEmpty -->
          <button
            [disabled]="newQuestion.controls.Type.pristine"
            type="button"
            class="btn btn-primary"
            (click)="saveQuestion()"
          >
            Save question
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
