<div class="pt-0">
  <div>
    <div>
      <form action="">
        <div class="row g-2 mb-3">
          <div class="col-auto ms-auto">
            <div class="form-group">
              <button
                [disabled]="loading"
                class="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#modal"
              >
                Add New
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h6 class="text-secondary mb-2" *ngIf="!loading">
              Showing
              <strong
                >{{ users.length }}
                {{ users.length == 1 ? "result" : "results" }}</strong
              >
            </h6>
            <span class="placeholder-glow" *ngIf="loading"
              ><span class="placeholder col-4"></span
            ></span>
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody *ngIf="loading">
                  <tr class="placeholder-glow">
                    <td><span class="placeholder col-8"></span></td>
                    <td><span class="placeholder col-9"></span></td>
                    <td><span class="placeholder col-6"></span></td>
                    <td><span class="placeholder col-8"></span></td>
                    <td><span class="placeholder col-5"></span></td>
                    <td><span class="placeholder col-4"></span></td>
                    <td><span class="placeholder col-2"></span></td>
                  </tr>
                </tbody>
                <thead *ngIf="!loading">
                  <tr>
                    <!-- <th><div class="aligned">User ID</div></th> -->
                    <th><div class="aligned">National ID</div></th>
                    <th><div class="aligned">Name</div></th>
                    <th><div class="aligned">Contact</div></th>
                    <!-- <th><div class="aligned">Org. Structures</div></th> -->
                    <th>
                      <div class="aligned">Role</div>
                    </th>
                    <th><div class="aligned">Registration Date</div></th>
                    <th><div class="aligned">Actions</div></th>
                  </tr>
                </thead>
                <tbody *ngIf="!loading">
                  <tr *ngFor="let p of users">
                    <!-- <td>
                                            <div class="aligned">{{p.Id}}</div>
                                        </td> -->
                    <td>
                      <div class="aligned">
                        {{ p.IdCardNumber }}
                      </div>
                    </td>
                    <td>
                      <div class="aligned">{{ p.Names }} {{ p.Surnames }}</div>
                    </td>
                    <td>
                      <div class="aligned mb-1">
                        {{ p.Email }}
                        <i
                          class="bi bi-check-circle-fill fs-4 text-success"
                          *ngIf="p.EmailConfirmed"
                        ></i>
                        <i
                          class="bi bi-exclamation-circle-fill text-danger ms-2"
                          *ngIf="!p.EmailConfirmed"
                        ></i>
                      </div>
                      <div class="aligned">
                        {{ p.PhoneNumber }}
                        <i
                          class="bi bi-check-circle-fill fs-4 text-success"
                          *ngIf="p.PhoneConfirmed"
                        ></i>
                        <i
                          class="bi bi-exclamation-circle-fill text-danger ms-2"
                          *ngIf="!p.PhoneConfirmed"
                        ></i>
                      </div>
                    </td>
                    <!-- <td>
                                            <div class="aligned">
                                                <ul class="list-group list-group-flush">
                                                    <li class="list-group-item no-padding" *ngFor="let o of p.Structures">{{pipeOrgName(o.IdOrganizationalStructure)}}</li>
                                                </ul>
                                            </div>
                                        </td> -->
                    <td>
                      <div class="aligned">
                        {{ p.RoleName == "Admin" ? "Admin" : "Registrant" }}
                      </div>
                    </td>
                    <td>
                      <div class="aligned">
                        {{ p.DateCreated | date }}
                      </div>
                    </td>
                    <td class="d-flex">
                      <div class="aligned">
                        <div class="btn-group btn-group-sm">
                          <button
                            class="btn btn-warning"
                            (click)="editModal(p)"
                            data-bs-toggle="modal"
                            data-bs-target="#editmodal"
                          >
                            <i class="bi bi-pencil-fill"></i> Edit
                          </button>
                          <button
                            class="btn btn-danger"
                            (click)="deleteModal(p.Id)"
                            data-bs-toggle="modal"
                            data-bs-target="#deleteModal"
                          >
                            <i class="bi bi-trash3-fill"></i> Delete
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!--  -->
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="modal"
  tabindex="-1"
  aria-labelledby="modal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <form [formGroup]="addNewUserForm" (ngSubmit)="addNewUser()">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="modal">Add new user</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div *ngIf="invalidForm" class="alert alert-danger" role="alert">
            Please fill all fields.
          </div>
          <div class="row g-2">
            <div class="col-6">
              <div class="form-floating mb-3">
                <input
                  formControlName="Names"
                  type="text"
                  [class]="
                    invalidForm ? 'form-control is-invalid' : 'form-control'
                  "
                  id="floatingInput"
                  placeholder="Flu"
                />
                <label for="floatingInput">Name</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-floating mb-3">
                <input
                  formControlName="Surnames"
                  type="text"
                  [class]="
                    invalidForm ? 'form-control is-invalid' : 'form-control'
                  "
                  id="floatingInput2"
                  placeholder="Flu"
                />
                <label for="floatingInput2">Last Name</label>
              </div>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input
              formControlName="IdCardNumber"
              type="text"
              [class]="invalidForm ? 'form-control is-invalid' : 'form-control'"
              id="floatingInput3"
              placeholder="Flu"
            />
            <label for="floatingInput3">National ID Number</label>
          </div>
          <hr />
          <div class="form-floating mb-3">
            <input
              formControlName="PhoneNumber"
              type="phone"
              [class]="invalidForm ? 'form-control is-invalid' : 'form-control'"
              id="floatingInput4"
              placeholder="Flu"
            />
            <label for="floatingInput4">Phone Number</label>
          </div>
          <div class="form-floating mb-3">
            <input
              formControlName="Email"
              type="email"
              [class]="invalidForm ? 'form-control is-invalid' : 'form-control'"
              id="floatingInput5"
              placeholder="Flu"
            />
            <label for="floatingInput5">Email</label>
          </div>
          <div class="form-floating mb-3">
            <input
              formControlName="Password"
              type="password"
              [class]="invalidForm ? 'form-control is-invalid' : 'form-control'"
              id="floatingInput6"
              placeholder="Flu"
            />
            <label for="floatingInput6">Password</label>
          </div>
          <hr />
          <div class="form-floating mb-3">
            <select
              formControlName="IdOrganizationalStructure"
              [class]="invalidForm ? 'form-select is-invalid' : 'form-select'"
              id="floatingSelect7"
              aria-label="Floating label select example"
            >
              <option *ngFor="let o of organizations" [value]="o.Id">
                {{ o.AreaName }}
              </option>
            </select>
            <label for="floatingSelect7">Select organization structure</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="submit"
            [class]="formSent ? 'btn btn-success disabled' : 'btn btn-success'"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Delete Modal -->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <h1 class="modal-title fs-5" id="deleteModalLabel">Delete this user</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>
          Are you sure you want to delete this user? this action cannot be
          undone and the user details will be lost forever.
        </p>
        <p>
          Records and patient's data related to this user will stay intact, but
          this user
          <strong class="fw-bold"
            >will not be able to access any ESS SYSTEM.</strong
          >
        </p>
      </div>
      <div class="modal-footer bg-light">
        <button
          type="button"
          class="btn btn-transparent"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger" (click)="confirmDelete()">
          Yes, delete it
        </button>
      </div>
    </div>
  </div>
</div>

<!-- edit modal -->
<div
  class="modal fade"
  id="editmodal"
  tabindex="-1"
  aria-labelledby="editmodal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <form [formGroup]="userEditForm">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="editmodal">Edit user information</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row g-1 mb-3">
            <div class="col-6">
              <small>Registered on:</small>
              <p class="fw-bold mb-0">{{ userOnEdit.DateCreated | date }}</p>
            </div>
            <div class="col-6">
              <small>National ID:</small>
              <p class="fw-bold mb-0">{{ userOnEdit.IdCardNumber }}</p>
            </div>
            <div class="col-12">
              <small>Organizational structures:</small>
              <p class="fw-bold mb-1" *ngFor="let str of userOnEdit.Structures">
                {{ pipeOrgName(str.IdOrganizationalStructure) }}
              </p>
            </div>
          </div>
          <div class="row g-1">
            <div class="col-6">
              <div class="form-floating mb-3">
                <input
                  formControlName="Names"
                  type="text"
                  class="form-control"
                  [value]="userOnEdit.Names"
                />
                <label for="">Names:</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-floating mb-3">
                <input
                  formControlName="Surnames"
                  type="tex"
                  class="form-control"
                  [value]="userOnEdit.Surnames"
                />
                <label for="">Surnames:</label>
              </div>
            </div>
          </div>
          <div class="form-floating mb-3">
            <input
              formControlName="Email"
              type="email"
              class="form-control"
              [value]="userOnEdit.Email"
            />
            <label for="">Email</label>
          </div>
          <div class="form-floating mb-3">
            <input
              formControlName="PhoneNumber"
              type="phone"
              class="form-control"
              [value]="userOnEdit.PhoneNumber"
            />
            <label for="">Phone number</label>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row g-2 w-100">
            <div class="col">
              <div class="form-group w-100">
                <div class="form-floating">
                  <select
                    (change)="changeUserRole($event)"
                    name=""
                    id=""
                    class="form-select"
                  >
                    <option
                      [selected]="userOnEdit.RoleName == role.RoleName"
                      [value]="role.Id"
                      *ngFor="let role of roles"
                    >
                      {{ role.RoleName == "Admin" ? "Admin" : "Registrant" }}
                    </option>
                  </select>
                  <label for="">Role</label>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <button
                (click)="applyRoleChange()"
                [disabled]="!roleDropdownChanged || awaitingRoleChange"
                class="btn btn-success h-100"
              >
                <span *ngIf="!awaitingRoleChange">Apply</span>
                <span
                  *ngIf="awaitingRoleChange"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-transparent"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            [disabled]="
              !userEditForm.get('Email')?.dirty &&
              !userEditForm.get('PhoneNumber')?.dirty
            "
            type="button"
            class="btn btn-success"
            (click)="submitUserUpdate()"
          >
            Save changes
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
