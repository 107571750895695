import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, FormArray, FormControl } from '@angular/forms';
import { DiseasesService } from 'src/app/services/diseases.service';
import { FormsService } from 'src/app/services/forms.service';
import { Question } from 'src/app/models/question.model';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-new-form',
  templateUrl: './new-form.component.html',
  styleUrls: ['./new-form.component.scss']
})
export class NewFormComponent implements OnInit {
  orgId:any;
  userId:any;
  users: any = [];
  orgStructureId: number = 0;
  organizations: any = [];
  selectedDiseaseId: any;
  formCodeInMemory: any = '';
  formVersionInMemory: number = 1;
  invalidForm = false;
  formSent = false;
  formSuccess = false;
  showQuestionField = false;
  newFormTitle = '';
  listOfForms: any[] = [];
  dataTypes: any[] = [];
  mainFormJson: any[] = [];
  levelNameInMemory = 'triage';
  levelQuestionsInMemory: any[] = [];
  formTypeId:number = 0;
  enabledConditionalQuestion = false;
  newQuestion = new UntypedFormGroup({
    Question: new UntypedFormControl(''),
    Type: new UntypedFormControl(0),
    Lenght: new UntypedFormControl(1000),
    Mandatory: new UntypedFormControl(false),
    ContactType: new UntypedFormControl(''),
  })

  singleOption = new UntypedFormGroup({
    Option: new UntypedFormControl('')
  })

  levelOnMemory = {
    LevelNumber: 1,
    LevelName: '',
    LevelComplete: false,
    LevelIsConditional: false,
    LevelConditionalTitle: '',
    ConditionalDiseases: [0],
    Form: [{
      Question: '',
      Answer: '',
      Type: 0,
      Length: 0,
      Mandatory: false,
      Options: [{}],
      OptionsAnswers: []
    }]
  }

  //form definitivo que va para el POST
  mainForm = new UntypedFormGroup({
    IdOrganization: new UntypedFormControl(0),
    IdOrganizationalStructure: new UntypedFormControl(0),
    IdFormType: new FormControl(0),
    FormCode: new UntypedFormControl(''),
    DiseasesForInvestigation: new UntypedFormControl(''),
    FormName: new UntypedFormControl(''),
    FormVersion: new UntypedFormControl(1),
    FormJson: new UntypedFormControl(''),
    IdFormStatus: new UntypedFormControl(0),
    IdRecordStatus: new UntypedFormControl(0),
    RecordStatus: new UntypedFormControl(true)
  })
  //aqui es donde voy a meter todos los levels
  generalForm: any[] = []

  constructor(
    private _diseases: DiseasesService,
    private _forms: FormsService,
    private _router: Router,
    private _common: CommonService
    ) {}

  ngOnInit() {
    this.getStructure();
    this.getAllDiseases(this.orgId);
    this.getDataTypes();
    this.selectDiseaseId();
  }

  diseasesList: any[] = [];
  getAllDiseases(id:number) {
    this._diseases.getAll(id).subscribe((res) => {
      if(res) {
        for(let i = 0 ; i < res.length ; i++) {
          this.diseasesList.push(res[i]);
        }
      }
    })
  }


  getStructure() {
    const structure = localStorage.getItem("structure");
    if(structure != null) {
      var deserialized = JSON.parse(structure)
      this.orgId = deserialized[0].IdOrganization;
      this.orgStructureId = deserialized[0].Id;
    }
  }

  getDataTypes() {
    this._forms.getDataTypes().subscribe((res) => {
      if(res) {
        for(let i = 0 ; i < res.length ; i++) {
        this.dataTypes.push(res[i])
        }
      }
    }, (e) => {
      console.log(e);
    })
  }

  showFormCreator = true; //used to be defaulted to false
  selectDiseaseId() {
    if(this.selectedDiseaseId != undefined) {
      this.showFormCreator = true;
    } else {
      this.showFormCreator = false;
    }
  }
  
  showLevelCreator = true;
  addLevel() {
    this.showLevelCreator = true;
    this.enabledConditionalQuestion = false;
    this.levelIsConditional = false;
  }

  discardLevel() {
    this.showLevelCreator = false;
    //reset level on memory for next level addition
    this.levelOnMemory = {
      LevelNumber: 1,
      LevelName: '',
      LevelComplete: false,
      LevelIsConditional: false,
      LevelConditionalTitle: '',
      ConditionalDiseases: [],
      Form: [{
        Question: '',
        Answer: '',
        Type: 0,
        Length: 0,
        Mandatory: false,
        Options: [{}],
        OptionsAnswers: []
      }]
    }
  }

  showOptionsInput = false;
  optionsInMemory: any[] = []
  showQuestionFieldForNewQuestion = true;
  showContactField = false;
  showDiseaseMessage = false;
  
  checkDataType() {
    let val = this.newQuestion.controls['Type'].value;
    this.clearQuestionValidations();

    if(val == 4 || val == 5) {
      this.showOptionsInput = true;
      this.showQuestionFieldForNewQuestion = true;
    } else if (val == 1006) { 
      this.newQuestion.controls['Question'].setValue('Diagnosed Disease');
      //1006 is diagnosed disease
      this.showDiseaseMessage = true
    } else if (val == 1007) { 
      //1007 is contact
      this.showContactField = true;
    } else {
      this.showQuestionFieldForNewQuestion = true;
    }
  }

  clearQuestionValidations() {
    this.showDiseaseMessage = false;
    this.showQuestionFieldForNewQuestion = false;
    this.showContactField = false;
    this.showOptionsInput = false;
    this.optionsInMemory = [];
  }

  addOption() {
    
    this.optionsInMemory.push(this.singleOption.controls['Option'].value);
    this.singleOption.controls['Option'].setValue('');
  }

  newQuestionIsEmpty: boolean = false;
  saveQuestion() {
    if(this.newQuestion.controls['Question'].value.trim() == '') {
      this.newQuestionIsEmpty = true;
      return;
    }

    let newQuest = {
      Question: this.newQuestion.controls['Question'].value,
      Answer: '',
      Type: this.newQuestion.controls['Type'].value,
      Length: 1000,
      Mandatory: this.newQuestion.controls['Mandatory'].value,
      Options: this.optionsInMemory,
      OptionsAnswers: [],
      ContactType: this.newQuestion.controls['ContactType'].value
    }
    if(this.newQuestion.controls['ContactType'].value == 'phone') {
      newQuest.Question = 'Phone';
    } else if (this.newQuestion.controls['ContactType'].value == 'email') {
      newQuest.Question = 'Email';
    }
    this.levelOnMemory.Form.push(newQuest);

    if(this.levelOnMemory.Form[0].Question == '') {
      this.levelOnMemory.Form.shift();
    }
    this.newQuestion.reset();
    this.optionsInMemory = [];
  }

  noLevelName: boolean = false;
  noQuestionsInLevel: boolean = false;

  saveLevel() {
    //validate that new level has a name
    if(this.levelOnMemory.LevelName.trim() == '' || this.levelOnMemory.LevelName == undefined) {
      this.noLevelName = true;
      return;
    }
    //validate that new level has at least one question
    if(this.levelOnMemory.Form[0].Question.trim() == '') {
      this.noQuestionsInLevel = true;
      return;
    }
    

    this.levelOnMemory.ConditionalDiseases = this.conditionalDiseasesOnMemory;
    // push level to general form
    this.generalForm.push(this.levelOnMemory);

    //hide level creation inputs
    this.showLevelCreator = false;
    //reset level on memory for next level addition
    this.levelOnMemory = {
      LevelNumber: 1,
      LevelName: '',
      LevelComplete: false,
      LevelIsConditional: false,
      LevelConditionalTitle: '',
      ConditionalDiseases: [],
      Form: [{
        Question: '',
        Answer: '',
        Type: 0,
        Length: 0,
        Mandatory: false,
        Options: [{}],
        OptionsAnswers: []
      }]
    }
    this.conditionalDiseasesOnMemory = [];
    this.enabledConditionalQuestion = false;
  }

  formNameInMemory: string = '';
  formIsIncomplete: boolean = false;
  saveForm() {
    
    this.mainForm.controls['IdOrganization'].setValue(this.orgId);
    this.mainForm.controls['IdOrganizationalStructure'].setValue(this.orgStructureId);
    this.mainForm.controls['IdFormType'].setValue(Number(this.formTypeId));
    this.mainForm.controls['FormCode'].setValue(this.formCodeInMemory.toString());
    this.mainForm.controls['FormVersion'].setValue(this.formVersionInMemory);

    //agregar numero de nivel a cada nivel
    for(let i = 0 ; i < this.generalForm.length ; i++) {
      this.generalForm[i].LevelNumber = i + 1;
    }

    let toString = JSON.stringify(this.generalForm);
    this.mainForm.controls['FormJson'].setValue(toString);
    this.mainForm.controls['IdFormStatus'].setValue(1);
    this.mainForm.controls['IdRecordStatus'].setValue(1);
    this.mainForm.controls['FormName'].setValue(this.formNameInMemory);

    //if it's a disease investigation form, serialize the list of diseases that the
    //form will be used for
    if(this.mainForm.controls['IdFormType'].value == 1) {
      let serializedDiseasesList = JSON.stringify(this.idsOfSelectedDiseasesForInvestigation);
      this.mainForm.controls['DiseasesForInvestigation'].setValue(serializedDiseasesList);
    }
    
    this.saveFormToDb(this.mainForm.value);
  }

  noFormCode = false;
  noFormName = false;
  noFormType = false;
  submittingNewForm = false;
  saveFormToDb(form:any) {
    this.noFormCode = false;
    this.noFormName = false;
    this.noFormType = false;

    if(this.formCodeInMemory.trim() == '') {
      this.noFormCode = true;
      return;
    };

    if(this.formNameInMemory.trim() == '') {
      this.noFormName = true;
      return;
    }

    if(this.formTypeId == 0) {
      this.noFormType = true;
      return;
    }

    this.submittingNewForm = true;
    this._forms.addNew(form).subscribe((res) => {
      
      this._router.navigateByUrl("/forms");
      window.location.reload();
      this._common.customAlert('Form created successfully');
    }, (e) => {
      this.submittingNewForm = false;
      console.log(e);
    })
  }

  goBack() {
    window.location.reload();
  }

  enableConditionalQuestion() {
    this.enabledConditionalQuestion = !this.enabledConditionalQuestion;

  }

  showDiseaseSelector = false;
  isNotificationForm = true;
  changeFormType() {
    this.mainForm.controls['IdFormType'].setValue(this.formTypeId);

    if(this.formTypeId == 1) {
      this.showDiseaseSelector = true;
      this.isNotificationForm = false;
    } else {
      this.showDiseaseSelector = false;
      this.idsOfSelectedDiseasesForInvestigation = [];
      this.isNotificationForm = true;
    }
  }

  idsOfSelectedDiseasesForInvestigation: any[] = [];
  toggleDiseaseCheck(diseaseId: number)  {
    if(this.idsOfSelectedDiseasesForInvestigation.includes(diseaseId)) {
      let index = this.idsOfSelectedDiseasesForInvestigation.indexOf(diseaseId);
      this.idsOfSelectedDiseasesForInvestigation.splice(index, 1);
    } else {
      this.idsOfSelectedDiseasesForInvestigation.push(diseaseId);
    }
  }

  levelIsConditional = false;
  changeLevelConditionStatus() {
    this.levelIsConditional = !this.levelIsConditional;
    this.levelOnMemory.LevelIsConditional = this.levelIsConditional;
  }

  conditionalDiseasesOnMemory: any[] = [];
  toggleConditionalDisease(diseaseId:any) {
    if(this.conditionalDiseasesOnMemory.includes(diseaseId)) {
      let index = this.conditionalDiseasesOnMemory.indexOf(diseaseId);
      this.conditionalDiseasesOnMemory.splice(index,1);
    } else {
      this.conditionalDiseasesOnMemory.push(diseaseId);
    }
  }
} 
