<div class="text-center login-wrap">
    <main class="form-signin w-100 pt-5">
      
      <form [formGroup]="loginForm" (ngSubmit)="submit()">
        <img class="mb-0" src="/assets/img/logo.webp" alt="ESS brand image" width="172" >
        <div class="mt-3">
          <h1 class="fs-5 mb-3 fw-normal">Sign in</h1>
        </div>
        <div *ngIf="invalid" class="alert alert-danger mt-3" role="alert">
          Invalid credentials. <br>Please try again.
        </div>
        <div *ngIf="serverError" class="alert alert-danger mt-3" role="alert">
          Connection lost. <br>Please try again later or contact your system admin.
        </div>
        <div class="form-floating mb-2">
          <input (keyup)="invalid = false" [class.is-invalid]="invalid" formControlName="Email" type="text" class="form-control" id="emailInput" placeholder="">
          <label for="emailInput">National ID</label>
        </div>
        <div class="form-floating">
          <input (keyup)="invalid = false" [class.is-invalid]="invalid" formControlName="Password" type="password" class="form-control" id="passwordInput" placeholder="Password">
          <label for="passwordInput">Password</label>
        </div>
    
        <button [class]="loading ? 'w-100 btn btn btn-primary disabled' : 'w-100 btn btn btn-primary'" type="submit">
          <span *ngIf="!loading">Sign in</span>
          <span *ngIf="loading">
            <div class="spinner-border text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </span>
        </button>
       
        <p class="mt-4">Forgot your password? <a routerLink="/password-recovery">Click here</a></p>
        <p class="mt-5 mb-3 text-muted">© 2023</p>
      </form>
    </main>
</div>