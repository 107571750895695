import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlSegment } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('token');
    const urlTree = this._router.parseUrl(this._router.url);
    const urlSegments: UrlSegment[] = urlTree.root.children.primary.segments;

    let pathWithoutParams = '';
    urlSegments.forEach(segment => {
      pathWithoutParams += '/' + segment.path;
    });

    if(!token) {
      if (pathWithoutParams == '/password-recovery' || pathWithoutParams == '/password-reset') {

        return next.handle(req);
  
      } else {
        this._auth.logOut();
        return next.handle(req);
      }
    }

    const headers = req.clone({
      headers: req.headers.set('Authorization', `bearer ${token}`),
    });

    return next.handle(headers);
  }
}