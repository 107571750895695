import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { OrganizationService } from 'src/app/services/organization.service';
import { ModalService } from 'src/app/services/modal.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit {
  orgId: any;
  users: any = [];
  organizations: any = [];

  invalidForm = false;
  formSent = false;
  formSuccess = false;
  loading = true;
  roles: any[] = [];
  //user roles
  // Id = 1 - Admin
  // Id = 2 - Guess

  constructor(
    private _users: UsersService,
    private _organization: OrganizationService,
    private _modal: ModalService,
    private _common: CommonService
  ) {}

  ngOnInit() {
    this.getStructure();
    this.getAllUsers(this.orgId);
    this.getOrganizations(this.orgId);
    this.getRoles();
    console.log('latest version active');
  }

  getRoles() {
    this._users.getUserRoles().subscribe(
      (res) => {
        console.log(res);
        this.roles = res;
      },
      (err) => console.log(err)
    );
  }
  resetDirectives() {
    this.formSent = false;
    this.formSuccess = false;
    this.invalidForm = false;
  }

  userRoleForm = new FormGroup({
    IdUser: new FormControl(0),
    IdRole: new FormControl(0),
  });

  roleDropdownChanged: boolean = false;
  changeUserRole(event: any) {
    this.userRoleForm.controls['IdUser'].setValue(this.userOnEdit.Id);
    this.userRoleForm.controls['IdRole'].setValue(event.target.value);
    this.roleDropdownChanged = true;
  }

  awaitingRoleChange: boolean = false;
  applyRoleChange() {
    this.awaitingRoleChange = true;
    this._users.changeUserRole(this.userRoleForm.value).subscribe(
      (res) => {
        this.getAllUsers(this.orgId);
        this._modal.hideModal();
        this._common.customAlert('New role applied!');
        this.awaitingRoleChange = false;
        this.roleDropdownChanged = false;
      },
      (err) => console.log(err)
    );
  }

  getOrganizations(orgId: number) {
    this._organization.getAll(orgId).subscribe((res) => {
      if (res) {
        for (let i = 0; i < res.length; i++) {
          this.organizations.push(res[i]);
        }
        console.log(this.organizations);
      }
    });
  }

  getAllUsers(id: number) {
    this.users = [];
    this._users.getAll(id).subscribe(
      (res) => {
        if (res != null) {
          var data = res;
          for (let i = 0; i < data.length; i++) {
            var modDate = data[i].DateCreated;
            this.users.push(data[i]);
          }
          this._users.getStructuresWhereUserBelongs().subscribe((res) => {
            this.users.map((user: any) => {
              user.Structures = res.filter(
                (relation: any) => relation.IdUser == user.Id
              );
            });
          });
          this.loading = false;
        }
      },
      (e) => console.log(e)
    );
  }

  getStructure() {
    const structure = localStorage.getItem('structure');
    if (structure != null) {
      var deserialized = JSON.parse(structure);
      this.orgId = deserialized[0].IdOrganization;
    }
  }

  deleteUser(id: number) {
    this._users.delete(id).subscribe((res) => {
      if (res) {
        this._modal.hideModal();
        this.getAllUsers(this.orgId);
      }
    });
  }

  addNewUserForm = new UntypedFormGroup({
    IdOrganization: new UntypedFormControl(0, Validators.required),
    IdOrganizationalStructure: new UntypedFormControl(0, Validators.required),
    Password: new UntypedFormControl('', Validators.required),
    Names: new UntypedFormControl('', Validators.required),
    Surnames: new UntypedFormControl('', Validators.required),
    IdCardNumber: new UntypedFormControl('', Validators.required),
    Email: new UntypedFormControl('', Validators.required),
    PhoneNumber: new UntypedFormControl('', Validators.required),
  });

  addNewUser() {
    this.resetDirectives();
    this.addNewUserForm.controls['IdOrganization'].setValue(this.orgId);

    if (this.addNewUserForm.invalid) {
      this.invalidForm = true;
      return;
    }

    this._users.addNew(this.addNewUserForm.value).subscribe((res) => {
      if (res) {
        this.addNewUserForm.reset();
        this._modal.hideModal();
        this.getAllUsers(this.orgId);
        this.invalidForm = false;
      }
    });
  }

  userToBeDeleted: number = 0;
  deleteModal(id: number) {
    this.userToBeDeleted = id;
  }

  confirmDelete() {
    this.deleteUser(this.userToBeDeleted);
  }

  userEditForm = new FormGroup({
    IdOrganization: new FormControl(0),
    UserName: new FormControl(''),
    Names: new FormControl(''),
    Surnames: new FormControl(''),
    IdCardNumber: new FormControl(''),
    Email: new FormControl(''),
    PhoneNumber: new FormControl(''),
    IdRecordStatus: new FormControl(0),
    RecordStatus: new FormControl(false),
  });
  userOnEdit: any = {};

  editModal(obj: any) {
    this.getUserInfo();
    this.userOnEdit = obj;
    this.userEditForm.controls['IdOrganization'].setValue(this.orgId);
    this.userEditForm.controls['UserName'].setValue(
      obj.Names + ' ' + obj.Surnames
    );
    this.userEditForm.controls['Names'].setValue(obj.Names);
    this.userEditForm.controls['Surnames'].setValue(obj.Surnames);
    this.userEditForm.controls['IdCardNumber'].setValue(obj.IdCardNumber);
    this.userEditForm.controls['Email'].setValue(obj.Email);
    this.userEditForm.controls['PhoneNumber'].setValue(obj.PhoneNumber);
    this.userEditForm.controls['IdRecordStatus'].setValue(1);
    this.userEditForm.controls['RecordStatus'].setValue(true);
  }

  submitUserUpdate() {
    this._users.update(this.userOnEdit.Id, this.userEditForm.value).subscribe(
      (res) => {
        this._modal.hideModal();
        this.getAllUsers(this.orgId);
      },
      (err) => console.log(err)
    );
  }

  userStructure: any[] = [];
  async getUserInfo() {
    this.userStructure = [];
    var struct = await localStorage.getItem('structure');
    if (struct != null) {
      var arr = JSON.parse(struct);
      for (let i = 0; i < arr.length; i++) {
        this.userStructure.push(arr[i]);
      }
    }
  }

  userStructureRelations: any[] = [];
  getStructuresOfSelectedUser() {
    this._users.getStructuresWhereUserBelongs().subscribe((res) => {
      this.userStructureRelations = res;
    });
  }

  pipeOrgName(orgId: number) {
    var org = this.organizations.find((org: any) => org.Id == orgId);
    return org.AreaName;
  }
}
