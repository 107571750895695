import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PasswordService } from 'src/app/services/password/password.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  invalid: boolean = false;
  loading: boolean = false;
  linkSent: boolean = false;
  success: boolean = false;
  token: string | null = null;
  empty: boolean = false;
  dontMatch: boolean = false;
  constructor(private _password: PasswordService, private _activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this._activatedRoute.queryParamMap.subscribe(params => { 
        this.token = params.get('key');
    })
  }

  form = new FormGroup({
    Password: new FormControl('', Validators.required)
  })

  submit() {
    //reset validations
    this.empty = false;
    this.dontMatch = false;

    //password field must not be empty
    const newPassword = this.form.controls['Password'].value;
    if(newPassword?.trim() == '') {
      this.empty = true;
      return;
    }

    //confirm password
    const confirmation = document.getElementById('passwordConfirmationInput') as HTMLInputElement;
    if(confirmation.value != newPassword) {
      this.dontMatch = true;
      return;
    }

    //send new password and token to endpoint

    if(this.token != null) {
      this._password.setNewPassword(this.token, this.form.value).subscribe((res) => {
        this.success = true;
      }, err => console.log(err));
    }
  }
}
