import { Component, Input, OnInit } from '@angular/core';
import { FormsService } from 'src/app/services/forms.service';

@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss']
})
export class EditFormComponent implements OnInit {
  @Input() diseasesList: any[] = [];
  @Input() formForEdit: any = {};
  @Input() dataTypes: any[] = [];
  formJson: any[] = [];
  FormVersion: number | null = null;
  formObject: any = {};
  formId: number = 0;
  constructor(private _forms: FormsService) { }

  ngOnInit(): void {
    this.FormVersion = this.formForEdit.FormVersion;
    this.formObject = this.formForEdit;
    this.formJson = JSON.parse(this.formForEdit.FormJson);
  }

  deleteOption(levelIndex: number, questionIndex:number, optionIndex:number) {

    this.formJson[levelIndex].Form[questionIndex].Options.splice(optionIndex, 1); 
  }

  addOption(levelIndex: number,questionIndex:number, inputId:string) {
    const input = document.getElementById('optionsInput-' + inputId) as HTMLInputElement;
    if(input.value != '') {
      this.formJson[levelIndex].Form[questionIndex].Options.push(input.value);
      input.value = '';
    }
  }

  newQuestion = {
    Answer: '',
    ContactType: '',
    Length: 0,
    Mandatory: false,
    Options: [],
    OptionsAnswers: [],
    Question: '',
    Type: 1
  }

  newLevel = {
    ConditionalDiseases: [],
    Form: [],
    LevelComplete: false,
    LevelConditionalTitle: '',
    LevelIsConditional: false,
    LevelName: '',
    LevelNumber: 0
  }
  addQuestion(levelIndex: number) {
    const question = {
      Answer: '',
      ContactType: '',
      Length: 0,
      Mandatory: false,
      Options: [],
      OptionsAnswers: [],
      Question: '',
      Type: 1
    }
    this.formJson[levelIndex].Form.push(question);
  }

  removeQuestion(levelIndex: number, questionIndex: number) {
    this.formJson[levelIndex].Form.splice(questionIndex, 1);
  }

  addLevel() {
    const level = {
      ConditionalDiseases: [],
      Form: [],
      LevelComplete: false,
      LevelConditionalTitle: '',
      LevelIsConditional: false,
      LevelName: '',
      LevelNumber: 0
    };
    level.LevelNumber = this.formJson.length + 1;
    this.formJson.push(level);
  }

  updateForm() {

    let payload = {
      IdOrganization: this.formObject.IdOrganization,
      IdOrganizationalStructure: this.formObject.IdOrganizationalStructure,
      IdFormType: this.formObject.IdFormType,
      FormCode: this.formObject.FormCode,
      DiseasesForInvestigation: this.formObject.DiseasesForInvestigation,
      FormName: this.formObject.FormName,
      FormVersion: this.formObject.FormVersion + 1,
      FormJson: JSON.stringify(this.formJson),
      IdFormStatus: this.formObject.IdFormStatus,
      IdRecordStatus: this.formObject.IdRecordStatus,
      RecordStatus: this.formObject.RecordStatus,
    }


    this._forms.updateForm(this.formObject.Id, payload).subscribe((res) => {

      window.location.reload();
    }, err => console.log(err))
  }
}

