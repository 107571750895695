<div class="login-wrap">
    <main class="form-signin w-100 pt-5">
      <div class="d-flex justify-content-center">
        <img class="mb-0" src="/assets/img/logo.webp" alt="ESS brand image" width="172" >
      </div>
      <div class="mt-3">
        <h1 class="fs-5 mb-3 fw-normal text-center">Create a new password</h1>
      </div>
      <!-- CONFIRMATION MESSAGE -->
      <div class="mt-4" *ngIf="success">
        <h5 class="fw-bold text-success text-center">Your password has been reset</h5>
      </div>
      <form [formGroup]="form" *ngIf="!success">
        <div *ngIf="invalid" class="alert alert-danger mt-3" role="alert">
          Invalid email. <br>Please try again.
        </div>
        <div class="form-group mb-2">
          <div class="form-floating">
            <input  [class.is-invalid]="invalid || empty" formControlName="Password" type="password" class="form-control" id="passwordInput" placeholder="New password">
            <label for="emailInput">Password</label>
          </div>
          <label for="" *ngIf="empty" class="text-danger">Password must not be empty</label>
        </div>
        <div class="form-group mb-2">
          <div class="form-floating">
            <input [class.is-invalid]="invalid || dontMatch" type="password" class="form-control" id="passwordConfirmationInput" placeholder="Confirm new password">
            <label for="emailInput">Confirm Password</label>
          </div>
          <label for="" *ngIf="dontMatch" class="text-danger">Passwords don't match</label>
        </div>
    
        <button [class]="loading ? 'w-100 btn btn btn-primary disabled' : 'w-100 btn btn btn-primary'" (click)="submit()">
          <span *ngIf="!loading">Save new password</span>
          <span *ngIf="loading">
            <div class="spinner-border text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </span>
        </button>
       
      </form>
      <p class="mt-4 text-center"><a routerLink="/login">Back to sign in</a></p>
      <p class="mt-5 mb-3 text-muted text-center">© 2023</p>
    </main>
</div>