import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-geographical-structure',
  templateUrl: './geographical-structure.component.html',
  styleUrls: ['./geographical-structure.component.scss']
})
export class GeographicalStructureComponent implements OnInit {
  locationTypes: any[] = [];
  locationsList: any[] = [];
  orgId:any;
  orgStructureId: number = 0;
  confirmingUpdate: boolean = false;
  selectedTypePriorityNumber: number = 0;
  
  newLocationForm = new FormGroup({
    IdOrganization: new FormControl(0, [Validators.required]),
    Code: new FormControl('', [Validators.required, Validators.minLength(2)]),
    GeographicName: new FormControl('', [Validators.required, Validators.minLength(2)]),
    IdGeographicStructureType: new FormControl(0, Validators.required),
    IdSuperiorsCode: new FormControl(0, Validators.required),
    SuperiorsCode: new FormControl('', [Validators.required, Validators.minLength(2)])
  })

  editGeoForm = new FormGroup({
    IdOrganization: new FormControl(0, Validators.required),
    Code: new FormControl('', Validators.required),
    GeographicName: new FormControl('', Validators.required),
    IdGeographicStructureType: new FormControl(0, Validators.required),
    IdSuperiorsCode: new FormControl(0, Validators.required),
    SuperiorsCode: new FormControl('', Validators.required),
    IdRecordStatus: new FormControl(0, Validators.required)
  })

  objectInMemory: any = {
    IdOrganization: '',
    Code: 0,
    GeographicName: '',
    IdGeographicStructureType: 0,
    IdSuperiorsCode: 0,
    SuperiorsCode: '',
    IdRecordStatus: 0
  }
  constructor(private _location: LocationService, private _modal: ModalService) {}

  ngOnInit() {
    this.getStructure();
    this.fetchLocationsTypes();
    this.fetchAllLocations(this.orgId);
  }

  getStructure() {
    const structure = localStorage.getItem("structure");
    if(structure != null) {
      var deserialized = JSON.parse(structure)
      this.orgId = deserialized[0].IdOrganization;
      this.orgStructureId = deserialized[0].Id;
    }
  }

  fetchLocationsTypes() {
    this._location.getAllTypes().subscribe((res) => {
      // console.log(res);
      for(let i = 0; i < res.length ; i++) {
        this.locationTypes.push(res[i]);
      }
    })
  }

  fetchAllLocations(orgId:number) {
    this.locationsList = [];
    this._location.getAllLocations(orgId).subscribe((res) => {

      for(let i = 0; i < res.length ; i++) {
        this.locationsList.push(res[i]);
      }
    })
  }

  checkLocationType() {
    // console.log(this.selectedTypePriorityNumber);
  }

  superiorsCode:string = ''
  setSuperiorsCode() {
    for(let i = 0; i < this.locationsList.length ; i++) {
      if(this.locationsList[i].Id == this.newLocationForm.controls['IdSuperiorsCode'].value) {
        this.superiorsCode = this.locationsList[i].Code;
      }

    }
    // this.superiorsCode = code;
  }

  saveNewLocation() {
    
    this.setSuperiorsCode();
    this.newLocationForm.controls['IdOrganization'].setValue(this.orgId);
    this.newLocationForm.controls['SuperiorsCode'].setValue(this.superiorsCode);
    this.newLocationForm.controls['IdSuperiorsCode'].setValue(Number(this.newLocationForm.controls['IdSuperiorsCode'].value));
    this.newLocationForm.controls['IdGeographicStructureType'].setValue(Number(this.newLocationForm.controls['IdGeographicStructureType'].value));
    if(this.newLocationForm.invalid) {
      // console.log(this.newLocationForm.invalid);
      return;
    }
    this._location.saveNewLocation(this.newLocationForm.value).subscribe((res) => {
      if(res) {
        // console.log(res)
        this._modal.hideModal();
        this.newLocationForm.reset();
        this.superiorsCode = '';
        this.fetchAllLocations(this.orgId);
      }
    }, (e) => {
      console.log(e);
    })
    // console.log(this.newLocationForm.value);
    
  }

  editModal(obj: any) {
    this.confirmingUpdate = false;

    this.editGeoForm.controls['IdOrganization'].setValue(obj.IdOrganization);
    this.editGeoForm.controls['Code'].setValue(obj.Code);
    this.editGeoForm.controls['GeographicName'].setValue(obj.GeographicName);
    this.editGeoForm.controls['IdGeographicStructureType'].setValue(obj.IdGeographicStructureType);
    this.editGeoForm.controls['IdSuperiorsCode'].setValue(obj.IdSuperiorsCode);
    this.editGeoForm.controls['SuperiorsCode'].setValue(obj.SuperiorsCode);
    this.editGeoForm.controls['IdRecordStatus'].setValue(obj.IdRecordStatus);
    this.objectInMemory = obj;  
  }
  
  showSaveConfirmation() {
    this.confirmingUpdate = true;

  }

  confirmUpdate() {

    this._location.updateLocation(this.objectInMemory.Id, this.editGeoForm.value).subscribe((res) => {

      if(res) {
        this._modal.hideModal();
        this.fetchAllLocations(this.orgId);
        this.editGeoForm.reset();
        this.objectInMemory = {};
        this.confirmingUpdate = false;
      }
    }, err => console.log(err))

  }

  locationDeleteId: number = 0;
  deleteModal(id: number) {
    this.locationDeleteId = id;
  }

  confirmDelete() {
    this._location.deleteLocation(this.locationDeleteId).subscribe((res) => {
      if(res) {
        this._modal.hideModal();
        this.fetchAllLocations(this.orgId);
        this.editGeoForm.reset();
        this.objectInMemory = {};
        this.confirmingUpdate = false;
      }
    }, err => console.log(err))
  }
}