import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor() { }
  hideModal() {
    let closeBtnList = document.getElementsByClassName('btn-close');
    for(let i = 0 ; i < closeBtnList.length ; i++) {
      let btn = (closeBtnList[i] as HTMLLinkElement);
      btn.click();
    }
  }
}
