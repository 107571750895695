import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordService } from 'src/app/services/password/password.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit{

  invalid: boolean = false;
  loading: boolean = false;
  linkSent: boolean = false;
  empty: boolean = false;
  constructor(private _password: PasswordService) {}
  ngOnInit(): void {
    
  }

  form = new FormGroup({
    NationalID: new FormControl('', Validators.required)
  })

  submit() {
    this.loading = true;
    this.empty = false;
    const input = this.form.controls['NationalID'].value;
    if(input?.trim() == '') {
      this.loading = false;
      this.empty = true;
      return;
    }

    this._password.requestPasswordReset(this.form.value).subscribe((res: any) => {

      this.loading = false;
      if(res.Status == 404) {
        this.invalid = true;
      } else if(res.Status == 200) {
        this.linkSent = true;
      }
    }, (err: any) => {console.log(err)})
  }
}
