import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators'
// import { LoadingService } from '../Tools/loading/loading.service';
import {AuthService } from '../services/auth.service';
// import { NotificacionesService, tipoNotificacion } from '../Tools/notificaciones-service/notificaciones-service.service';

@Injectable()
export class LoadingHttpRequestInterceptor implements HttpInterceptor {

  constructor(
    // private _loading: LoadingService,
    private _auth:AuthService,
    // private notificacion: NotificacionesService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    // this._loading.setServerConnectionError(false);
    // this._loading.setLoading(true, request.url);
    return next.handle(request)
      .pipe(
        catchError((err: any) => {
        // ERROR EN REQUEST
        if (err.status == 0 || err.statusText == 'Internal Server Error') {
        //   this._loading.setServerConnectionError(true);
          return throwError('Error');
        }
        // SE VERIFICA SI LA SESION ACTUAL EXPIRO
        if(!this.validarSesionExpirada(err)) return throwError(err);

        // SE VERIFICA SI EL TOKEN ES CORRECTO
        if(!this.validarTokenIncorrecto(err)) return throwError(err);

        // SE VERIFICA SI EXISTE UN TOKEN
        if(!this.validarTokenVacio(err)) return throwError(err);

        // ERRORES DE VALIDACION
        this.erroresValidacionAPI(err);

        // this._loading.setLoading(false, request.url);

        return throwError(err)
      }))
      .pipe(
        finalize(() => {
          setTimeout(() => {
            // this._loading.setLoading(false, request.url);
          }, 1100);
        })
      );
  }

validarSesionExpirada(err: any)
{
  const { error, code } = err?.error;
  const codeStatus = err?.status;
  if (codeStatus === 401 && code == 'DLQ81' )
  {
    // this.notificacion.mostrarNotificacion(tipoNotificacion.ERROR, 'SESION EXPIRADA', 5000, false);
    this._auth.logOut();
    return false;
  }

  else {
    return true
  };
}

validarTokenIncorrecto(err: any)
{
  const { error, code } = err.error;
  const codeStatus = err?.status;
  if (codeStatus === 401 && code == 'XYT22' )
  {
    this._auth.logOut();
    return false;
  }
  else return true;
}

validarTokenVacio(err: any)
{
  const { error, code } = err.error;
  const codeStatus = err?.status;
  if (codeStatus === 401 && code == 'QUF49' )
  {
    // this.notificacion.mostrarNotificacion(tipoNotificacion.ERROR, 'ERROR DE AUTENTICACION', 5000, false);
    this._auth.logOut();
    return false;
  }
  else return true;
}

erroresValidacionAPI(err: any)
{
  if (err?.status == 400)
  {
    // SE VERIFICA QUE EXISTA UN ARRAY DE ERRORES RELACIONADOS A VALIDACIONES
    if (err?.error?.errorsValidation)
    {
      err.isValidationErrors = true;
      return err?.error?.errorsValidation
    }
    else return err;
  }
  else return err;
}

}