import { AfterViewInit, Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { OrganizationService } from 'src/app/services/organization.service';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { StructureService } from 'src/app/services/structure/structure.service';

@Component({
  selector: 'app-organizational-structure',
  templateUrl: './organizational-structure.component.html',
  styleUrls: ['./organizational-structure.component.scss'],
})
export class OrganizationalStructureComponent implements OnInit {
  viewAsTree = false;
  orgId: any;
  users: any = [];
  organizations: any = [];
  structureLevels: any[] = [];
  idLevelSuperior: number = 0;
  objInMemory: any = {};
  loading = true;

  constructor(
    private _organization: OrganizationService,
    private _common: CommonService,
    private _structure: StructureService
  ) {}

  ngOnInit() {
    this.getStructure();
    this.getOrganizations(this.orgId);
    this.getStructureLevels();
  }

  getStructure() {
    const structure = localStorage.getItem('structure');
    if (structure != null) {
      var deserialized = JSON.parse(structure);
      this.orgId = deserialized[0].IdOrganization;
    }
  }

  getStructureLevels() {
    this._organization.getAllLevels(this.orgId).subscribe(
      (res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            this.structureLevels.push(res[i]);
          }
        }
      },
      (e) => {
        console.log(e);
      }
    );
  }
  tree: any = [];
  hierarchy: any = [];
  getOrganizations(orgId: number) {
    this.organizations = [];
    this._organization.getAll(orgId).subscribe((res) => {
      if (res) {
        for (let i = 0; i < res.length; i++) {
          this.organizations.push(res[i]);
        }

        this._structure
          .transformToHierarchy(this.organizations)
          .then((res: any) => {
            this.hierarchy = res;
          })
          .then(() => {
            this.generateTree(this.hierarchy);
          });

        //will probably delete all lines below
        // this.createOrganizationTree(this.hierarchy).then((tree) => {
        //   this.tree = tree;
        //   this.generateTree(tree);
        //   // this.generateTable(this.chartData);
        // });
        this.loading = false;
      }
    });
  }

  generateTable(dataTree: any) {
    let parent = document.getElementById('table-wrap') as HTMLElement;
    //add header row
    let html = `<div class="row mt-3">
                  <div class="col-12">
                    <div class="row fw-bold org-row">
                      <div class="col">Name</div>
                      <div class="col-2">ID</div>
                      <div class="col-2">Reports To</div>
                      <div class="col-2">Actions</div>
                    </div>
                  </div>
                `;
    //add main row (main org)
    html += `<div class="col-12">
                <div class="row org-row">
                  <div class="col">${dataTree.name}</div>
                  <div class="col-2">${dataTree.Id}</div>
                  <div class="col-2">${dataTree.IdSuperiorsCode}</div>
                  <div class="col-2">
                    <div class="dropdown ms-2">
                          <button class="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="ri-more-2-fill"></i>
                          </button>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item edit-org-btn" id="${dataTree.Id}" data-bs-toggle="modal" data-bs-target="#editmodal"><i class="ri-pencil-fill"></i> Edit</a></li>
                            <li><a class="dropdown-item text-danger delete-org-btn" id="del-${dataTree.Id}" data-bs-toggle="modal" data-bs-target="#deleteModal"><i class="ri-delete-bin-2-fill"></i> Delete</a></li>
                          </ul>
                      </div>
                  </div>
                </div>
              </div>`;
    //add children rows
    dataTree.children.forEach((child: any) => {
      html += this.generateChildrenRow(child, 2);
    });
    //add closing tag to main "row" div
    html += '</div>';
    parent.innerHTML = html;
    // this.assignEditEvent();
    // this.assignDeleteEvent();
  }

  generateChildrenRow(org: any, level: number | null = null) {
    let row = `<div class="col-12">
                <div class="row org-row">
                  <div class="col">
                    <span class="sub-${level}">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5z"/>
                      </svg>
                    </span>
                    <span class="ms-2">${org.name}</span>
                  </div>
                  <div class="col-2">${org.Id}</div>
                  <div class="col-2">${org.IdSuperiorsCode}</div>
                  <div class="col-2">
                    <div class="dropdown ms-2">
                          <button class="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="ri-more-2-fill"></i>
                          </button>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item edit-org-btn" id="${org.Id}" data-bs-toggle="modal" data-bs-target="#editmodal"><i class="ri-pencil-fill"></i> Edit</a></li>
                            <li><a class="dropdown-item text-danger delete-org-btn" id="del-${org.Id}" data-bs-toggle="modal" data-bs-target="#deleteModal"><i class="ri-delete-bin-2-fill"></i> Delete</a></li>
                          </ul>
                      </div>
                  </div>
                </div>
              </div>`;
    if (org.children.length > 0) {
      org.children.forEach((child: any) => {
        row += this.generateChildrenRow(child, 3);
      });
    }
    return row;
  }

  assignEditEvent() {
    let btns = document.getElementsByClassName(
      'edit-org-btn'
    ) as HTMLCollectionOf<HTMLElement>;

    for (let i = 0; i < btns.length; i++) {
      const id = btns[i].id;
      const btn = document.getElementById(id) as HTMLElement;

      btn.addEventListener('click', () => {
        // window.alert('do something');
        this.editModal(id);
      });
    }
  }

  assignDeleteEvent() {
    let btns = document.getElementsByClassName(
      'delete-org-btn'
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < btns.length; i++) {
      const id = btns[i].id;
      const btn = document.getElementById(id) as HTMLElement;

      btn.addEventListener('click', () => {
        this.deleteModal(id);
      });
    }
  }
  chartData: any = {
    name: '',
    children: [],
    Id: 0,
  };
  generateTree(data: any) {
    //push roots to tree
    data.forEach((institution: any) => {
      this.chartData.name = institution.AreaName;
      this.chartData.children.push(
        ...this.generateTreeChildren(institution.children)
      );
      this.chartData.Id = institution.Id;
      this.chartData.IdSuperiorsCode = institution.IdSuperiorsCode;
    });

    this.data = this.chartData;
  }

  generateTreeChildren(children: any) {
    let arrOfChildren: any = [];
    children.forEach((child: any) => {
      let childSchema = {
        name: child.AreaName,
        children:
          child.children.length > 0
            ? this.generateTreeChildren(child.children)
            : [],
        Id: child.Id,
        IdSuperiorsCode: child.IdSuperiorsCode,
      };
      arrOfChildren.push(childSchema);
    });
    return arrOfChildren;
  }

  async createOrganizationTree(institutions: Array<any>) {
    const institutionMap: any = {};
    const roots: any = [];
    //hardcoded 'main' institution code
    const main = institutions.filter((institution) => institution.Id === 3);
    roots.push(main[0]);

    // Create a map of institutions using their Id as the key
    institutions.forEach((institution) => {
      institution.children = [];
      institutionMap[institution.Id] = institution;
    });

    // Iterate over the institutions to build the parent-child relationships
    institutions.forEach((institution) => {
      const parentId = institution.IdSuperiorsCode;
      if (parentId === institution.Id) {
        // If the institution is its own parent, it is a root institution
        roots.push(institution);
      } else {
        const parentInstitution = institutionMap[parentId];
        if (parentInstitution) {
          parentInstitution.children.push(institution);
        }
      }
    });
    return roots;
  }

  generateTableRow(institution: any) {
    let html = `<tr><td>${institution.Id}</td><td>${institution.AreaName}</td><td>`;
    if (institution.children.length > 0) {
      html += '<table class="table">\n<tbody>\n';
      institution.children.forEach((child: any) => {
        html += this.generateTableRow(child);
      });
      html += '</tbody>\n</table>';
    }
    html += '</td></tr>\n';
    return html;
  }

  toggleView() {
    this.viewAsTree = !this.viewAsTree;
  }

  addStructureForm = new UntypedFormGroup({
    IdOrganization: new UntypedFormControl(0),
    Code: new UntypedFormControl('3546'),
    AreaName: new UntypedFormControl('', Validators.required),
    IdOrganizationalStructuresLevel: new UntypedFormControl(),
    IdSuperiorsCode: new UntypedFormControl(0, Validators.required),
    SuperiorsCode: new UntypedFormControl(),
  });

  savingNewStructure: boolean = false;
  noSuperiorsCode: boolean = false;
  noAreaName: boolean = false;
  addNewStructure() {
    //validate a superior's code has been selected
    const supCode = this.addStructureForm.controls['SuperiorsCode'].value;
    if (supCode == '' || supCode == null) {
      this.noSuperiorsCode = true;
      return;
    }
    //validate an area name has been assigned
    const areaName = this.addStructureForm.controls['AreaName'].value;
    if (areaName.trim() == '' || areaName == null) {
      this.noAreaName = true;
      return;
    }

    this.savingNewStructure = true;
    let supId = this.findSuperiorId(
      this.organizations,
      this.addStructureForm.controls['SuperiorsCode'].value
    );

    this.addStructureForm.controls['IdOrganization'].setValue(this.orgId);
    this.addStructureForm.controls['IdSuperiorsCode'].setValue(supId);
    this.addStructureForm.controls['IdOrganizationalStructuresLevel'].setValue(
      this.idLevelSuperior
    );
    this._organization.addNewStructure(this.addStructureForm.value).subscribe(
      (res) => {
        window.location.reload();
      },
      (e) => {
        this.savingNewStructure = false;
        console.log(e);
      }
    );
  }

  findSuperiorId(arr: any, code: any) {
    var superiorId;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].Id == code) {
        superiorId = arr[i].Id;
        this.idLevelSuperior = arr[i].IdOrganizationalStructuresLevel;
        return superiorId;
      }
    }
  }

  data = {
    name: 'Direction 1',
    children: [
      {
        name: 'child 1',
      },
      {
        name: 'child 2',
      },
    ],
  };
  option: EChartsOption = {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
    },
    series: [
      {
        type: 'tree',
        id: 0,
        name: 'tree1',
        // data: [this.chartData],
        data: [this.chartData],
        top: '10%',
        left: '15%',
        bottom: '22%',
        right: '20%',
        symbolSize: 7,
        edgeShape: 'polyline',
        edgeForkPosition: '10%',
        initialTreeDepth: 3,
        lineStyle: {
          width: 2,
        },
        label: {
          backgroundColor: '#fff',
          position: 'left',
          verticalAlign: 'middle',
          align: 'right',
        },
        leaves: {
          label: {
            position: 'right',
            verticalAlign: 'middle',
            align: 'left',
          },
        },
        emphasis: {
          focus: 'descendant',
        },
        expandAndCollapse: true,
        animationDuration: 550,
        animationDurationUpdate: 750,
      },
    ],
  };

  deletingStructure = false;
  confirmDelete() {
    this.deletingStructure = true;
    this._organization.deleteStructure(this.idForDelete).subscribe(
      (res) => {
        this.deletingStructure = false;
        this._common.hideModal();
        this._common.customAlert('Structure deleted successfully');
        window.location.reload();
      },
      (err) => console.log(err)
    );
  }

  changedSuperiorId: boolean = false;
  handleSuperiorChange(event: Event) {
    this.changedSuperiorId = true;
  }

  public editModal(id: any) {
    const result = this.organizations.filter((org: any) => org.Id == id);
    this.objInMemory = result[0];

    this.editForm.controls['AreaName'].setValue(this.objInMemory.AreaName);
  }

  idForDelete: number | null = null;
  deleteModal(id: any) {
    this.idForDelete = id;
  }

  editForm = new FormGroup({
    AreaName: new FormControl('', Validators.required),
    IdSuperiorsCode: new FormControl(0, Validators.required),
    SuperiorsCode: new FormControl('', Validators.required),
  });

  confirmEdit() {
    if (this.changedSuperiorId) {
      //convert IdSuperiorsCode to number
      this.editForm.controls['IdSuperiorsCode'].setValue(
        Number(this.editForm.controls['IdSuperiorsCode'].value)
      );

      //Using its ID, find the superior org which was selected by user
      const newSuperiorOrg = this.organizations.find(
        (org: any) => org.Id === this.editForm.controls['IdSuperiorsCode'].value
      );

      //assign the superior's code to the form
      this.editForm.controls['SuperiorsCode'].setValue(newSuperiorOrg.Code);
    } else {
      //assign the default superior's code to the form
      this.editForm.controls['SuperiorsCode'].setValue(
        this.objInMemory.SuperiorsCode
      );
      this.editForm.controls['IdSuperiorsCode'].setValue(
        this.objInMemory.IdSuperiorsCode
      );
    }

    this._organization
      .updateStructure(this.objInMemory.Id + '', this.editForm.value)
      .subscribe(
        (res) => {
          this._common.hideModal();
          window.location.reload();
        },
        (e) => {
          console.log('error', e);
        }
      );
  }
}
