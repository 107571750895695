import { Component } from '@angular/core';

@Component({
  selector: 'app-forms-settings',
  templateUrl: './forms-settings.component.html',
  styleUrls: ['./forms-settings.component.scss']
})
export class FormsSettingsComponent {

}
