import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {

  default = 'Asiri Hospital';
  username: string | null = '';
  userStructure: any = [];
  permissionLevel = 0;
  constructor(private auth: AuthService) { }

  changeOrg(org:string) {
    this.default = org;
  }
  showNav = true;

  ngOnInit() {
    this.toggleNav();
    this.getUserInfo();
    this.permissionLevel = this.auth.getPermissionLevel();
  }

  toggleNav() {
    if(window.location.pathname == '/login') {
      this.showNav = false;
    } else {
      this.showNav = true;
    }
  }

  logout() {
    this.auth.logOut()
    window.location.reload();
  }

  async getUserInfo() {
    this.username = localStorage.getItem("username");
    var struct = localStorage.getItem("structure");
    if(struct != null) {
      var arr = JSON.parse(struct)
      for(let i = 0 ; i < arr.length ; i++) {
        this.userStructure.push(arr[i]);
      }
      this.default = this.userStructure[0].AreaName;
    }
  }
}
