import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  //this is a service that takes an array and a search term and returns the filtered array
  filterByCriteria(arr:any[], searchTerm:string) {
    let filteredArr = arr.filter( (e:any) => {
      return e.PatientName.toLowerCase().includes(searchTerm.toLowerCase()) || e.AssignedUserName.toLowerCase().includes(searchTerm.toLowerCase()) || e.IdCardNumber.toLowerCase().includes(searchTerm.toLowerCase());
    })
    return filteredArr;
  }

  scrollToTop() {
    const ref = document.getElementById('scroll-ref') as HTMLElement;
    ref.scrollIntoView();
  }

  customAlert(message: string) { 
    let alert = document.getElementById('popup-alert') as HTMLElement;
    alert.innerHTML = message;
    alert.classList.add('show');
    setTimeout(() => {
      alert.classList.remove('show');
    }, 5000);
  }

  hideModal() {
    let closeBtnList = document.getElementsByClassName('btn-close');
    for(let i = 0 ; i < closeBtnList.length ; i++) {
      let btn = (closeBtnList[i] as HTMLLinkElement);
      btn.click();
    }
  }

}
