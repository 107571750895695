import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  showNav = true;
  rotate = false;
  delay = 300;
  permissionLevel = 0;
  constructor( private _auth: AuthService) { }

  ngOnInit(): void {
    this.checkSidebarCookie();
    this.permissionLevel = this._auth.getPermissionLevel();
  }

  checkSidebarCookie() {
    const sidebarCookie = localStorage.getItem("sidebarPreference");
    if(sidebarCookie == null) {
      this.showNav = true;
      this.rotate = false;
    } else {
      this.showNav = false;
      this.rotate = true;
      var sidebar = (document.getElementById('sidebar') as HTMLElement);
      sidebar.style.width = 101 + 'px'
    }
  }
  toggleSideNav() {
    // this.showNav = !this.showNav;
    if(this.showNav) {
      this.showNav = false;
      this.rotate = true;
      var sidebar = (document.getElementById('sidebar') as HTMLElement);
      for(let w = 277 ; w > 102 ; w--) {
        setTimeout(() => {
          //console.log(w)
          sidebar.style.width = w + 'px'
        },this.delay)
      }
      localStorage.setItem("sidebarPreference", "collapsed");
    } else {
      this.rotate = false;
      var sidebar = (document.getElementById('sidebar') as HTMLElement);
      for(let w = 101 ; w < 278 ; w++) {
        setTimeout(() => {
          //console.log(w)
          sidebar.style.width = w + 'px'
        },this.delay)
      }
      setTimeout(() => {
        this.showNav = true;
      },this.delay)
      localStorage.removeItem("sidebarPreference");
    }
    
  }
}
