import { Component } from '@angular/core';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent {

  step = 2;

  goToStep(stepNumber:number) {
    this.step = stepNumber;
  }
}
