import { Component } from '@angular/core';

@Component({
  selector: 'app-chevron-right',
  templateUrl: './chevron-right.component.html',
  styleUrls: ['./chevron-right.component.scss']
})
export class ChevronRightComponent {

}
