import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CasesService } from 'src/app/services/cases.service';
import { ModalService } from 'src/app/services/modal.service';
import { PatientsService } from 'src/app/services/patients.service';
import { UsersService } from 'src/app/services/users.service';
import { PatientComponent } from '../patient/patient.component';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-my-cases',
  templateUrl: './my-cases.component.html',
  styleUrls: ['./my-cases.component.scss']
})
export class MyCasesComponent implements OnInit {
  orgId:any;
  orgStructureId: number = 0;
  caseId = null;
  myId = 0;
  username: any = '';
  listOfPatients: any[] = [];
  listOfUsers: any[] = [];
  listOfCases: any[] = [];
  listOfClosedCases: any[] = [];
  listOfNewCases: any[] = [];
  originalListOfCases: any= {};
  loading = true;
  view = 0;   //'0' para mostrar nuevos casos y '1' para mostrar casos en curso
  activeIndex = 0;
  userTransferSelected: boolean = false;
  p: number = 0; //page number 
  constructor(
    private _cases: CasesService,
    private _patients: PatientsService,
    private _users: UsersService,
    private _modal: ModalService,
    private _common: CommonService
    ) {}

  transferForm = new FormGroup({
    IdRegistryEpidemiologicalCases: new FormControl(),
    IdAssignedUser: new FormControl(),
  })

  ngOnInit(): void {
    this.getStructure();
    this.getMyCases();
    this.getPatients();
    this.getUsers();
    this.username = localStorage.getItem("username");
  }

  changeView(num: number) {
    this.view = num;
  }
  originalListOfUsers: any[] = [];
  getUsers() {
    this._users.getAll(this.orgId).subscribe((res) => {

      this.listOfUsers = res;
      this.originalListOfUsers = res;
    }, (e) => {
      console.log(e);
    })
  }

  getStructure() {
    const structure = localStorage.getItem("structure");
    if(structure != null) {
      var deserialized = JSON.parse(structure);
      this.orgId = deserialized[0].IdOrganization;
      this.orgStructureId = deserialized[0].Id;
    }
  }

  clearArrays() {
    this.listOfCases = [];
    this.listOfNewCases = [];
    this.listOfClosedCases = [];
  }
  getMyCases() {
    this.clearArrays();

    this._cases.getMyCases().subscribe((res) => {
      const { abiertos, pedientes } = res;
      this.originalListOfCases = res;
      this.listOfNewCases = pedientes;
      this.listOfCases = abiertos.filter((e:any) => e.IdRecordStatus == 1);
      this.listOfClosedCases = abiertos.filter((e:any) => e.IdRecordStatus == 5)

      if(this.listOfNewCases.length == 0) {
        this.changeView(1)
      }
      this.loading = false;
    },(e) => {
      console.log(e);
    })
  }

  getPatients() {
    this._patients.getAllPatients().subscribe((res) => {
      for(let i = 0 ; i < res.length ; i++) {
        this.listOfPatients.push(res[i])
      }
    },(e) => {
      console.log(e);
    }

    )
  }

  whenOpenTransferModal(caseId:number) {
    this.transferForm.controls['IdRegistryEpidemiologicalCases'].setValue(caseId);
  }

  
  selectTransferUser(userId:number, index:number) {
    this.userTransferSelected = true;
    //clicked item
    var itemToActivate = document.getElementById('list-item-' + index) as HTMLElement;

    this.activeIndex = index;
    this.transferForm.controls['IdAssignedUser'].setValue(userId);
    //all li items
    let listItems = document.getElementsByClassName('list-group-item');
    //remove the active class from all li elements
    for(let i = 0 ; i < listItems.length ; i++) {
      let item = (listItems[i]) as HTMLElement;
      item.classList.remove('active');
    }
    itemToActivate.classList.add('active');
  }
  
  transferCase() {
    this._cases.transfer(this.transferForm.value).subscribe((res) => {
      this.getMyCases();
      this._modal.hideModal();
    }, (e) => {
      console.log(e);
    })
  }


  viewCase(caseId: number) {
    this._cases.markAsRead(caseId).subscribe((res) => {

      window.location.href ='href="/#/search/patient?id=' + caseId;
    }, (e) => {
      console.log(e);
    })
  }

  filter(event: any) {
    const value = event.target.value;
    const source = this.originalListOfCases;
    const tempListOfNewCases = this._common.filterByCriteria(source?.pedientes, value);
    const tempListOfCases = this._common.filterByCriteria(source?.abiertos, value).filter((e:any) => e.IdRecordStatus == 1);
    const tempListOfClosedCases = this._common.filterByCriteria(source?.abiertos, value).filter((e:any) => e.IdRecordStatus == 5);
    this.listOfNewCases = tempListOfNewCases
    this.listOfCases = tempListOfCases.filter((e:any) => e.IdRecordStatus == 1);
    this.listOfClosedCases = tempListOfClosedCases.filter((e:any) => e.IdRecordStatus == 5);
  }

  filterByUser(event: any) {
    const param = event.target.value;
    const source = this.originalListOfCases;
    this.listOfUsers = this.originalListOfUsers.filter((e:any) => e.Names.toLowerCase().includes(param.toLowerCase()) || e.Surnames.toLowerCase().includes(param.toLowerCase()) || e.IdCardNumber.toLowerCase().includes(param.toLowerCase()));
  }

}
