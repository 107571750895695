<div class="pt-0">
    <h5 class="mb-0 py-2 fw-bold">Register a new user</h5>
    <div class="card rounded-4 shadow-sm">
        <div class="card-header">
        </div>
        <div class="card-body">
            <div>
                <div class="row g-3 mb-3">
                    <div class="col-8 offset-2">
                        <div class="card border overflow-hidden">
                            <div class="card-header bg-light border-bottom d-flex justify-content-between">
                                
                                <label [class]="step == 2 ? 'text-primary fw-medium' : 'text-secondary'">Credentials</label>
                                <label [class]="step == 3 ? 'text-primary fw-medium' : 'text-secondary'">Confirm</label>
                            </div>
                            <!-- STEP 1-->
                            

                            <!-- STEP 2-->
                            <div class="card-body bg-light" *ngIf="step == 2">
                                <div class="row mb-2">
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="">First Name</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="form-group">
                                            <label for="">Last Name</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="">Email</label>
                                            <input type="email" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 mb-2">
                                        <div class="form-group">
                                            <label for="">Password</label>
                                            <input type="password" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="">Re-type Password</label>
                                            <input type="password" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- STEP 3 -->
                            <div class="card-body bg-light" *ngIf="step == 3">
                                <div class="d-flex">
                                    <div class="round-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="darkgray" class="bi bi-person-fill" viewBox="0 0 16 16">
                                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
                                        </svg>
                                    </div>
                                    <div class="ms-4">
                                        <label for="" class="text-secondary">Full Name</label>
                                        <h6 class="fw-bold mb-1">Derek Miller</h6>
                                    </div>
                                    <div class="ms-4">
                                        <label for="" class="text-secondary">Credentials</label>
                                        <h6 class="fw-bold mb-1">derek@mail.com</h6>
                                        <h6 class="fw-bold mb-1">***********</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer bg-light border-top d-flex justify-content-end" *ngIf="step == 1">
                                <button class="btn btn-sm btn-primary px-3" (click)="goToStep(2)">
                                    Credentials
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                      </svg>
                                </button>
                            </div>

                            <div class="card-footer bg-light border-top d-flex justify-content-end" *ngIf="step == 2">
                                
                                <button class="btn btn-sm btn-primary px-3" (click)="goToStep(3)">
                                    Confirm
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                      </svg>
                                </button>
                            </div>

                            <div class="card-footer bg-light border-top d-flex justify-content-between" *ngIf="step == 3">
                                <button class="btn btn-sm btn-secondary" (click)="goToStep(2)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                                      </svg>
                                    Credentials

                                </button>
                                <button class="btn btn-sm btn-primary px-3" >
                                    Save User
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
    </div>
</div>