import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private http: HttpClient) { }

  addNew(body:any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/Forms`, body);
  }

  getAll(id:number): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/Forms?idOrganization=${id}`);
  }

  deleteForm(id:number): Observable<any> {
    return this.http.delete<any>(`${environment.apiURL}/Forms/${id}`);
  }

  getDataTypes(): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/DataTypes`);
  }

  updateForm(id:number, payload:any): Observable<any> {
    return this.http.put<any>(`${environment.apiURL}/Forms/${id}`, payload);
  }
}
