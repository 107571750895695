<div class="pt-0">
  <div class="card rounded-4 shadow-sm">
    <div class="card-header">
      <h5 class="mb-0 py-2 fw-bold">Search Records</h5>
      <p class="text-secondary">
        Find patient's records using the patient's name
      </p>
      <!-- <h6 class="mb-0 py-2">Search</h6> -->
    </div>
    <div class="card-body">
      <form action="">
        <div class="row g-3 mb-3">
          <div class="col-12">
            <div class="form-group">
              <div class="input-group">
                <input
                  placeholder="Filter by patient name"
                  (keyup)="filter($event)"
                  [disabled]="loading"
                  type="text"
                  class="form-control"
                />
                <!-- <button [disabled]="loading" class="btn btn-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                      </svg>
                                </button> -->
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 placeholder-glow" *ngIf="loading">
            <span class="placeholder col-2"></span>
            <br />
            <table class="table">
              <tbody>
                <tr>
                  <td><span class="placeholder col-10"></span></td>
                  <td><span class="placeholder col-7"></span></td>
                  <td><span class="placeholder col-4"></span></td>
                  <td><span class="placeholder col-8"></span></td>
                  <td><span class="placeholder col-4"></span></td>
                  <td><span class="placeholder col-3"></span></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12" *ngIf="!loading">
            <h6 class="text-muted">Showing {{ casesList.length }} results</h6>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>National ID</th>
                    <th>Registration Date</th>
                    <th>Registered By</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let c of casesList
                        | paginate : { itemsPerPage: 6, currentPage: p }
                    "
                  >
                    <td
                      *ngFor="let p of patientsList"
                      [class]="p.Id != c.IdPatient ? 'd-none' : ''"
                    >
                      <span *ngIf="p.Id == c.IdPatient"
                        >{{ p.Names }} {{ p.Surnames }}</span
                      >
                    </td>
                    <td
                      *ngFor="let p of patientsList"
                      [class]="p.Id != c.IdPatient ? 'd-none' : ''"
                    >
                      <span *ngIf="p.Id == c.IdPatient">{{
                        p.IdCardNumber
                      }}</span>
                    </td>
                    <td>{{ c.DateCreated }}</td>
                    <td>
                      <span
                        *ngFor="let u of usersList"
                        [class]="u.Id != c.IdUserCreated ? 'd-none' : ''"
                        >{{ u.Names }} {{ u.Surnames }}</span
                      >
                    </td>
                    <td>
                      <app-pill
                        *ngIf="c.IdRecordStatus == '1'"
                        [state]="'open'"
                        [text]="'Open'"
                      ></app-pill>
                      <app-pill
                        *ngIf="c.IdRecordStatus == '5'"
                        [state]="'closed'"
                        [text]="'Closed'"
                      ></app-pill>
                    </td>
                    <td>
                      <a
                        class="btn btn-sm btn-primary"
                        href="/#/search/patient?id={{ c.Id }}"
                        >View <i class="ri-arrow-right-s-line"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-center">
        <pagination-controls
          class="custom-pagination"
          (pageChange)="p = $event"
          (pageBoundsCorrection)="p = $event"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>
