<h5 class="">Password and Access</h5>
<div *ngIf="!loading">
    <!-- <div class="mt-4">
        <h6 class="fw-bold">Email Address</h6>
        <p>Your email is <strong>{{thisUserData.Email}}</strong></p>
    </div>
    <hr> -->
    <div class="mt-4">
        <h6 class="fw-bold">Password</h6>
        <p>You will receive a password reset link in your inbox.</p>
        <div class="password-row">
            <div class="row g-3">

                <div class="col-12" *ngIf="!showPasswordResetForm">
                    <button class="btn btn-primary" (click)="showPasswordReset()">Reset my Password</button>
                </div>
                
                <div class="col-12" *ngIf="showPasswordResetForm">
                    <div class="row g-2" *ngIf="statusOK">
                        <div class="col">
                            <p class="text-success">Password reset link sent.</p>
                        </div>
                    </div>
                    <div class="row g-2" *ngIf="!statusOK">
                        <div class="col">
                            <form [formGroup]="form" action="">
                                <input (keyup)="resetValidations()" [class.is-invalid]="inputIsEmpty || invalidNationalId" formControlName="NationalID" type="text" class="form-control" placeholder="Please confirm your National ID">
                            </form>
                        </div>
                        <div class="col-auto">
                            <button [disabled]="loadingResponse" class="btn btn-primary" (click)="requestPasswordReset()">
                                <span *ngIf="loadingResponse" class="d-flex align-items-center">
                                    <div class="spinner-border spinner-border-sm text-light" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <span class="ms-2">Loading...</span>
                                </span>
                                <span *ngIf="!loadingResponse">
                                    Confirm Password Reset
                                </span>
                            </button>
                        </div>
                    </div>
                    <p class="text-danger" *ngIf="invalidNationalId">Invalid National ID</p>
                </div>
            </div>
        </div>
    </div>
</div>

