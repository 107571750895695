import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss']
})
export class PillComponent {
  @Input() state: string = '';
  @Input() text: string = 'text';
  @Input() showIcon: boolean = true;
}
