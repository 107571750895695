import { Pipe, PipeTransform } from '@angular/core';
import { DiseasesService } from '../services/diseases.service';

@Pipe({
  name: 'diseaseName'
})
export class DiseaseNamePipe implements PipeTransform {
  
  constructor(private _diseases: DiseasesService) {}
  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

}
