import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss'],
})
export class StructureComponent {
  @Input() children: any[] = [];
  @Input() handleEditClick!: (id: any) => void;
  @Input() organizations: any[] = [];
  handleClickForEdit(event: any) {
    console.log(event.target.id);
    this.handleEditClick(Number(event.target.id));
  }
}
