import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { DiseasesService } from 'src/app/services/diseases.service';
import { FormsService } from 'src/app/services/forms.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  orgId:any;
  orgStructureId: number = 0;
  userId:any;
  users: any = [];
  organizations: any = [];
  selectedDiseaseId: number = 0;
  diseasesList: any[] = [];
  invalidForm = false;
  formSent = false;
  formSuccess = false;
  showQuestionField = false;
  newFormTitle = '';
  listOfForms: any[] = [];
  dataTypes: any[] = [];
  loading = true;
  //navigation
  //opciones
  //0 = /forms
  //1 = create new form
  option:number = 0;
  changeView(num:number) {
    this.option = num;
  }

  resultsPerPage: number = 5;
  currentPage: number = 1;

  //preview form modal fields
  questionsOnModal: any [] = [];

  constructor(
    private _diseases: DiseasesService, 
    private _forms: FormsService,
    private _common: CommonService
    ) {}

  ngOnInit() {
    this.getStructure();
    this.getAllDiseases(this.orgId);
    this.getDataTypes();
    this.getAllForms(this.orgId);
  }
  ngOnViewInit() {
    this.getAllForms(this.orgId);
  }
  getDataTypes() {
    this._forms.getDataTypes().subscribe((res) => {
      if(res) {
        // console.log(res);
        for(let i = 0 ; i < res.length ; i++) {
        this.dataTypes.push(res[i])
        }
      }
    }, (e) => {
      console.log(e);
    })
  }
  getStructure() {
    const structure = localStorage.getItem("structure");
    if(structure != null) {
      var deserialized = JSON.parse(structure)
      this.orgId = deserialized[0].IdOrganization;
      this.orgStructureId = deserialized[0].Id;
    }
  }


  getAllDiseases(id:number) {
    this._diseases.getAll(id).subscribe((res) => {
      if(res) {
        // console.log(res)
        for(let i = 0 ; i < res.length ; i++) {
          this.diseasesList.push(res[i]);
        }
        // console.log(this.diseasesList);
        this.loading = false;
      }
    })
  }

  getAllForms(id:number) {
    this.listOfForms = [];
    this._forms.getAll(id).subscribe((res) => {
      if(res) {
        // console.log(res);
        for(let i = 0 ; i < res.length ; i++) {
          this.listOfForms.push(res[i]);
        }
        // console.log(this.listOfForms);
      }
    }, (e) => {
      console.log(e);
    })
  }
  questionInMemoryArray: any[] = []

  //form en memoria para agregar las preguntas
  newQuestionForm = new UntypedFormGroup({
    question: new UntypedFormControl('', Validators.required),
    type: new UntypedFormControl(0, Validators.required),
    options: new UntypedFormControl()
  })
  
  //form definitivo que va para el POST
  mainForm = new UntypedFormGroup({
    IdOrganization: new UntypedFormControl(0),
    IdOrganizationalStructure: new UntypedFormControl(0),
    IdDisease: new UntypedFormControl(0),
    FormCode: new UntypedFormControl(''),
    FormLevel: new UntypedFormControl(1),
    FormVersion: new UntypedFormControl(1),
    FormJson: new UntypedFormControl(''),
    IdFormStatus: new UntypedFormControl(0),
    IdRecordStatus: new UntypedFormControl(0),
    // IdUserCreated: new UntypedFormControl(0),
    RecordStatus: new UntypedFormControl(true)
  })

  saveNewForm() {
    // console.log('this');
    this.mainForm.controls['IdOrganization'].setValue(this.orgId);
    this.mainForm.controls['IdOrganizationalStructure'].setValue(this.orgStructureId);
    this.mainForm.controls['IdDisease'].setValue( Number(this.selectedDiseaseId));
    const serialized = JSON.stringify(this.questionInMemoryArray);
    this.mainForm.controls['FormJson'].setValue(serialized);
    this.mainForm.controls['IdFormStatus'].setValue(1);
    this.mainForm.controls['IdRecordStatus'].setValue(1);

    // console.log(this.mainForm.value);

    this._forms.addNew(this.mainForm.value).subscribe((res) => {
      // console.log(res);
    }, (e) => {
      console.log(e);
    })
  }
  showAddQuestionField() {
    this.showQuestionField = true;
  }

  cancelQuestionField() {
    this.showQuestionField = false;
  }

  pushQuestion() {
    this.questionInMemoryArray.push(this.newQuestionForm.value)
    this.newQuestionForm.reset();
    this.showQuestionField = false;
  }

  cancelForm() {
    this.questionInMemoryArray = [];
    this.newQuestionForm.reset();
  }
  levelsForPreview: any[] = [];
  previewForm(form:any) {
    this.levelsForPreview = []
    var unserialized = JSON.parse(form.FormJson);
    for(let i = 0 ; i < unserialized.length ; i++) {
      this.levelsForPreview.push(unserialized[i])
    }
    // console.log(this.levelsForPreview);
  }

  deletingForm: boolean = false;
  deleteForm(id:number) {
    this.deletingForm = true;
    this._forms.deleteForm(id).subscribe((res) => {
      if(res) {
        this.getAllForms(this.orgId);
        this._common.hideModal();
        this._common.customAlert("Form deleted successfully");
      }
    }, (e) => {
      this.deletingForm = false;
      this._common.customAlert("Error deleting form");
      console.log(e);
    })
  }

  formIdToBeDeleted:number = 0;
  deleteModal(id:number) {
    this.formIdToBeDeleted = id;
  }

  confirmDelete() {
    this.deleteForm(this.formIdToBeDeleted);
  }

  formSelectedForEdit:any;
  editForm(obj:any) {
    // console.log(obj);
    this.formSelectedForEdit = obj;
    this.changeView(2);
  }
}
