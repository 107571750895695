<div class="pt-0">
  <div class="card rounded-4 shadow-sm">
    <div class="card-header py-4">
      <div class="vstack gap-2">
        <app-back-btn></app-back-btn>
        <h5 class="mb-0 fw-bold text-capitalize">
          {{ patient.Names }} {{ patient.Surnames }}
          <app-pill
            *ngIf="originalCase?.IdRecordStatus == '5'"
            [state]="'closed'"
            [text]="'Closed'"
          ></app-pill>
          <app-pill
            *ngIf="originalCase?.IdRecordStatus == '1'"
            [state]="'open'"
            [text]="'Open'"
          ></app-pill>
        </h5>
        <p class="text-secondary">
          Patient details and disease investigation form.
        </p>
      </div>
    </div>
    <div class="card-body">
      <div action="">
        <div class="row g-3">
          <!-- patient data-->
          <div class="col-12" *ngIf="!loading">
            <div class="border rounded-1 p-3 shadow-sm">
              <div class="d-flex align-items-center mb-3">
                <div class="round-icon round-icon-sm bg-light-blue">
                  <i class="fs-5 bi bi-person-badge-fill text-primary"></i>
                </div>
                <h6 class="fw-bold mb-0 ms-2">Patient Info</h6>
              </div>
              <div class="row g-3">
                <div class="col-12 col-md-6">
                  <label for="">Patient's Name</label>
                  <p class="lead fw-bold">
                    {{ patient.Names }} {{ patient.Surnames }}
                  </p>
                </div>
                <div class="col-12 col-md-6">
                  <label for="">National ID</label>
                  <p class="lead fw-bold">{{ patient.IdCardNumber }}</p>
                </div>
                <div class="col-12">
                  <label for="">Gender</label>
                  <p class="lead fw-bold">
                    {{
                      patient.IdGender == 1
                        ? "Male"
                        : patient.IdGender == 2
                        ? "Female"
                        : "Other"
                    }}
                  </p>
                </div>
                <div class="col-12 col-md-6">
                  <label for="">Home Address</label>
                  <p class="lead fw-bold">{{ patient.HomeAddress }}</p>
                </div>
                <div class="col-12 col-md-6">
                  <label for="">Work Address</label>
                  <p class="lead fw-bold">{{ patient.WorkAddress }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12"
            *ngIf="originalCase?.IdRecordStatus == 1 && permissionLevel != 1"
          >
            <div
              [class]="
                step.LevelType != 'title'
                  ? 'border rounded-1 p-3 mb-3 shadow-sm'
                  : ''
              "
              *ngFor="let step of case; index as stepIndex"
            >
              <div *ngIf="loading" class="placeholder-glow">
                <span class="placeholder col-3"></span>
                <br />
                <span class="placeholder col-8"></span>
                <br />
                <span class="placeholder col-12"></span>
                <span class="placeholder col-7"></span>
              </div>
              <div
                [class]="step.LevelType != 'title' ? '' : 'mt-4'"
                *ngIf="!loading"
              >
                <div class="row">
                  <div>
                    <!--  *ngIf="stepIndex > 0" -->
                    <h6
                      *ngIf="step.LevelType != 'title'"
                      class="text-capitalize"
                    >
                      <strong> {{ step.LevelName }}</strong>
                    </h6>
                    <h5
                      *ngIf="step.LevelType == 'title'"
                      class="text-capitalize d-flex align-items-center justify-content-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-clipboard-pulse"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1H3a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-1v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Zm6.979 3.856a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.895-.133L4.232 10H3.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 .416-.223l1.41-2.115 1.195 3.982a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h1.5a.5.5 0 0 0 0-1h-1.128L9.979 5.356Z"
                        />
                      </svg>
                      <strong class="ms-1"> {{ step.LevelName }}</strong>
                    </h5>
                    <div class="row">
                      <div
                        class="col-12"
                        *ngFor="let l of step.Form; index as qi"
                      >
                        <div class="form-group mb-3 text-capitalize">
                          <h6
                            class="fw-bold mb-1 mt-4 border-bottom"
                            *ngIf="l.Type == '1008'"
                          >
                            {{ l.Question }}
                          </h6>
                          <p class="fw-regular mb-1" *ngIf="l.Type != '1008'">
                            {{ l.Question }}
                          </p>
                          <!-- contact input -->
                          <input
                            type="text"
                            *ngIf="l.Type == '1007'"
                            [(ngModel)]="l.Answer"
                            class="form-control"
                          />

                          <input
                            type="text"
                            *ngIf="l.Type == '1'"
                            [(ngModel)]="l.Answer"
                            class="form-control"
                          />

                          <textarea
                            class="form-control"
                            *ngIf="l.Type == '2'"
                            [(ngModel)]="l.Answer"
                            name=""
                            id=""
                            cols="15"
                            rows="3"
                          ></textarea>

                          <select
                            class="form-select"
                            [(ngModel)]="l.Answer"
                            *ngIf="l.Type == '3'"
                            name=""
                            id=""
                          >
                            <option value="" selected disabled>
                              Select an option
                            </option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>

                          <input
                            type="date"
                            *ngIf="l.Type == '6'"
                            [(ngModel)]="l.Answer"
                            class="form-control"
                          />
                          <!-- <input type="text" *ngIf="l.Type != '1006'" [(ngModel)]="l.Answer" class="form-control"> -->
                          <!-- if diagnosis is empty-->
                          <div
                            *ngIf="
                              l.Type == '1006' && l.OptionsAnswers.length == 0
                            "
                          >
                            no diagnosis
                          </div>
                          <!-- if diagnosis isn't empty -->
                          <div
                            *ngIf="
                              l.Type == '1006' && l.OptionsAnswers.length > 0
                            "
                          >
                            <ul>
                              <!-- <li *ngFor="let op of l.OptionsAnswers">{{op}}</li> -->
                              <li *ngFor="let op of l.OptionsAnswers">
                                {{ getDiseaseName(op) }}
                              </li>
                            </ul>
                          </div>
                          <div *ngIf="l.Type == '4'">
                            <select
                              [(ngModel)]="l.Answer"
                              name=""
                              id=""
                              class="form-select"
                            >
                              <option [value]="o" *ngFor="let o of l.Options">
                                {{ o }}
                              </option>
                            </select>
                          </div>
                          <!-- MULTIPLE CHOICE -->
                          <div *ngIf="l.Type == '5'">
                            <!-- (click)="pushCheckVal(m, qi, 'multiple', levelIndex)" -->
                            <div
                              (click)="
                                pushCheckVal(
                                  qi,
                                  'multiple',
                                  stepIndex,
                                  stepIndex + '-' + qi + '-' + m,
                                  m
                                )
                              "
                              class="form-check"
                              *ngFor="let ch of l.Options; index as m"
                            >
                              <input
                                style="pointer-events: none !important"
                                class="form-check-input"
                                type="checkbox"
                                [value]="ch"
                                id="form-check{{ stepIndex }}-{{ qi }}-{{ m }}"
                                [checked]="l.OptionsAnswers.includes(m)"
                              />
                              <label
                                style="pointer-events: none !important"
                                class="form-check-label"
                                for="form-check{{ stepIndex }}-{{ qi }}-{{ m }}"
                              >
                                {{ ch }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="save-changes-btns d-flex"
              *ngIf="originalCase?.IdRecordStatus == 1 && permissionLevel != 1"
            >
              <button
                [disabled]="updatingCase"
                class="btn btn-primary me-2 shadow d-flex align-items-center"
                (click)="updateCase()"
              >
                <span *ngIf="updatingCase">
                  <div
                    class="spinner-border spinner-border-sm text-light me-2"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  Updating case
                </span>
                <span *ngIf="!updatingCase">Update Case</span>
              </button>

              <button
                [disabled]="updatingCase"
                class="btn btn-success shadow d-flex align-items-center"
                (click)="showCloseCaseConfirmation()"
                data-bs-toggle="modal"
                data-bs-target="#closeCaseModal"
              >
                <span *ngIf="updatingCase">
                  <div
                    class="spinner-border spinner-border-sm text-light me-2"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  Closing case
                </span>
                <span *ngIf="!updatingCase">Close Case</span>
              </button>
            </div>
          </div>

          <div
            class="col-12"
            *ngIf="originalCase?.IdRecordStatus == 5 || permissionLevel == 1"
          >
            <div
              [class]="
                step.LevelType != 'title'
                  ? 'border rounded-1 p-3 mb-3 shadow-sm'
                  : ''
              "
              *ngFor="let step of case; index as stepIndex"
            >
              <div
                [class]="step.LevelType != 'title' ? '' : 'mt-4'"
                *ngIf="!loading"
              >
                <div class="row">
                  <div class="col-12">
                    <h5
                      *ngIf="step.LevelType != 'title'"
                      class="text-capitalize"
                    >
                      <strong>{{ step.LevelName }}</strong>
                    </h5>
                    <h3
                      *ngIf="step.LevelType == 'title'"
                      class="text-capitalize mt-4 text-center"
                    >
                      <strong>{{ step.LevelName }}</strong>
                    </h3>
                    <div class="row text-capitalize">
                      <div
                        class="col-12"
                        *ngFor="let l of step.Form; index as questionIndex"
                      >
                        <div class="form-group">
                          <h5
                            class="fw-bold mb-1 mt-4 border-bottom"
                            *ngIf="l.Type == '1008'"
                          >
                            {{ l.Question }}
                          </h5>
                          <p class="fw-bold mb-1" *ngIf="l.Type != '1008'">
                            {{ l.Question }}
                          </p>
                          <p
                            *ngIf="
                              l.Type != '6' && l.Type != '5' && l.Type != '1006'
                            "
                          >
                            {{ l.Answer }}
                          </p>
                          <p *ngIf="l.Type == '6'">{{ l.Answer | date }}</p>
                          <div *ngIf="l.Type == '5'">
                            <ul>
                              <li *ngFor="let op of l.OptionsAnswers">
                                {{ l.Options[op] }}
                              </li>
                            </ul>
                          </div>
                          <div *ngIf="l.Type === '1006'">
                            <ul>
                              <li *ngFor="let op of l.OptionsAnswers">
                                {{ getDiseaseName(op) }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Close Case Modal -->
<div
  class="modal fade"
  id="closeCaseModal"
  tabindex="-1"
  aria-labelledby="closeCaseModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <h4 class="modal-title fs-5" id="closeCaseModalLabel">
          You are about to close this case
        </h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="cancelCaseClose()"
        ></button>
      </div>
      <div class="modal-body">
        <p>Once the case is closed it cannot be updated anymore.</p>
      </div>
      <div class="modal-footer bg-light">
        <button
          type="button"
          class="btn btn-transparent"
          data-bs-dismiss="modal"
          (click)="cancelCaseClose()"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-success" (click)="closeCase()">
          Yes, close case
        </button>
      </div>
    </div>
  </div>
</div>
